<template>
  <div :class="goalCardClass" ref="goalel">
    <div :class="{'column1': !this.editable, 'hideColumn1' : this.editable}">
      <font-awesome-icon icon="fa-bullseye" class="question-icon" />
    </div>
    <div class="column2">
      <div class="heading">
        <div class="goal-name-edit">
          <label v-if="this.editable" class="text-muted">
            {{ $t('PersonalGoals.qGoalName') }}
          </label>
          <textarea :disabled="!editable"
                 id="goal-name"
                 v-model="this.goalName"
                 class="input-field"
                 maxlength="150"
                 :class="{'editEnabled' : this.editable, 'hideTextArea'
                 : !this.editable}"/>
        </div>
        <label v-if="!this.editable" class="editDisabled">
          {{this.goalName}}
        </label>
        <div class="type-date-container">
          <div class="time-rubber" @click="toggleCalendar">
            <font-awesome-icon class="icon" icon="fa-calendar"/>
            <p class="date">{{ timeRubberDate }}</p>
          </div>
          <div v-if="!this.editable" class="goal-type">
            <label>{{this.goalType}}</label>
          </div>
          <div v-else>
            <div class="question">
              <select v-model="goalType">
                <option :value="this.$t('PersonalGoals.goalType.habit')">
                  {{ $t('PersonalGoals.goalType.habit') }}
                </option>
                <option :value="this.$t('PersonalGoals.goalType.mindset')">
                  {{ $t('PersonalGoals.goalType.mindset') }}
                </option>
                <option :value="this.$t('PersonalGoals.goalType.reflection')">
                  {{ $t('PersonalGoals.goalType.reflection') }}
                </option>
                <option :value="this.$t('PersonalGoals.goalType.other')">
                  {{ $t('PersonalGoals.goalType.other') }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
        <edit-kss-calendar-component v-if="this.editable && this.showCalendar"
                                   :editable="this.editable" :initialDate="selectedDate"
                                   @updateDate="updateSelectedDate" />

      <div class="question-set">
        <PersonalGoalsQnAComponent :editable="this.editable"
                                   v-for="qna in qnaSet"
                                   :key="qna._id"
                                   :qna="qna"/>
      </div>
      <div v-if="this.editable" class="edit-btns">
        <button type="button" v-if="editable" class="delete" @click.prevent="deleteGoal">
          <i class="icon" >
            <font-awesome-icon icon="fa-trash-can"/>
          </i>
          <span>{{this.$t('PersonalGoals.deleteGoalButton')}}</span>
        </button>
        <button type="button" class="btn-update" name="update" :disabled="editable === false"
                :class="editable === false && 'disabled'"
                @click.prevent="updateGoal">
          {{ $t('PersonalGoals.update') }}
        </button>
      </div>
    </div>
    <div class="checkmark-container">
      <div class="icon-wrapper">
        <div v-if="$route.path === '/personal-goals' && !this.status"
          class="edit" @click.prevent="toggleEditable">
          <i>
            <font-awesome-icon icon="fa-pen"/>
          </i>
        </div>
        <font-awesome-icon @click.prevent="completeGoal"
                           v-if="!this.checkBoxChecked"
                           class="unchecked-icon"
                           icon="fa-regular fa-square"/>
        <font-awesome-icon @click.prevent="incompleteGoal"
                           v-if="this.checkBoxChecked"
                           class="checked-icon"
                           icon="fa-square-check"/>
      </div>
    </div>
    <PopUpConfirmationComponent :message="popupMessage"
                                :confirmButtonText="deleteButtonText"
                                :cancelButtonText="cancelButtonText"
                                :confirmButtonColor="'red'"
                                @cancel="onPopupCancel"
                                @confirm="onPopupConfirm"
                                v-if="showPopup"/>
  </div>
</template>
<script>
import PersonalGoalsQnAComponent
  from '@/components/Home/PersonalGoalsComponent/PersonalGoalsQnAComponent.vue';
import PopUpConfirmationComponent from '@/components/global/PopUpConfirmationComponent.vue';
import EditKssCalendarComponent
  from '@/components/Home/KssCreator/KssEditComponents/EditKssCalendarComponent.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'GoalComponent',
  props: ['goal'],
  components: {
    FontAwesomeIcon,
    EditKssCalendarComponent,
    PopUpConfirmationComponent,
    PersonalGoalsQnAComponent,
  },
  data() {
    return {
      selectedDate: new Date(this.goal.goalDate),
      checkBoxChecked: this.goal.status,
      editable: false,
      maxLimit: this.$store.getters.getPersonalGoalsMaxLimit,
      popupMessage: this.$t('PersonalGoalsComponent.popup.deleteMessage'),
      deleteButtonText: this.$t('PersonalGoalsComponent.popup.deleteButton'),
      cancelButtonText: this.$t('PersonalGoalsComponent.popup.cancelButton'),
      showPopup: false,
      showCalendar: false,
      goalName: this.goal.goalName,
      goalType: this.goal.goalType,
      goalDate: this.goal.goalDate,
      status: this.goal.status,
    };
  },
  computed: {
    timeRubberDate() {
      const goalDateString = new Date(this.goalDate);
      return `${(`0${goalDateString.getDate()}`).slice(-2)}.${(`0${goalDateString.getMonth() + 1}`).slice(-2)}.${goalDateString.getFullYear()}`;
    },
    qnaSet() {
      return this.goal.questionAndAnswer;
    },
    goalCardClass() {
      return this.status ? 'goal-card-inactive' : 'goal-card-active';
    },
  },
  methods: {
    updateSelectedDate(date) {
      this.goalDate = date;
      this.toggleCalendar();
    },
    async updateGoal() {
      if (this.checkForErrors()) {
        return;
      }
      const updatedValue = this.$store.getters.getGoalUpdateObject;
      const updatedData = this.qnaSet.map((ele) => {
        if (Object.prototype.hasOwnProperty.call(updatedValue, ele.questionKeyword)) {
          return { ...ele, answer: updatedValue[ele.questionKeyword] };
        }
        return ele;
      });
      const data = {
        goalId: this.goal._id,
        goalName: this.goalName,
        goalType: this.goalType,
        goalDate: this.goalDate,
        questionAndAnswer: updatedData,
      };
      await this.$store.dispatch('updatePersonalGoal', data);
      this.$store.commit('setToastNotification', {
        id: Math.random(),
        message: this.$t('PersonalGoals.updateMsg'),
        type: 'success',
      });
    },
    checkForErrors() {
      let hasError = false;
      if (this.goalName === '') {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('PersonalGoals.enterName'),
          type: 'error',
        });
        hasError = true;
      }
      if (this.goalType === '') {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('PersonalGoals.selectGoal'),
          type: 'error',
        });
        hasError = true;
      }
      const currentDate = new Date();
      if (this.goalDate - currentDate < 0) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('PersonalGoals.incorrectDate'),
          type: 'error',
        });
        hasError = true;
      }
      return hasError;
    },
    toggleEditable() {
      this.editable = !this.editable;
      if (this.editable) {
        setTimeout(() => this.scrollToElement(), 150);
      }
    },
    // reverting completed goal to incomplete
    async incompleteGoal() {
      const activeGoals = this.$store.getters.getActiveGoalsCount;
      if (activeGoals >= this.maxLimit) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('PersonalGoals.toast.maximumGoalsMessage'),
          type: 'error',
        });
        return;
      }
      this.checkBoxChecked = !this.checkBoxChecked;
      await this.$store.dispatch('changeGoalStatus', {
        id: this.goal?._id,
        status: !this.goal.status,
      });
      this.$store.commit('setActiveGoalsCount', (activeGoals + 1));
    },
    completeGoal() {
      this.checkBoxChecked = !this.checkBoxChecked;
      setTimeout(() => this.markGoal(), 200);
    },
    async markGoal() {
      try {
        await this.$store.dispatch('changeGoalStatus', {
          id: this.goal?._id,
          status: !this.goal.status,
        });
        this.$store.commit('addFrontendOverlay', {
          id: Math.random(),
          type: 'personalGoalFeedback',
          goalId: this.goal?._id,
        });
        this.$store.commit('setActiveGoalsCount', (this.$store.getters.getActiveGoalsCount - 1));
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'error',
          type: 'error',
        });
      }
    },
    async onPopupConfirm() {
      try {
        await this.$store.dispatch('deleteGoal', {
          id: this.goal?._id,
        });
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'error',
          type: 'error',
        });
      }
    },
    toggleCalendar() {
      this.showCalendar = !this.showCalendar;
    },
    deleteGoal() {
      this.showPopup = true;
    },
    onPopupCancel() {
      this.showPopup = false;
    },
    scrollToElement() {
      const el = this.$refs.goalel;

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
  unmounted() {
    this.$store.commit('removeGoalsObject');
  },
};

</script>
<style scoped>
.goal-card-active {
  position: relative;
  width: 100%;
  max-width: 1440px;
  background: var(--color-light);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  scroll-margin-top: 50px;
}

.goal-card-inactive {
  background: var(--color-light-grey);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 100%;
  max-width: 1440px;
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  scroll-margin-top: 50px;
}

/*------------------ BUTTONS -------------------*/
.edit-btns {
  width: 100%;
  padding-top: 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.btn-update {
  font-size: 20px;
  width: fit-content;
  height: 2.5rem;
  padding: 0 30px;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background-color: var(--color-bisque);
  cursor: pointer;
}

.btn-update:hover {
  transition: 0.5s;
  background-color: var(--color-fulvous);
}

.type-date-container {
  display: flex;
  flex-direction: row;
}

/* Edit Styling */
.hideTextArea {
  display: none;
}
textarea {
  font-family: Poppins, sans-serif;
  border: 1px solid transparent;
  box-sizing: border-box;
  background: var(--color-light);
  border-radius: 7px;
  width: 100%;
  height: 100px;
  font-size: 15px;
  resize: none;
}

.input-field:focus-within {
  transform: scale(1.02, 1.02);
  border-color: var(--color-orange);
}

.editEnabled {
  border-radius: 7px;
  border: 1px solid var(--color-light-grey);
  width: 100%;
  margin-bottom: .5rem;
  padding-left: 10px;
  height: 100px;
}

.editDisabled {
  background-color: transparent;
  padding-left: 0;
  height: fit-content;
  margin-right: 60px;
}

.edit i {
  font-size: 1.3rem;
  color: var(--color-disabled);
}

.delete i {
  font-size: 1rem;
  padding-right: 5px;
}

.delete {
  color: red;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  cursor: pointer;
  border-radius: 50px;
  width: fit-content;
  height: 2.5rem;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
}

.delete:hover {
  color: #c70000;
}

.delete span {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}

.edit i:hover {
  color: var(--color-orange);
}

.column1 {
  width:max-content;
}

.hideColumn1 {
  display: none;
}

.column2 {
  min-width: 60%;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
}

.checkmark-container {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 32px;
  height: 32px;
  top: .8rem;
  right: .8rem;
  color: var(--color-disabled)
}

.question-icon {
  font-size: 60px;
  margin-right: 10px;
}

.question-set{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.heading {
  margin-bottom: .5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.goal-type {
  background-color: var(--color-orange);
  border-radius: 7px;
  height: 2.2rem;
  width: max-content;
}

.time-rubber, .goal-type{ /* Date-container on task-element, change color to: var(--color-disable)*/
  display: flex;
  flex-direction: row;
  margin: 0 0.2rem 0 0;
  color: white;
  padding: 0.7rem;
  align-items: center;
  height: 2.2rem;
  width: max-content;
  border-radius: 4px;
  background-color: var(--color-orange);
}

.date {
  color: var(--color-white);
  padding: 5px;
  width: 100%;
  white-space: nowrap;
}

.checked-icon {
  color: var(--color-orange);
  font-size: 2rem;
  align-self: flex-end;
  padding-left: 10px;
}

.unchecked-icon {
  padding-left: 10px;
  font-size: 2rem;
}

.icon-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 10;
  cursor: pointer;
  margin: 0 10px;
}

#goal-name {
  margin-right: 10px;
  font-size: 19px;
}

.goal-name-edit {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.text-muted {
  color: var(--color-disabled);
  margin: 0 0 5px 5px;
}

.question {
  width: 100%;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

select {
  background-color: var(--color-light);
  width: 100%;
  height: 2.2rem;
  font-size: 20px;
  padding: 1px 5px;
  border-radius: 5px;
  border: 1px solid var(--color-light-grey);
  color: var(--color-orange);
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .question-set{
    width: 100%;
    margin-left: -10px;
  }
  .questions {
    width: 100%;
    height: fit-content;
    padding-right: 0;
  }
  .editDisabled {
    margin-left: 27px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .editEnabled {
    margin-left: 0;
  }
  input{
    width: 100%;
  }
  .question-icon {
    position: absolute;
    font-size: 25px;
    top: 20px;
    left: 20px;
  }
  .column2 {
    margin-left: 16px;
  }
  .icon-wrapper {
    margin-right: 0;
  }
  .heading {
    flex-wrap: wrap;
    margin-left: -10px;
  }
  .date, .icon{
    font-size: 14px;
  }
  .goal-type {
    margin-left: 0;
    font-size: 14px;
  }
  .type-date-container{
    margin: 0;
    min-width: 200px;
  }
  .delete {
    padding-right: 0;
  }
}
@media screen and (max-width: 521px) {
  .delete span {
    display: none;
  }
  .delete i {
    padding-right: 0;
    color: red;
    font-size: 1.5rem;
  }
  .column2 {
    margin-right: 0;
    padding-right: 0;
  }
}
</style>
