<template>
  <div class="input-container">
    <div class="pill-container">
      <div v-for="selectedTeam in selectedTeams" :key="selectedTeam" class="pill-element">
        {{ selectedTeam.name }}
        <span class="close" @click.prevent="checkElement(selectedTeam)">
          <font-awesome-icon icon="fa-trash-can" />
        </span>
      </div>
      <div class="search-pill-element">
        <input v-model="searchTerm" class="search-input" type="text"
           id="search-input" ref="searchInput"
          :placeholder="$t('KssTeamSelectionComponent.placeholder.searchInput')" />
      </div>
    </div>
  </div>
  <ul v-if="teams.length > 0" class="list-items" id="tpl_teams-container">
    <li class="item" v-for="team in teams.filter((el) => !el.isChecked)"
      :key="team" @click.prevent="checkElement(team)">
      <span class="item-text">
        {{ team.name }}
      </span>
    </li>
  </ul>
  <ul v-else class="list-items">
    <li class="item-disabled">
      <span class="item-item-disabled-text">
        {{ $t('KssTeamSelectionComponent.placeholder.noTeams') }}
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'KssTeamSelectionComponent',
  data() {
    return {
      selectedTeams: [],
      participants: [],
      searchTerm: '',
    };
  },
  props: {
    checkedKssTeams: {
      type: Array,
      default: null,
    },
    selectedIds: {
      type: Array,
      default: null,
    },
    allKssParticipants: {
      type: Array,
      default: null,
    },
  },
  emits: ['updateSelectedUserIds', 'updateCheckedTeams'],
  computed: {
    teamData() {
      return [];
    },
    checkedIds() {
      return this.selectedIds;
    },
    teams() {
      return this.teamData
        .filter((el) => !el.isChecked)
        .filter((el) => el.name.toLowerCase()
          .includes(this.searchTerm.toLowerCase()))
        .sort((a, b) => ((a.name > b.name) ? 1 : -1));
    },
    kssParticipants() {
      return this.participants.filter((el) => !el.isChecked)
        .filter((el) => el.name.toLowerCase()
          .includes(this.searchTerm.toLowerCase()))
        .sort((a, b) => ((a.name > b.name) ? 1 : -1));
    },
    selectedUserIds() {
      return this.participants.filter((el) => el.isChecked)
        .map((el) => el._id);
    },
  },
  methods: {
    checkElement(selectedTeam) {
      this.getCheckedParticipants();

      const index = this.teamData
        .findIndex((item) => item._id === selectedTeam._id);
      this.teamData[index].isChecked = !this.teamData[index].isChecked;

      this.allKssParticipants.forEach((participant, i) => {
        participant.teams.forEach((teamId) => {
          if (teamId === selectedTeam._id) {
            this.participants[i].isChecked = this.teamData[index].isChecked;
          }
        });
      });

      this.updateSelectedTeams();
      this.$emit('updateSelectedUserIds', this.selectedUserIds);
      this.$emit('updateCheckedTeams', this.teamData);
    },
    getCheckedParticipants() {
      this.participants = [];
      this.allKssParticipants.forEach((el) => {
        this.participants.push({
          name: el.name,
          _id: el._id,
          isChecked: this.checkedIds.includes(el._id),
        });
      });
    },
    updateSelectedTeams() {
      this.selectedTeams = this.teamData.filter((el) => el.isChecked);
    },
  },
  created() {
    this.checkedKssTeams.forEach((team) => {
      const teamObject = { ...team };
      this.teamData.push(teamObject);
    });

    this.updateSelectedTeams();
    this.getCheckedParticipants();
  },
};
</script>

<style scoped>
.list-items::-webkit-scrollbar {
  width: 8px;
  background: var(--color-white);
  border-radius: 0 8px 8px 0;
}

.list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.select-container .list-items {
  width: 100%;
  max-height: 250px;
  border-radius: 20px;
  box-shadow: 5px 10px 8px var(--color-light-grey);
  padding: 0.5rem 1rem;
  margin: 1rem 0;
  background-color: var(--color-milk);
  display: block;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 10;
}

.select-container .list-items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  cursor: pointer;
  transition: 0.3s;
}

.select-container .list-items .item:hover {
  transform: scale(1.02);
}

.select-container .list-items .item .item-text {
  display: flex;
  align-items: center;
}

.select-container .list-items .item .item-text img {
  width: 2rem;
  margin-right: 12px;
}

.select-container .list-items .item .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.select-container .list-items .item-disabled {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  cursor: not-allowed;
  transition: 0.3s;
  opacity: 0.5;
}

.select-container .list-items .item .item-disabled-text {
  display: flex;
  align-items: center;
}

.input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.pill-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
}

.pill-element {
  display: flex;
  align-items: center;
  background-color: var(--color-orange);
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  margin: 5px;
  color: var(--color-milk);
}

.pill-element .close {
  margin-left: 5px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}
</style>
