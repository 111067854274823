import NetworkContent from '@/helpers/networking/NetworkContent';
import { moodomatToolTipHelper, tooltipTeamPlotHelper, removeTimeToolTipHelper } from '@/helpers/graphPlotHelper';

export default {
  state: {
    leftSelection: [],
    rightSelection: [],
    isDashboardLoading: false,
    oimPlotData: null,
    kssPlotData: null,
    moodomatPlotData: null,
    leftSelected: null,
    rightSelected: null,
    // Team Dashboard
    oimTeamPlotData: null,
    kssTeamPlotData: null,
    moodomatTeamPlotData: null,
    teamSelection: [],
    selectedTeam: null,
    dashboardHtml: [],
    selectedCompareMonth: null,
    compareMonthSelection: [],
    moodomatTeamCount: {},
  },
  getters: {
    getOimPlotData: (state) => state.oimPlotData,
    getKssPlotData: (state) => state.kssPlotData,
    getMoodomatPlotData: (state) => state.moodomatPlotData,
    getOimTeamPlotData: (state) => state.oimTeamPlotData,
    getKssTeamPlotData: (state) => state.kssTeamPlotData,
    getMoodomatTeamPlotData: (state) => state.moodomatTeamPlotData,
    getDashboardHtml: (state) => state.dashboardHtml,
    getIsDashboardLoading: (state) => state.isDashboardLoading,
    getMoodomatTeamCount: (state) => state.moodomatTeamCount,
  },
  mutations: {
    setIsDashboardLoading: (state, data) => {
      state.isDashboardLoading = data;
    },
    setLeftSelection: (state, data) => {
      state.leftSelection = data.filter((el) => el.show.includes('left'));
    },
    // for init commit with no data
    // for set commit with data set to value
    setLeftSelected: (state, data) => {
      if (data) {
        state.leftSelected = data;
      } else {
        const [first] = state.leftSelection;
        state.leftSelected = first;
      }
    },
    // for init commit with no data
    // for set commit with data set to value
    setRightSelected: (state, data) => {
      if (data) {
        state.rightSelected = data;
      } else {
        const [first] = state.rightSelection;
        state.rightSelected = first;
      }
    },
    setRightSelection: (state, data) => {
      state.rightSelection = data.filter((el) => el.show.includes('right'));
    },
    setOimPlotData: (state, data) => {
      if (data !== undefined && Object.keys(data).length !== 0) {
        // eslint-disable-next-line no-param-reassign
        data.options.plugins.tooltip = {
          callbacks: {
            label: (ctx) => tooltipTeamPlotHelper(ctx),
          },
        };
        state.oimPlotData = data;
      }
    },
    setKssPlotData: (state, data) => {
      if (data !== undefined && Object.keys(data).length !== 0) {
        // eslint-disable-next-line no-param-reassign
        data.options.plugins.tooltip = {
          callbacks: {
            title: (ctx) => removeTimeToolTipHelper(ctx),
          },
        };
        state.kssPlotData = data;
      }
    },
    setMoodomatPlotData: (state, data) => {
      if (data !== undefined && Object.keys(data).length !== 0) {
        // eslint-disable-next-line no-param-reassign
        data.options.plugins.tooltip = {
          callbacks: {
            title: (ctx) => removeTimeToolTipHelper(ctx),
          },
        };
        state.moodomatPlotData = data;
      }
    },
    setTeamSelection: (state, data) => {
      state.teamSelection = data;
    },
    // for init commit with no data
    // for set commit with data set to value
    setSelectedTeam: (state, data) => {
      if (data) {
        state.selectedTeam = data;
      } else {
        const [first] = state.teamSelection;
        state.selectedTeam = first;
      }
    },
    setOimTeamPlotData: (state, data) => {
      if (data !== undefined && Object.keys(data).length !== 0) {
        // eslint-disable-next-line no-param-reassign
        data.options.plugins.tooltip = {
          callbacks: {
            label: (ctx) => tooltipTeamPlotHelper(ctx),
          },
        };
        state.oimTeamPlotData = data;
      }
    },
    setKssTeamPlotData: (state, data) => {
      if (data !== undefined && Object.keys(data).length !== 0) {
        // eslint-disable-next-line no-param-reassign
        data.options.plugins.tooltip = {
          callbacks: {
            title: (ctx) => removeTimeToolTipHelper(ctx),
          },
        };
        state.kssTeamPlotData = data;
      }
    },
    setMoodomatTeamPlotData: (state, data) => {
      if (data !== undefined && Object.keys(data).length !== 0) {
        if (Array.isArray(state.moodomatCountTeamPlotData)) {
          // eslint-disable-next-line no-param-reassign
          data.options.plugins.tooltip = {
            callbacks: {
              label: (ctx) => moodomatToolTipHelper(ctx, state.moodomatCountTeamPlotData),
              title: (ctx) => removeTimeToolTipHelper(ctx),
            },
          };
        }
        state.moodomatTeamPlotData = data;
      }
    },
    setMoodomatCountTeamPlotData: (state, data) => {
      if (data !== undefined && Object.keys(data).length !== 0) {
        state.moodomatCountTeamPlotData = data;
      }
    },
    setMindsetHtml: (state, data) => {
      state.dashboardHtml = data;
    },
    setSelectedCompareMonth: (state, data) => {
      state.selectedCompareMonth = data;
    },
    setCompareMonthSelection: (state, data) => {
      state.compareMonthSelection = data;
    },
    setMoodomatTeamCount: (state, data) => {
      state.moodomatTeamCount = data;
    },
    resetPlotData: (state) => {
      state.oimPlotData = null;
      state.kssPlotData = null;
      state.moodomatPlotData = null;
      state.oimTeamPlotData = null;
      state.kssTeamPlotData = null;
      state.moodomatTeamPlotData = null;
    },
    resetPersonalKssPlot: (state) => {
      state.kssPlotData = null;
    },
    resetPersonalMindsetPlot: (state) => {
      state.oimPlotData = null;
    },
    resetPersonalMoodomatPlot: (state) => {
      state.moodomatPlotData = null;
    },
    resetMindsetHtml: (state) => {
      state.dashboardHtml = [];
    },
  },
  actions: {
    async getPersonalMindset({ commit }) {
      const { data } = await NetworkContent.getPersonalMindset();
      commit('setLeftSelection', data.data);
      commit('setRightSelection', data.data);
      commit('setOimPlotData', data.plotData);
      commit('setLeftSelected');
      commit('setRightSelected');
    },
    async getPersonalMindsetPlot({
      commit,
      state,
    }) {
      commit('resetPersonalMindsetPlot');
      const postData = {
        data: [state.leftSelected, state.rightSelected],
      };
      const { data } = await NetworkContent.getPersonalMindsetPlot(postData);
      commit('setOimPlotData', data.plotData);
    },
    async getPersonalKssPlot({ commit }) {
      commit('resetPersonalKssPlot');
      const { data } = await NetworkContent.getPersonalKssPlot();
      commit('setKssPlotData', data.plotData);
    },
    async getPersonalMoodomatPlot({ commit }) {
      commit('resetPersonalMoodomatPlot');
      const { data } = await NetworkContent.getPersonalMoodomatPlot();
      commit('setMoodomatPlotData', data.plotData);
    },
    async getTeamDashboard({ commit }) {
      const { data } = await NetworkContent.getTeamDashboard();
      if (data.plotData.mindset_omindset) commit('setOimTeamPlotData', data.plotData.mindset_omindset);
      if (data.firstTeamDataDate) commit('setSelectedCompareMonth', data.firstTeamDataDate);
      if (data.dateSelection) commit('setCompareMonthSelection', data.dateSelection);
      if (data.plotData.kss_kss) commit('setKssTeamPlotData', data.plotData.kss_kss);
      if (data.dailyTeamCount) commit('setMoodomatCountTeamPlotData', data.dailyTeamCount);
      if (data.plotData.moodomat) commit('setMoodomatTeamPlotData', data.plotData.moodomat);
      if (data.data.length > 0) {
        commit('setTeamSelection', data.data);
        commit('setSelectedTeam');
      }
      if (data.moodomatCount) commit('setMoodomatTeamCount', data.moodomatCount);
    },
    async getTeamDashboardPlots({
      commit,
      state,
    }, isTeamChange = false) {
      commit('resetPlotData');
      const postData = {
        data: {
          team: state.selectedTeam,
          date: isTeamChange ? null : state.selectedCompareMonth,
        },
      };
      const { data } = await NetworkContent
        .getTeamDashboardPlots(postData);
      if (isTeamChange) {
        commit('setSelectedCompareMonth', data.firstTeamDataDate);
        commit('setCompareMonthSelection', data.dateSelection);
      }
      commit('setOimTeamPlotData', data.plotData.mindset_omindset);
      commit('setKssTeamPlotData', data.plotData.kss_kss);
      commit('setMoodomatTeamPlotData', data.plotData.moodomat);
      commit('setMoodomatCountTeamPlotData', data.dailyTeamCount);
      commit('setMoodomatTeamCount', data.moodomatCount);
    },
    async getDashboardHtml({ commit }) {
      const { data } = await NetworkContent.getDashboardHtml();
      commit('setMindsetHtml', data.underDiagrammHTML);
    },
  },
};
