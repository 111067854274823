<template>
  <div class="similar-item">
    <div @click="toggleCollapse" class="similar-item-header">
      <div class="similar-item-header-content">
        <div class="similar-item-header-left">
          <h2 class="similar-item-title">{{ item.name }}</h2>
          <p class="similar-item-description">{{ item.description }}</p>
        </div>
        <div class="similar-item-header-right">
          <span class="users">
            {{ $t('SimilarKssItemComponent.users', { count: item.users.length }) }}
          </span>
          <font-awesome-icon :icon="isCollapsed ? 'angle-down' : 'angle-up'"/>
        </div>
      </div>
      <div class="similar-item-footer">
        <span class="similar-item-date">{{ getDate }}</span>
        <div class="similar-item-footer-right">
          <img :src="getPicture" alt="" class="creator-img">
          <span class="creator-name">{{ item.creator && item.creator.name }}</span>
        </div>
      </div>
    </div>
    <div v-if="isCollapsed" class="similar-item-body">
      <div v-for="(user, uIndex) in item.users" :key="uIndex" class="pill">
        {{ user.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimilarKssItemComponent',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isCollapsed: false,
    };
  },
  computed: {
    getDate() {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      return new Date(this.item.date).toLocaleString(undefined, options);
    },
    getPicture() {
      if (this.item.creator?.picture_profile?.small) {
        return this.item.creator.picture_profile.small;
      }
      // eslint-disable-next-line global-require
      return require('@/assets/smiley-profil-bild.png');
    },
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>

<style scoped>
.similar-item {
  width: 100%;
  background: var(--color-light);
  border-radius: var(--card-border-radius);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin: 1rem 0;
  overflow: hidden;
}

.similar-item-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  background: #f5f5f5;
  cursor: pointer;
}

.similar-item-header-content {
  display: flex;
  justify-content: space-between;
}

.similar-item-footer-right {
  align-self: flex-end;
  display: flex;
  align-items: center;
}

.similar-item-title {
  margin: 0;
  font-size: 1.2em;
}

.similar-item-description {
  margin: 5px 0;
  color: #666;
}

.similar-item-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.similar-item-footer-right {
  display: flex;
  align-items: center;
}

.creator-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 10px;
}

.creator-name {
  margin-left: 10px;
}

.similar-item-body {
  padding: 15px;
  background: var(--color-milk);
}

.users {
  margin-right: 10px;
}

.pill {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 0.8em;
  background-color: var(--color-orange);
  color: var(--color-milk);
}
</style>
