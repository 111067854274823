<template>
    <div class="mainMindSet">
      <div class="upperMindSet">
        <button
          @click="toggleVisibility"
          :style="{
            backgroundColor: showTeamsAndTags ? '#f49718' : 'white',
            color: showTeamsAndTags ? 'white' : 'black',
          }"
        >
          <img width="23" src="@/assets/eye-slash.svg" alt="eye slash" v-show="showTeamsAndTags" />
          <img width="23" src="@/assets/eye.svg" alt="eye" v-show="!showTeamsAndTags" />
          {{
            showTeamsAndTags ? $t("mngDashboardComponent.hide") : $t("mngDashboardComponent.show")
          }}
          {{
            $t("mngDashboardComponent.teams/tags")
           }}
        </button>
        <FiltersComponent
          :allowFilter="{
            team: true,
            tag: true
          }"
          :max="mindsetMaxSelection"
          :multiple="false"
          v-if="showTeamsAndTags"
          @saveSelection="saveSelection"
        />
      </div>
      <MessageBoxComponent v-if="selections.length === 1"
        :isWarning="false" :text="$t('mngDashboardComponent.clickToCompareCompany')">
        <button @click="compareToCompany" class="orageButton">
          {{ $t('mngDashboardComponent.compare') }}
        </button>
      </MessageBoxComponent>
      <MessageBoxComponent :text="mindsetMessage" />
      <div class="lowerMindSet">
        <AlertBox
        @mouseover="isAlertBoxVisible = true"
        @mouseout="isAlertBoxVisible = false"
        @focusin="isAlertBoxVisible = true"
        @focusout="isAlertBoxVisible = false"
        tabindex="0"
        v-show="isAlertBoxVisible"
        :text="mindsetAlert"
      />
        <div class="leftMindSetContainer">
          <div class="topMindSetChartContainer">
            <h1>{{ $t('mngDashboardComponent.theOMindsetInYourCompany') }}</h1>
            <select v-model="mindSetSelectFilter" @change="mindSetOverAllFunction()">
              <option v-for="item in mindsetPeriod" :key="item?.identifier"
                :value="item?.identifier">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="mindSetChartContainer" @mouseover="isAlertBoxVisible = true"
          @mouseout="isAlertBoxVisible = false"
          @focusin="isAlertBoxVisible = true"
          @focusout="isAlertBoxVisible = false"
          tabindex="0">
            <OMindSetCompanyChart ref="mindSetCompany" />
          </div>
        </div>
        <div class="rightMindSetContainer">
          <div class="avgChartContainer"
            style="align-items: normal; height: 100%; justify-content: flex-start;">
            <template v-for="(tip,i) in mindsetTipText" :key="i">
              <div class="OMindsetDevelopmentTipGroup" v-if="tip?.length > 0">
                <OMindsetDevelopmentTip :style="`width: ${100 / tip.length}%`"
                 v-for="t in tip" :key="t?.title" :data="t"/>
              </div>
              <OMindsetDevelopmentTip v-else :data="tip"/>
            </template>
          </div>
        </div>
      </div>
    </div>
  </template>
<script>
import '../managementDashboard.css';
import OMindSetCompanyChart from '@/components/Home/ManagementDashboard/OmindsetComponent/OmindsetChartComponent.vue';
import OMindsetDevelopmentTip from '@/components/Home/ManagementDashboard/OmindsetComponent/OmindsetDevelopmentTipComponent.vue';
import FiltersComponent from '@/components/Home/ManagementDashboard/common/FiltersComponent.vue';
import AlertBox from '@/components/Home/ManagementDashboard/common/AlertBoxComponent.vue';
import MessageBoxComponent from '@/components/Home/ManagementDashboard/common/MessageBoxComponent.vue';

export default {
  name: 'OmindsetComponent',
  data() {
    return {
      isAlertBoxVisible: false,
      selections: [],
      mindSetSelectFilter: 'AllTime',
      showTeamsAndTags: false,
      showAverageText: true,
    };
  },
  components: {
    MessageBoxComponent,
    AlertBox,
    OMindsetDevelopmentTip,
    OMindSetCompanyChart,
    FiltersComponent,
  },
  computed: {
    oMindSetCompanyData() {
      return this.$store.getters.getOmindset?.oMindSetCompanyData;
    },
    mindsetTipText() {
      return this.$store.getters.getOmindset?.mindsetTipText;
    },
    mindsetPeriod() {
      return this.$store.getters.getOmindset?.mindsetPeriod;
    },
    mindsetAlert() {
      return this.$store.getters.getOmindset?.alert;
    },
    mindsetMessage() {
      return this.$store.getters.getOmindset?.message;
    },
    mindsetMaxSelection() {
      return this.$store.getters.getOmindset?.maxSelection;
    },
  },
  methods: {
    // --------- Main Function to call the api on every filters change ---------------- //
    async mindSetOverAllFunction(selections) {
      try {
        await this.$store.dispatch('fetchInitialMindSetCompanyData', {
          selections: selections ?? this.selections,
          mindsetPeriodType: this.mindSetSelectFilter,
        });
        this.$refs.mindSetCompany.OMindSetCompanyCharts(this.oMindSetCompanyData);
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
      }
    },

    // -----------show / hide Teams and Tags function -------------- //
    toggleVisibility() {
      this.showTeamsAndTags = !this.showTeamsAndTags;
      if (this.selections.length > 0) {
        this.selections = [];
        this.mindSetOverAllFunction();
      }
    },

    compareToCompany() {
      const selections = [
        ...this.selections,
        {
          name: {
            en: 'Whole Company',
            de: 'Ganzes Unternehmen',
          },
          selection: {},
          wholeCompany: true,
        },
      ];
      this.mindSetOverAllFunction(selections);
    },

    saveSelection(selection) {
      this.selections = selection;
      this.mindSetOverAllFunction();
    },
  },
  mounted() {
    this.mindSetOverAllFunction();
  },
};
</script>
