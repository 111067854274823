<template>
  <div class="overlay-container" v-if="getVisibleOverlays">
    <component :is="getOverlayComponent(getOverlay)"
               :overlay="getOverlay"/>
  </div>
</template>

<script>
import LevelUpOverlayNoteComponent
  from '@/components/Home/Shared/OverlayNotes/LevelUpOverlayNoteComponent.vue';
import TeamSelectionOverlayComponent
  from '@/components/Home/Shared/OverlayNotes/TeamSelectionOverlayComponent.vue';
import TagSelectionOverlayComponent
  from '@/components/Home/Shared/OverlayNotes/TagSelectionOverlayComponent.vue';
import HtmlOverlayComponent
  from '@/components/Home/Shared/OverlayNotes/HtmlOverlayComponent.vue';

export default {
  name: 'OverlayContainerComponent',
  components: {
    LevelUpOverlayNoteComponent,
    TeamSelectionOverlayComponent,
    TagSelectionOverlayComponent,
    HtmlOverlayComponent,
  },
  computed: {
    getVisibleOverlays() {
      return this.$store.getters.visibleNoteOverlays;
    },
    getOverlay() {
      if (this.$store.getters.getOverlayNotes) {
        return this.$store.getters.getOverlayNotes[0];
      }

      return null;
    },
  },
  methods: {
    getOverlayComponent(overlay) {
      switch (overlay.event) {
        case 'rewardLevelUp':
          return 'LevelUpOverlayNoteComponent';
        case 'chooseTeams':
          return 'TeamSelectionOverlayComponent';
        case 'chooseTags':
          return 'TagSelectionOverlayComponent';
        case 'htmlOverlay':
          return 'HtmlOverlayComponent';
        default:
          return null;
      }
    },
  },
};
</script>

<style scoped>
.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 90;
  background-color: rgba(0, 0, 0, 0.5);
}

</style>
