<template>
  <div class="popup-overview" ref="popupel">
    <h1 class="overlay-title">
      {{ $t('SimilarKssWarningOverlayComponent.similarFound') }}
    </h1>
    <div class="content-container">
      <div class="item-list-overlay">
        <similar-kss-item-component v-for="(item, index) in getSimilarTasks" :key="index"
                                    :item="item"/>
      </div>
    </div>
    <div class="question">
      {{ $t('SimilarKssWarningOverlayComponent.doYouWantToCreate') }}
    </div>
    <div class="button-container">
      <button class="popup-button-create"
              type="button"
              :disabled="submitDisabled"
              @click.prevent="createTask">
        {{ $t('SimilarKssWarningOverlayComponent.create') }}
      </button>
      <button class="popup-button-cancel"
              type="button"
              @click.prevent="closeOverlay">
        {{ $t('SimilarKssWarningOverlayComponent.cancel') }}
      </button>
    </div>
  </div>
</template>

<script>
import SimilarKssItemComponent
  from '@/components/Home/Shared/FrontendOverlays/SimilarKssWarningComponents/SimilarKssItemComponent.vue';

export default {
  name: 'SimilarKssWarningOverlayComponent',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  components: { SimilarKssItemComponent },
  data() {
    return {
      postData: this.data.postData,
      submitDisabled: false,
    };
  },
  computed: {
    getSimilarTasks() {
      return this.$store.state.kss.similarTasks;
    },
  },
  methods: {
    scrollToElement() {
      const el = this.$refs.popupel;

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    async createTask() {
      try {
        this.submitDisabled = true;
        this.postData.forceSimilarTask = true;
        await this.$store.dispatch('createKssTask', this.postData);
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.kss.kssCreated'),
          type: 'success',
        });
        this.closeOverlay();
        this.submitDisabled = false;
      } catch (error) {
        this.submitDisabled = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    closeOverlay() {
      this.$store.commit('removeFrontendOverlay', this.data.id);
    },
  },
  mounted() {
    setTimeout(() => this.scrollToElement(), 150);
  },
};
</script>

<style scoped>

.popup-overview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  padding: 1em;
  border-radius: 2em;
  background-color: rgb(255, 255, 255);
  box-shadow: 4px 5px 0 3px rgba(0, 0, 0, 0.1);
  line-height: 1.5em;
  font-size: 12px;
  min-height: 400px;
  max-width: 421px;
  max-height: 700px;
  height: min-content;
  scroll-margin-top: 50px;
  overscroll-behavior: contain;
}

.content-container {
  flex-grow: 1;
  overflow-y: auto;
  overscroll-behavior: contain;
}

.content-container::-webkit-scrollbar {
  width: 8px;
  background: var(--color-white);
  border-radius: 10px
}

.content-container::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 10px;
}

.button-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: sticky;
  bottom: 0;
  padding: 10px 0;
  margin: 25px 0;
  background: white;
}

.popup-button-create,
.popup-button-cancel {
  display: inline-block;
  padding: 5px;
  margin: 4px 2px;
  border: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 50px;
  width: calc(50% - 10px);
  font-size: 16px;
}

.popup-button-create {
  color: var(--color-black);
  background-color: var(--color-orange);
}

.popup-button-cancel {
  padding: 10px 20px;
  color: var(--color-black);
  background-color: var(--color-bisque);
}

.popup-button-create:hover,
.popup-button-cancel:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

.item-list-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  gap: 20px;
}

.overlay-title,
.question {
  text-align: center;
}

.overlay-title {
  font-size: 1.5em;
}

.question {
  margin-top: 20px;
  font-size: 1.2em;
}

@media (max-width: 521px) {
  .popup-overview, .popup-Meeting {
    padding: 0;
  }

;
}

@media only screen and (min-width: 540px) {
  .popup-overview{
    font-size: 14px;
  }
}

@media only screen and (min-width: 600px) {
  .popup-button-cancel,
  .popup-button-create {
    width: auto;
    min-width: 140px;
    flex: 0 0 auto;
  }
}

</style>
