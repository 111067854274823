<template>
  <div class="timer">
    <vee-field v-model="time"
               type="time"
               class="time"
               name="start-time"
               id="input_cal-time"/>
  </div>
  <div class="note">
    <font-awesome-icon icon="fa-triangle-exclamation"/>
    <span>
          {{ $t('KssRuntimeComponent.startKssTrackerNote') }}
    </span>
  </div>
  <kss-recurring-task-component/>
</template>

<script>
import KssRecurringTaskComponent from '@/components/Home/KssCreator/KssRecurringTaskComponent.vue';

export default {
  name: 'KssRuntimeComponent',
  components: { KssRecurringTaskComponent },
  data() {
    return {
      time: `${(`0${new Date().getHours()}`).slice(-2)}:${(`0${new Date().getMinutes()}`).slice(-2)}`,
    };
  },
  computed: {},
  methods: {},
  created() {
    // Round on the next half hour
    const now = new Date();
    const currentMinutes = now.getMinutes();
    let roundedMinutes;

    if (currentMinutes < 30) {
      roundedMinutes = 30;
    } else {
      roundedMinutes = 0;
      now.setHours(now.getHours() + 1); // Increment the hour if rounding down
    }
    now.setMinutes(roundedMinutes);
    const roundedTime = `${(`0${now.getHours()}`).slice(-2)}:${(`0${now.getMinutes()}`).slice(-2)}`;
    this.time = roundedTime;
    this.$store.commit('setSelectedTime', roundedTime);
  },
  watch: {
    time(newTime) {
      this.$store.commit('setSelectedTime', newTime);
    },
  },
};
</script>

<style scoped>
.headline {
  color: var(--color-orange);
  font-size: 1.5rem;
  text-align: center;
}

.timer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
}

.timer .time {
  font-size: 30px;
  font-weight: 700;
  /* padding: var(--card-padding) 0 0 0;*/
}

.start-runtime {
  display: block;
  max-width: 260px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  margin-bottom: 3rem;
  padding: 10px 35px;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

.start-runtime:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

.note {
  /* width: 75%; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.note span {
  font-size: 13px;
  padding-left: 15px;
}

.note i {
  font-size: 27px;
  color: var(--color-error);
}
</style>
