<template>
          <div class="content">
            <div class="forms">
              <vee-form @submit="createKssTask">
                <h1 id="txt_create"> {{ $t('KssRuntimeComponent.createKssTracker') }}</h1>
                <div class="time-wrapper">
                  <div class="time-box">
                    <input v-model="showCalendar"
                           type="checkbox"
                           checked="checked"
                           name="cbox"
                           id="cbox1">
                    <label for="cbox1" class="label" id="lbl_calDate">
                      <font-awesome-icon icon="fa-calendar"/>
                      {{ getFormattedDate ?? $t('KssRuntimeComponent.selectDate') }}
                    </label>
                    <div class="area" id="div_calendar-date">
                      <kss-calendar-component/>
                    </div>
                    <input v-model="showRuntime" type="checkbox" checked name="cbox" id="cbox2">
                    <label for="cbox2" class="label" id="lbl_cal-time">
                      <font-awesome-icon icon="fa-clock"/>
                      {{ getFormattedTime ?? $t('KssRuntimeComponent.selectRuntime') }}
                    </label>
                    <div class="area" id="div_calendar-time">
                      <!----------------- RUNTIME ----------------->
                      <div class="runtime" id="div_runtime">
                        <kss-runtime-component/>
                      </div>
                    </div>
                  </div>
                </div>
                <kss-form-component/>
              </vee-form>
            </div>
            <div class="btns">
              <button type="button" class="btn-create" name="create" id="btn_createKSS"
                      @click.prevent="createKssTask">
                {{ $t('KssRuntimeComponent.createKssTracker') }}
              </button>
            </div>

        <button class="close-btn" @click.prevent="closePopup">
        <font-awesome-icon icon="fa-xmark"/>
      </button>
      </div>
  </template>

<script>
import KssCalendarComponent from '@/components/Home/KssCreator/KssCalendarComponent.vue';
import KssRuntimeComponent from '@/components/Home/KssCreator/KssRuntimeComponent.vue';
import KssFormComponent from '@/components/Home/KssCreator/KssFormComponent.vue';

export default {
  name: 'KssCreatorComponent',
  props: {
    data: Object,
  },
  components: {
    KssFormComponent,
    KssRuntimeComponent,
    KssCalendarComponent,
  },
  data() {
    return {
      // selectedTab: this.tab,
      kssBreadCrumbs: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: '/kss-creator',
          isActive: false,
          name: this.$t('BreadCrumbs.kssTracker'),
        },
        {
          goTo: '/kss-creator',
          isActive: true,
          name: this.$t('BreadCrumbs.kssTracker'),
        },
      ],
    };
  },
  computed: {
    createdTasks() {
      return this.$store.getters.getCurrentCreatedTasks;
    },
    allKssParticipants() {
      return this.$store.getters.getKssParticipants;
    },
    involvedKssUsers() {
      return this.$store.getters.getInvolvedKssUsers;
    },
    archivedTasks() {
      return this.$store.getters.getCurrentArchivedTasks;
    },
    getFormattedDate() {
      const date = this.$store.getters.getSelectedDate;
      if (!date) {
        return null;
      }
      return `${date.getDate()}.${(date.getMonth() + 1)}.${date.getFullYear()}`;
    },
    getFormattedTime() {
      if (!this.$store.state.kss.selectedHours && this.$store.state.kss.selectedHours !== 0) {
        return null;
      }
      return `${(`0${this.$store.state.kss.selectedHours}`).slice(-2)}:${(`0${this.$store.state.kss.selectedMinutes}`).slice(-2)}`;
    },
    showCalendar: {
      get() {
        return this.$store.state.kss.showCalendar;
      },
      set(value) {
        this.$store.commit('setShowCalendar', value);
      },
    },
    showRuntime: {
      get() {
        return this.$store.state.kss.showRuntime;
      },
      set(value) {
        this.$store.commit('setShowRuntime', value);
      },
    },

  },
  methods: {
    closePopup() {
      this.$store.commit('removeFrontendOverlay', this.data.id);
    },
    async createKssTask() {
      try {
        if (this.checkForErrors()) {
          return;
        }

        const data = {
          involvedUsers: this.$store.state.kss.kssParticipants.filter((e) => e.isChecked)
            .map((e) => e._id),
          name: this.$store.state.kss.newMeetingSubject,
          description: this.$store.state.kss.newDescription,
        };

        const dateTaskBegin = new Date(
          this.$store.state.kss.selectedDate.getFullYear(),
          this.$store.state.kss.selectedDate.getMonth(),
          this.$store.state.kss.selectedDate.getDate(),
          this.$store.state.kss.selectedHours,
          this.$store.state.kss.selectedMinutes,
        );

        if (this.$store.state.kss.isRecurringTask) {
          data.repeatRules = this.getRecurringObject(dateTaskBegin.toUTCString());
        } else {
          data.date_taskBegin = dateTaskBegin.toUTCString();
          data.repeatRules = [];
        }

        data.involvedExternalUsers = this.$store.state.kss.inviteEmails;

        await this.$store.dispatch('createKssTask', data);
        if (!this.$store.state.kss.hasSimilarTasks) {
          this.$store.commit('setShowCalendar', true);
          this.$store.commit('setShowRuntime', true);
          this.$store.commit('setToastNotification', {
            id: Math.random(),
            message: this.$t('ToastComponent.kss.kssCreated'),
            type: 'success',
          });
        } else {
          this.$store.commit('addFrontendOverlay', {
            id: Math.random(),
            type: 'similarKssTasks',
            postData: data,
          });
        }
        // this.selectedTab = 'alreadyCreated';
        window.scrollTo(0, 0);
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    checkForErrors() {
      let hasError = false;

      if (!this.$store.state.kss.selectedDate) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.kss.selectDateErr'),
          type: 'error',
        });
        hasError = true;
      }

      if (!this.$store.state.kss.selectedHours && this.$store.state.kss.selectedHours !== 0) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.kss.selectTimeErr'),
          type: 'error',
        });
        hasError = true;
      }

      if ((this.$store.state.kss.selectedHours || this.$store.state.kss.selectedHours === 0)
          && this.$store.state.kss.selectedDate
          && !this.checkDateAndTimeValid()) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.kss.pastDateErr'),
          type: 'error',
        });
        hasError = true;
      }

      if (!this.$store.state.kss.newMeetingSubject) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.kss.selectSubjectErr'),
          type: 'error',
        });
        hasError = true;
      }

      if (this.$store.state.kss.kssParticipants.filter((e) => e.isChecked).length === 0
          && this.$store.state.kss.inviteEmails.length === 0) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('ToastComponent.kss.selectParticipantsErr'),
          type: 'error',
        });
        hasError = true;
      }

      if (this.$store.state.kss.isRecurringTask) {
        const type = this.$store.state.kss.recurringType;
        const subType = this.$store.state.kss.recurringSubtype;
        if (type === 'weekly' || (type === 'custom' && subType === 'week')) {
          const len = this.$store.state.kss.recurringDays
            .filter((e) => e.isSelected).length;

          if (len === 0) {
            this.$store.commit('setToastNotification', {
              id: Math.random(),
              message: this.$t('ToastComponent.kss.selectRecurringDays'),
              type: 'error',
            });
            hasError = true;
          }
        }
      }

      return hasError;
    },
    checkDateAndTimeValid() {
      const selectedDate = new Date(this.$store.state.kss.selectedDate);
      const { selectedHours } = this.$store.state.kss;
      const { selectedMinutes } = this.$store.state.kss;
      selectedDate.setHours(selectedHours, selectedMinutes);

      const currentTime = new Date();
      const timeDifference = currentTime - selectedDate;

      return timeDifference <= 60 * 60 * 1000;
    },
    getRecurringObject(dateTaskBegin) {
      switch (this.$store.state.kss.recurringType) {
        case 'daily':
          return [{
            frequency: 'DAILY',
            start: dateTaskBegin,
            byDayOfWeek: ['MO', 'TU', 'WE', 'TH', 'FR'],
          }];
        case 'weekly':
          return [{
            frequency: 'WEEKLY',
            byDayOfWeek: this.$store.state.kss.recurringDays
              .filter((e) => e.isSelected)
              .map((e) => e.rSchName),
            start: dateTaskBegin,
          }];
        case 'monthly':
          return [{
            frequency: 'MONTHLY',
            start: dateTaskBegin,
          }];
        case 'custom':
          if (this.$store.state.kss.recurringSubtype === 'days') {
            return [{
              frequency: 'DAILY',
              interval: this.$store.state.kss.recurringEveryX,
              start: dateTaskBegin,
            }];
          }
          if (this.$store.state.kss.recurringSubtype === 'weeks') {
            return [{
              frequency: 'WEEKLY',
              interval: this.$store.state.kss.recurringEveryX,
              byDayOfWeek: this.$store.state.kss.recurringDays
                .filter((e) => e.isSelected)
                .map((e) => e.rSchName),
              start: dateTaskBegin,
            }];
          }
          // eslint-disable-next-line no-fallthrough
        default:
          return [];
      }
    },
  },
  mounted() {
    this.$store.dispatch('getTeams');
    // pass the logged in user to select him by default
    this.$store.dispatch('getKssParticipants', this.$store.state.auth.user.name);
    this.$store.dispatch('getArchivedTasks');
    this.$store.dispatch('getCreatedTasks');
  },
  unmounted() {
    this.$store.commit('removeKssTeams');
    this.$store.commit('removeKssParticipants');
    this.$store.commit('removeArchivedTasks');
    this.$store.commit('removeCreatedTasks');
    this.$store.commit('resetInviteEmail');
  },
};
</script>

  <style scoped>

.time-box {
    display: flex;
    flex-wrap: wrap;
  }
  .content {
    background: var(--color-white);
    margin: 0 auto;
    position: relative;
    height: 90%;
    width: 960px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 10px;
    padding: 5px;
  }

  h1 {
    font-family: "Poppins";
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 0.19px;
    line-height: 32px;
    color: var(--color-dark);
    padding: var(--card-padding) 0 var(--card-padding);
  }

  h2 {
    font-family: "Poppins";
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 0.19px;
    line-height: 32px;
    color: var(--color-dark);
  }

  h3 {
    font-family: "Poppins";
    font-size: 30px;
    font-weight: normal;
    letter-spacing: 0.19px;
    line-height: 32px;
    color: var(--color-dark);
  }

  h4 {
    padding: 0.5rem 0;
  }

  p {
    padding: 0.5rem 0;
  }

  .link {
    color: var(--color-orange);
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: 19px;
    text-align: right;
    text-decoration: none;
  }

  .link:hover {
    transition: .5s;
    color: var(--color-fulvous);
  }

  .photo { /*gilt für alle Fotos*/
    display: block;
    width: 100%;
  }

   .navlabel{
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;
      margin: 4px 8px;
      font-weight: 400;
      font-size: 18px;
      cursor: pointer;
      background-color: var(--color-milk);
      border-radius: 10px;
      flex: 1;
  }

  .navlabel.selected-tab {
    color: var(--color-orange);
    border: 1px solid var(--color-orange);
  }

  @media (max-width: 520px) {
    .box .navlabel {
      margin: 16px 6px;
      padding: 16px 16px;
    }}
    @media (min-width: 521px) and (max-width: 768px) {
    .box .navlabel {
      padding: 16px 35px;
      margin: 16px 6px;
    }}

  @media (min-width: 769px) and (max-width: 1024px) {
    .box .navlabel {
      padding: 16px 35px;
      margin: 16px 6px;
    }}
  .tabs-container {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }

  /*---------------- TABSYSTEM NEW > ALREADY > ARCHIVE ------------------*/
  .wrapper {
    min-height: 100vh;
    box-sizing: border-box;
    padding-top: 10px;
    max-width: 960px;
    margin: 0 auto;
    width: 100vw;
  }

  .box {
    display: flex;
    flex-wrap: wrap;
  }

  .box .content {
    padding: 10px 0px 20px;
    order: 99;
    flex-grow: 1;
    width: 100%;
  }

  .box input[type="radio"] {
    display: none;
  }

  .box input[type="radio"]:checked + .navlabel {
    color: var(--color-orange);
  }

  .box input[type="radio"]:checked + label + .content {
    display: block;
  }

  /*---------------- CONTENT NEW ------------------*/
  .box .content .forms {
    /* max-width: 90vw; */
    /* width: 600px; */
    /*height: auto;*/
    display: block;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 20px 30px;
    background: var(--color-milk);
  }

  .box .content .forms .time-wrapper {
    /*min-height: 100vh;*/
    box-sizing: border-box;
    padding: 0px;
    max-width: 960px;
    margin: 0 auto;
  }

  .box .content .forms .time-wrapper .time-box {
    display: flex;
    flex-wrap: wrap;
  }

  .time-box .label {
    text-align: center;
    display: block;
    width: 350px;
    padding-top: 3px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 3px;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px;
    font-weight: 500;
    border-radius: 5px;
    color: var(--color-orange);
    background-color: var(--color-white);
    cursor: pointer;
  }

  .time-box .area {
    display: none;
    order: 99;
    flex-grow: 1;
    width: 100%;
  }

  .time-box input[type="checkbox"] {
    display: none;
  }

  .time-box input[type="checkbox"]:checked + label + .area {
    display: block;
  }

  /*---------------- CONTENT NEW: RUNTIME ------------------*/
  .runtime {
    width: 100%;
    border-radius: 20px;
    box-shadow: 5px 10px 8px var(--color-light-grey);
    padding: 0.5rem;
    margin-bottom: 1rem;
    background-color: var(--color-milk);
  }

  /*------------------ DROPDOWN -------------------*/
  .box .content .forms .dropdown {
    width: 150px;
    height: 30px;
    display: none;
    /* position: relative; */
    /* display: flex; */
    /* float: right; */
    /* flex-direction: column; */
    margin-top: 30px;
  }

  select {
    width: 100%;
    height: 100%;
    font-weight: 300;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    color: var(--color-orange);
    background-color: var(--color-white);
    box-shadow: 5px 10px 8px var(--color-light-grey);
    cursor: pointer;
  }

  select option {
    width: 150px;
    height: 30px;
    font-size: 14px;
    padding: 10px;
    margin: 10px;
    border: none;
    color: var(--color-orange);
    background-color: var(--color-milk);
    cursor: pointer;
  }

  label {
    color: var(--color-dark);
    font-family: "Poppins";
    font-size: 18px;
    letter-spacing: 0.15px;
    line-height: 27px;
    text-align: center;
  }

  /*------------------ BUTTONS -------------------*/
  .btns {
    display: flex;
    align-content: center;
    justify-content: flex-end;
    position: relative;
    margin: 2rem 0;
    box-sizing: border-box;
  }

  .btn-create {
    float: right;
    padding: 5px;
    font-size: 20px;
    width: 15rem;
    height: 2.5rem;
    color: var(--color-black);
    border: none;
    border-radius: 50px;
    background-color: var(--color-bisque);
    cursor: pointer;
  }

  .btn-create:hover {
    transition: .5s;
    background-color: var(--color-fulvous);
  }

  .btn-delete {
    float: left;
    padding: 5px;
    font-size: 20px;
    width: 8rem;
    height: 2.5rem;
    color: var(--color-black);
    border: none;
    border-radius: 50px;
    background: transparent;
    cursor: pointer;
  }

  .btn-delete i {
    padding-right: 10px;
  }

  .btn-delete:hover {
    transition: .5s;
    background-color: var(--color-fulvous);
  }

  .btn-tracker {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3rem;
    padding: 10px 35px;
    border: none;
    border-radius: 50px;
    font-size: 20px;
    color: var(--color-white);
    background-color: var(--color-orange);
    cursor: pointer;
  }

  .btn-tracker:hover {
    transition: .5s;
    background-color: var(--color-fulvous);
  }

  .close-btn {
    font-family: 'Poppins';
    position: absolute;
    top: 5px;
    right: 15px;
    background: none;
    border: none;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }

  /*==================== MEDIA QUERIES =====================*/
  @media (max-width: 520px) {
    .chapter {
      margin: 1rem 0;
    }

    .title {
      margin: 1rem 0;
    }

    .time-box .label {
      margin-left: auto;
      margin-right: auto;
      width: 130px;
    }

    .time-box .label span {
      display: none;
    }

  }

  @media (min-width: 521px) and (max-width: 768px) {
    .chapter {
      margin: 1rem -2rem;
    }

    .title {
      margin: 1rem -2rem;
    }

    .time-box .label {
      margin-left: auto;
      margin-right: auto;
      width: 230px;
    }

    .time-box .label span {
      display: block;
    }

  }

  @media (min-width: 769px) and (max-width: 1024px) {
    .chapter {
      margin: 0 -3rem 1rem;
    }

    .title {
      margin: 0 -3rem 1rem;
    }
  }

  @media (min-width: 1920px) {
    .chapter {
      margin: 0rem -10rem 1rem;
    }

    .title {
      margin: 0rem -10rem 1rem;
    }

    .time-box .label {
      margin-left: auto;
      margin-right: auto;
    }
  }

  </style>
