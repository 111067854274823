<template>
  <p id="question-text" class="question-text">
    {{ getQuestionText }}
    <span v-if="getHoverInfo" class="icon-container">
      <i class="fas fa-info-circle">
        <font-awesome-icon icon="fa-circle-question"/>
      </i>
      <span class="hover-bubble">{{ getHoverInfo }}</span>
    </span>
  </p>
  <img class="smiley" id="img_smiley" :src="getSmileyPicture" alt="">
  <div class="slide-container">
    <input v-model="answer" type="range" min="1" max="5" step="1"
           class="slider"
           id="sld_myRange"
           @click="handleClick"
           @touchend="handleClick">
    <ul class="datalist">
      <li>{{ $t('SliderQuestion.datalistOptions.stronglyDisagree') }}</li>
      <li>{{ $t('SliderQuestion.datalistOptions.disagree') }}</li>
      <li>{{ $t('SliderQuestion.datalistOptions.indifferent') }}</li>
      <li>{{ $t('SliderQuestion.datalistOptions.agree') }}</li>
      <li>{{ $t('SliderQuestion.datalistOptions.stronglyAgree') }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SliderQuestion',
  data() {
    return {
      question: this.$store.state.questionnaireManagement.selectedQuestion,
    };
  },
  computed: {
    getQuestionText() {
      const questionText = this.question.properties.content.text_question[this.getLocale] ?? '';
      if (this.getCreatorName) {
        return questionText.replaceAll('§USER_NAME§', this.getCreatorName);
      }

      return questionText;
    },
    getSmileyPicture() {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/smiley-${this.answer ?? String(0) + String('-') + String(this.getLocale)}.png`);
    },
    getHoverInfo() {
      return this.question.properties.content.description?.[this.getLocale];
    },
    getLocale() {
      return this.$i18n.locale;
    },
    getCreatorName() {
      return this.$store.state.questionnaireManagement.assessment.creator?.name;
    },
    answer: {
      get() {
        return this.$store.state.questionnaireManagement.answer;
      },
      set(value) {
        this.$store.commit('setAnswer', Number(value));
      },
    },
  },
  methods: {
    handleClick(value) {
      this.$store.commit('setAnswer', Number(value.target.value));
    },
  },
};
</script>

<style scoped>

.question-text {
  text-align: center;
  padding: 2rem 0;
}

.smiley {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.3rem;
  width: 400px;
}

/*------------------ SLIDER -------------------*/
.slide-container {
  width: 100%; /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 18px; /* Specified height */
  border-radius: 50px;
  background: linear-gradient(90deg, var(--color-deep-champagne) 0%,
  var(--color-yellow-orange) 40%, var(--color-orange) 100%); /*Background*/
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 30px; /* Set a specific slider handle width */
  height: 30px; /* Slider handle height */
  border-radius: 50px; /* Slider handle radius */
  background: var(--color-light-grey); /* background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 30px; /* Set a specific slider handle width */
  height: 30px; /* Slider handle height */
  border-radius: 50px; /* Slider handle radius */
  border: none;
  -webkit-box-shadow: 1px 1px 7px 1px var(--color-dark);
  box-shadow: 1px 1px 7px 1px var(--color-dark);
  background: var(--color-light-grey); /* background */
  cursor: pointer; /* Cursor on hover */
}

/* style datalist */
.datalist {
  padding: 0;
  margin: 0;height: 20px;
  padding-top: 10px;
  padding-bottom: 70px;

}

.datalist li {
  width: 20%;
  list-style-type: none;
  float: left;
  height: 10px;
  position: relative;
  box-sizing: border-box;
  font-size: 0.6rem;
  font-weight: 500;
}

.datalist li:nth-child(2) {
  padding-left: 3.8%;
}

.datalist li:nth-child(3) {
  text-align: center;
}

.datalist li:nth-child(4) {
  padding-left: 13%;
}

.datalist li:nth-child(5) {
  text-align: right;
}

.datalist li::before {
  font-size: 10px;
  position: absolute;
  top: 5px;
  margin-left: 2px;
}

.icon-container {
  position: relative;
  display: inline-block;
}

.hover-bubble {
  visibility: hidden;
  width: 150px;
  background-color: var(--color-orange);
  color: var(--color-milk);;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -75px;
}

.icon-container:hover .hover-bubble {
  visibility: visible;
}

.icon-container:hover i {
  color: var(--color-orange);
  transform: scale(1.2);
}

/*==================== MEDIA QUERIES =====================*/
@media (max-width: 520px) {
  .question-text {
    padding: 1rem 0.2rem;
  }

  .smiley {
    width: 100%;
    padding: 2rem 0;
    overflow: hidden;
  }

  .datalist li:nth-child(2) {
    padding-left: 1%;
  }

  .datalist li:nth-child(3) {
    padding-left: 3%;
  }

  .datalist li:nth-child(4) {
    padding-left: 9%;
  }

  .datalist li:nth-child(5) {
    padding-left: 7%;
  }

  .values-integrative-complexity {
    padding: 0 0 3rem;
    margin: 2rem 0;
  }
}

@media (min-width: 521px) and (max-width: 768px) {
  .smiley {
    width: 80%;
    overflow: hidden;
  }

  .datalist li:nth-child(5) {
    padding-left: 12%;
  }

}

@media (min-width: 769px) and (max-width: 1024px) {
  .smiley {
    width: 70%;
    overflow: hidden;
  }

}

@media (min-width: 1920px) {
  .smiley {
    width: 60%;
    overflow: hidden;
  }

  .question-text {
    padding: 1rem 2rem 0;
    font-size: 23px;
  }

  .datalist li {
    font-size: 0.8rem;
  }
}
</style>
