<template>
  <div class="user-grid-element">
    <div class="checkbox-icon-wrapper" @click.prevent="handleCheckboxChangeUser(user)">
      <font-awesome-icon
        v-if="!isCheckedUser(user)"
        class="unchecked-icon"
        icon="fa-regular fa-square"
      />
      <font-awesome-icon v-if="isCheckedUser(user)" class="checked-icon" icon="fa-square-check" />
    </div>
  </div>

  <div class="profile-name-block user-grid-element" @click.prevent="openPopup">
    <div class="profile-photo">
      <img :src="getProfilePicture" alt="Profile picture" class="photo" />
    </div>
    <p>{{ user.name }}</p>
  </div>

  <div class="user-grid-element level-text" @click.prevent="openPopup">
    {{ user.currentRewardStatus?.currentLevel }}
  </div>

  <div class="user-grid-element" @click.prevent="openPopup">{{ user.email }}</div>

  <div class="user-grid-element" @click.prevent="openPopup">
    {{ formatDate(user.date_lastActivity) }}
  </div>
</template>

<script>
export default {
  name: 'UserAdministrationTableRowProfileComponent',
  components: {},
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['deleteUser'],
  computed: {
    getProfilePicture() {
      if (this.user.picture_profile?.small) {
        return this.user.picture_profile.small;
      }
      // eslint-disable-next-line global-require
      return require('@/assets/smiley-profil-bild.png');
    },
  },
  methods: {
    openPopup() {
      this.$store.commit('addFrontendOverlay', {
        id: Math.random(),
        type: 'superUserProfile',
        userData: this.user,
        teams: this.teams,
      });
    },
    formatDate(originalDate) {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'CET',
        hour12: false,
      };

      const getLocale = () => (
        (navigator.languages && navigator.languages.length)
          ? navigator.languages[0] : navigator.language);
      const Locale = getLocale();
      // Locale = 'en-US';
      // Locale = 'de-DE';

      const formattedDate = new Date(originalDate).toLocaleString(Locale, options);
      if (formattedDate === 'Invalid Date') {
        return ' ';
      }
      return formattedDate;
    },
    isCheckedUser(user) {
      return this.$store.getters.isCheckedUser(user._id);
    },
    handleCheckboxChangeUser(user) {
      if (this.isCheckedUser(this.user)) {
        this.$store.dispatch('removeCheckedUser', user._id);
      } else {
        this.$store.dispatch('addCheckedUser', user);
      }
    },
  },
};
</script>

<style scoped>
.level-text {
  justify-content: center;
  padding-right: 7px;
}
.user-grid-element {
  height: 100%;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  overflow-x: auto;
}

.user-grid-icons {
  display: flex;
  justify-content: space-evenly;
}

user-grid-icons

/*------------------- PROFIL -----------------------*/
.profile {
  align-items: center;
  width: 100%;
  position: relative;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: var(--color-light);
}

.profile-name-block {
  display: flex;
  flex-direction: row;
}

.profile p {
  text-align: left;
  word-break: break-word;
  border-right: 1px solid gray;
}

.profile-photo {
  margin-right: 5px;
  width: 45px;
  animation: 1;
  overflow: hidden;
  border-radius: 50%;
}

.trash-icon {
  font-size: 1.5rem;
  color: gray;
  cursor: pointer;
}

.pill-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
}

.pill-element {
  display: flex;
  align-items: center;
  background-color: var(--color-orange);
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  margin: 5px;
  color: var(--color-milk);
}
/*------------------- CHECKBOX -----------------------*/

.checkbox-icon-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.checked-icon {
  color: var(--color-orange);
  font-size: 20px;
}

.unchecked-icon {
  font-size: 20px;
}

@media (max-width: 600px) {
  .profile {
    margin: 0.2rem;
    padding: 0.2rem;
  }

  .profile-photo {
    width: 40px;
    height: 40px;
  }
}
</style>
