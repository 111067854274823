<template>
  <div class="popup-overview">
    <div class="popup-content">
      <div v-html="getContent"></div>
    </div>
    <button class="close-btn" @click.prevent="deactivate">
      <font-awesome-icon icon="fa-xmark"/>
    </button>
  </div>
</template>

<script>
export default {
  name: 'HtmlOverlayComponent',
  props: {
    overlay: {
      type: Object,
      default: null,
    },
  },
  computed: {
    getLocale() {
      return this.$i18n.locale;
    },
    getContent() {
      return this.overlay?.content?.html?.[this.getLocale] ?? null;
    },
  },
  methods: {
    deactivate() {
      this.$store.dispatch('deactivateOverlay', this.overlay._id);
    },
  },
};
</script>

<style scoped>
.popup-overview {
  font-size: 12px;
  background-color: rgb(255, 255, 255);
  padding: 1em;
  border-radius: 2em;
  line-height: 1.5em;
  box-shadow: 4px 5px 0 3px rgba(0, 0, 0, 0.1);
  width: 90%;
  height: 80%;
  position: relative;
  overflow-y: auto;
}

.popup-content {
  position: relative;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.popup-overview::-webkit-scrollbar {
  width: 8px;
  background: var(--color-white);
  border-radius: 0 8px 8px 0;
}

.popup-overview::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.popup-overview::-webkit-scrollbar-track {
  background: var(--color-white);
  margin-top: 10px;
  margin-bottom: 10px;
}

.close-btn {
  font-family: 'Poppins';
  position: absolute;
  top: 5px;
  right: 15px;
  background: none;
  border: none;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.close-btn:hover {
  color: var(--color-orange);
}

.popup-overview h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}

@media only screen and (min-width: 540px) {
  .popup-overview {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1150px) {
  .popup-overview {
    font-size: 14px;
    max-width: 60%;
  }
}

</style>
