<template>
  <div class="popup-feedback" ref="travelel">
    <button class="close-btn" @click="closePopup">
      <font-awesome-icon icon="fa-xmark"/>
    </button>
    <div class="feedback-grid">
      <font-awesome-icon class="lightbulb-icon" icon="fa-regular fa-lightbulb"/>
      <div>
        <p><b>{{ $t('TravelBuddyFeedbackOverlayComponent.yourFeedback') }}</b></p>
      </div>
    </div>
    <div class="container">
      <form action="#">
        <div class="feedback-grid4">
          <p id="status-subheading" class="subheadings">
            {{$t('TravelBuddyFeedbackOverlayComponent.meetingStatusQuestion')}}
          </p>
          <div class="status-button-container">
            <button :class="{'active': this.meetingStatusSelected === 'completed' }"
                    type="button" class="status-button">
              {{ $t('TravelBuddyFeedbackOverlayComponent.completed') }}
            </button>
            <button :class="{'active': this.meetingStatusSelected === 'skipped' }"
                    type="button" class="status-button">
              {{ $t('TravelBuddyFeedbackOverlayComponent.skip') }}
            </button>
          </div>
        </div>
        <div class="feedback-grid2">
          <p class="subheadings">{{ this.dynamicMeetingFeedbackQuestion}}</p>
        </div>
        <textarea v-model="feedbackText"
                  :placeholder="$t('TravelBuddyFeedbackOverlayComponent.placeholderText')"/>
        <div class="feedback-grid3">
          <button type="button" class="grid-button-submit"
                  @click.prevent="submitFeedback">
            {{ $t('TravelBuddyFeedbackOverlayComponent.finish') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TravelBuddyFeedbackOverlayComponent',
  props: {
    data: Object,
  },
  data() {
    return {
      feedbackText: '',
    };
  },
  mounted() {
    this.scrollToElement();
  },
  methods: {
    // TODO
    // Dynamic Questions for feedback component
    changeMeetingStatus(status) {
      this.$store.commit('setTravelBuddyMeetingStatus', status);
    },
    // Automatic Smooth scroll to position of overlay component
    scrollToElement() {
      const el = this.$refs.travelel;
      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    async submitFeedback() {
      const meetingInfo = this.$store.getters.getTravelBuddyMeetingInfo;
      try {
        this.buttonDisabled = true;
        await this.$store.dispatch('submitTravelBuddyFeedback', {
          meetingDate: new Date(),
          meetingID: meetingInfo.meetingInfo._id,
          meetingStatus: this.meetingStatusSelected,
          feedbackText: this.feedbackText.trim(),
        });
        meetingInfo.meetingInfo.meetingStatus = this.meetingStatusSelected;
        this.buttonDisabled = false;
        await this.$store.commit('removeFrontendOverlay', this.data.id);
        await this.$store.commit('setTravelBuddyFeedbackText', this.feedbackText.trim());
        await this.$store.commit('setTravelBuddyMeetingInfo', meetingInfo);
      } catch (error) {
        this.buttonDisabled = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('TravelBuddyFeedbackOverlayComponent.errorCouldNotFeedback'),
          type: 'error',
        });
      }
    },
    closePopup() {
      this.changeMeetingStatus(this.travelBuddyMeeting.meetingInfo.meetingStatus);
      this.$store.commit('removeFrontendOverlay', this.data.id);
    },
  },
  computed: {
    travelBuddyMeeting() {
      const travelBuddyMeetingInfo = this.$store.getters.getTravelBuddyMeetingInfo;
      if (travelBuddyMeetingInfo.meetingThisWeek) {
        return travelBuddyMeetingInfo;
      }
      return false;
    },
    meetingStatusSelected() {
      return this.$store.getters.getTravelBuddyMeetingStatus;
    },
    dynamicMeetingFeedbackQuestion() {
      let meetingText = '';
      if (this.meetingStatusSelected === 'completed') {
        meetingText = this.$t('TravelBuddyFeedbackOverlayComponent.howDoYouFeel');
      } if (this.meetingStatusSelected === 'skipped') {
        meetingText = this.$t('TravelBuddyFeedbackOverlayComponent.howDoYouFeelSkip');
      }
      return meetingText;
    },
  },
};
</script>

<style scoped>

.popup-feedback {
  margin: 0 auto;
  width: 95vw;
  color: white;
  font-size: 14px;
  position: absolute;
  top: var(--feedback-top);
  left: 50%;
  transform: translate(-50%, -50%); /* Add transform to center the popup */
  background-color: rgb(0, 0, 0);
  padding: 1em 3em;
  border-radius: var(--card-border-radius);
  line-height: 1.5em;
  max-width: 960px;
  scroll-margin-top: var(--feedback-scroll-margin)
}

.popup-feedback .container {
  padding-left: 3em;
}

.feedback-grid4 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.status-button-container {
  display: flex;
  flex-direction: row;
}

.status-button {
  margin-left: 10px;
  max-height: 40px;
  border: 1px solid var(--color-orange);        /*border: 1px solid #1E2019;*/
  padding: .5em 1.5em .5em 1.5em;
  border-radius: 2em;
}

.popup-feedback .container p{
  padding-left: 0.5rem;
  padding-right: 3rem;
}

.lightbulb-icon {
  font-size: 32px;
  color: #fdd835;
  text-shadow: 0 0 10px #fdd835;
}

.feedback-grid3 {
  padding-right: 3.5rem;
}

.feedback-grid4 button.grid-button-submit {
  cursor: pointer;
  text-decoration: none;
  color: #1B1B1B;                               /*color: white;*/
  background: white;                       /*background: #F49718 none;*/
  border: 1px solid var(--color-orange);        /*border: 1px solid #1E2019;*/
  padding: .5em 1.5em .5em 1.5em;
  border-radius: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: min-content;
}

.feedback-grid2 button.grid-button > svg,
.feedback-grid3 button.grid-button-submit > svg {
  margin-right: 8px;
}

.feedback-grid2 button.grid-button:hover,
.feedback-grid3 button.grid-button-submit:hover {
  text-decoration: none;
  color: white;                                  /*color: #1B1B1B;*/
  background-color: #F49718;                      /*background-color: white;*/
  border: 1px solid #1E2019;                     /*border: 1px solid var(--color-orange);*/
  padding: .5em 1.5em .5em 1.5em;
  border-radius: 2em;
}

.feedback-grid2 button.grid-button:focus,
.feedback-grid3 button.grid-button-submit:focus,
.feedback-grid4 button.grid-button-submit:focus {
  outline: none;
}

.subheadings {
  width: 40% !important;
  white-space: nowrap;
  padding-top: 1rem;
}

#status-subheading {
  margin-right: 40px;
}
.grid-button-submit:disabled, .grid-button-submit:disabled:hover, .grid-button:disabled:hover  {
  opacity: 0.4;
  background: var(--color-disabled);
}

.container textarea {
  resize: vertical;
}

@media only screen and (min-width: 1056px) {
  .popup-feedback {
    font-size: 16px;
    width: 60%;
  }

  .grid-button, .grid-button-submit {
    width: auto;
  }
  .feedback-grid3 {
    padding-right: 2.5rem;
  }

  .popup-feedback .container{
    padding-top: 0.5rem;
    padding-left: 0rem;
  }
  .popup-feedback .container p{
    padding-left: 0.5rem;
    padding-right: 2.5rem;
  }

  .subheadings {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1055px) {

  .popup-feedback {
    font-size: 16px;
    left: 50%;
    width: 95vw;
    padding: 0.5rem 1.5rem 1.5rem 1.5rem;
    position: absolute !important;
  }

  .popup-feedback b {
    line-height: 20px;
  }

  .popup-feedback .container{
    padding-top: 0.5rem;
    padding-left: 0rem;
  }

  .subheadings {
    width: 100% !important;
  }

  .popup-feedback form textarea {
    width: 98%;
  }

  .feedback-grid {
    margin-left: -1em !important;
  }
}

.feedback-grid {
  display: grid;
  margin-left: -2em;
  grid-template-columns: 50px 1fr 1fr;
  place-items: center;
  justify-items: center;
}

.feedback-grid div {
  text-align: left;
  width: 100%;
  line-height: .7em;
}

.feedback-grid2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5em;
  place-items: center;
  justify-items: center;
}

@media only screen and (min-width: 1080px) {
  .feedback-grid2 {
    display: flex; /* Change from grid to flex */
    justify-content: flex-start; /* Add space between the two columns */
    align-items: center; /* Vertically align items */
  }

  .subheadings {
    width: 45% !important;
  }

}

#grid-button2 img {
  rotate: 180deg;
}

.feedback-grid3 {
  display: grid;
  place-items: center;
  justify-items: right;
}

.feedback-grid3 .grid-button-submit {
  text-decoration: none;
  color: #1B1B1B;                               /*color: #1B1B1B;*/
  background: white none;                       /*background: #F49718;*/
  border: 1px solid var(--color-orange);        /*border: 1px solid #1B1B1B;*/
  padding: .5em 1.5em .5em 1.5em;
  border-radius: 2em;
  display: inline-flex;
  align-items: center;
  width: auto;
  max-width: 150px;
}

.popup-feedback form textarea {
  width: 100%;
  height: 6.5em;
  border: none;
  outline: none;
  color: #000;
  font-size: 16px;
  margin-bottom: 1em;
  margin-top: -.5em;
  border-radius: 2em;
  padding: 1em;
}

.popup-feedback form textarea:disabled {
  background-color: rgba(219, 219, 219, 0.9);
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 24px;
  background-color: transparent;
  border: none;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  color: var(--color-milk);
}

.close-btn:hover {
  color: var(--color-orange);
}

@media screen and (max-width: 421px) {
  .feedback-grid2 {
    padding: 0 3em 1.5em 0;
  }
  .subheadings {
    white-space: normal;
  }
}

.active {
  background-color: var(--color-orange);
}
</style>
