<template>
  <div class="popup-overview" ref="workshopEl">
    <button class="close-btn" @click="this.closePopup">
      <font-awesome-icon icon="fa-xmark" />
    </button>
    <div class="popup-content">
      <div class="header">
        <div>{{ workshop.workshopTitle }}</div>
        <div>{{ $t('WorkshopComponent.Facilitator') }}:
           {{ workshop.workshopFacilitator.name }}</div>
      </div>
      <p class="workshop-date">
        <font-awesome-icon class="calendar-icon" icon="fa-calendar" />
        {{ formattedDate }}
      </p>
      <p class="workshop-des">{{ $t('WorkshopComponent.SendReflectionToAllParticipant') }}</p>
      <div class="participant-selection">
        <span
          v-for="participant in workshop.participants"
          @click="onToggleParticipant(participant)"
          :key="participant"
          class="participant-selection-option"
          :class="isSelected(participant) ? 'selected' : 'unselected'"
        >
          {{ participant.name }}
        </span>
      </div>
      <div class="btns">
        <button @click="onSubmit" type="button" class="btn-create">
          {{ $t('WorkshopComponent.SendReflection') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WorkshopParticipantsOverlayComponent',
  props: [],
  components: {},
  data() {
    return {
      selectedParticipants: this.$store.getters.currentSelectedWorkshop.participants,
    };
  },
  computed: {
    workshop() {
      return this.$store.getters.currentSelectedWorkshop;
    },
    formattedDate() {
      const date = new Date(this.workshop.createdAt);
      return `${`0${date.getDate()}`.slice(-2)}.${`0${date.getMonth() + 1}`.slice(
        -2,
      )}.${date.getFullYear()}`;
    },
  },
  mounted() {
    this.scrollToElement();
  },
  methods: {
    onSubmit() {
      if (this.selectedParticipants.length === 0) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('WorkshopComponent.YouNeedToChooseAtLeastOneParticipant'),
          type: 'error',
        });
      } else {
        try {
          this.$store.dispatch('sendReflexionWorkshop', {
            workshopId: this.workshop._id,
            participants: this.selectedParticipants.map((s) => s._id),
          });
          this.$store.commit('setToastNotification', {
            id: Math.random(),
            message: this.$t('ToastComponent.workshop.workshopSendReflection'),
            type: 'success',
          });
          this.closePopup();
        } catch (error) {
          this.$store.commit('setToastNotification', {
            id: Math.random(),
            message: this.$t('ToastComponent.workshop.workshopSendReflectionError'),
            type: 'error',
          });
        }
      }
    },
    scrollToElement() {
      const el = this.$refs.workshopEl;
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    closePopup() {
      this.$store.commit('removeFrontendOverlay');
    },
    onToggleParticipant(participant) {
      if (this.isSelected(participant)) {
        this.selectedParticipants = this.selectedParticipants.filter(
          (s) => s._id !== participant._id,
        );
      } else this.selectedParticipants.push(participant);
    },
    isSelected(participant) {
      return !!this.selectedParticipants.find((s) => s._id === participant._id);
    },
  },
};
</script>
<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  font-size: 22px;
  font-weight: 500;
  flex-wrap: wrap;
  gap: 10px;
}
.popup-overview {
  font-size: 16px;
  margin-top: 150px;
  background-color: rgb(251, 251, 251);
  padding: 1em;
  border-radius: 10px;
  line-height: 1.5em;
  width: 90%;
  max-width: 600px;
  height: min-content;
  position: relative;
  scroll-margin-top: 200px;
}

.popup-content {
  position: relative;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  color: var(--color-dark);
}

.close-btn:hover {
  color: var(--color-orange);
}

.workshop-date {
  color: var(--color-orange);
  font-weight: 500;
  margin-top: 12px;
  padding: 0;
}
.workshop-des {
  font-size: 20px;
}

.btns {
  display: flex;
  align-content: center;
  justify-content: flex-end;
  position: relative;
  margin-top: 4rem;
  box-sizing: border-box;
}

.btn-create {
  font-family: Poppins, sans-serif;
  float: right;
  padding: 8px 15px;
  color: white;
  border: none;
  border-radius: 50px;
  background-color: var(--color-orange);
  cursor: pointer;
}

.btn-create:hover {
  transition: 0.5s;
  background-color: var(--color-fulvous);
}
.participant-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin: 8px 0px;
}
.participant-selection-option {
  font-size: 14px;
  padding: 4px 15px;
  color: white;
  min-width: 100px;
  text-align: center;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}
.selected {
  background-color: var(--color-orange);
}
.unselected {
  background-color: var(--color-disabled);
}
</style>
