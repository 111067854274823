<template>
    <div v-if="data" class="omind-dev-tip-box">
      <div class="omind-dev-tip-head">
        <span class="title">{{ data.title[lang] }}</span>
        <img class="omind-dev-tip-tooltip"
          v-tooltip="data.tooltip[lang]" src="@/assets/iconfinder.svg" alt="icon finder" />
        <span v-if="data.value">: {{ data.value }}</span>
      </div>
      <div class="omind-dev-tip-content">
        <ol v-if="data.list?.length > 0">
          <li v-for="v in data.list" :key="v">{{ v }}</li>
        </ol>
        <span v-if="data.text[lang]" v-html="data.text[lang]"></span>
      </div>
    </div>
  </template>

<script>
export default {
  name: 'OMindsetDevelopmentTipComponent',
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
  },
};
</script>
  <style scoped>
  .omind-dev-tip-box {
    position: relative;
    border: 1px solid;
    border-radius: 12px;
    border-color: rgb(244, 151, 24);
    background-color: white;
    padding: 10px 15px;
    margin: 4px;
    color: black;
  }

  .omind-dev-tip-head {
    font-size: 18px;
  }
  .omind-dev-tip-head .title {
    font-weight: 500;
    overflow-wrap: anywhere;
    line-height: 2;
  }
  .omind-dev-tip-head img {
    margin-left: 4px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .omind-dev-tip-content ol {
    margin-left: 24px;
  }
  .omind-dev-tip-tooltip {
    position: absolute;
    right: 10px;
  }
  </style>
