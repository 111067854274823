import axios from 'axios';

export default class NetworkTeammanagement {
  static updateTeam(team, config) {
    return axios.post('team/updateTeam', team, config);
  }

  static createTeam(team, config) {
    return axios.post('team/create', team, config);
  }

  static deleteTeam(team, config) {
    return axios.post('team/delete', team, config);
  }
}
