import store from '@/store';

// eslint-disable-next-line import/prefer-default-export
export function checkIfNewPasswordSuccessful(to, from, next) {
  if (store.state.auth.newPasswordSetSuccesful) {
    next();
  } else {
    next('/auth');
  }
}
