<template>
  <div class="filter-component">
    <BaseFilterComponent
      v-if="ready && selections.length < max"
      :allowFilter="allowFilter"
      @saveSelection="saveSelection"
      :deleteFilterOnSave="true"
    />
    <template v-if="ready">
      <BaseFilterComponent
        v-for="selection in selections"
        :key="selection.id"
        :allowFilter="allowFilter"
        :selection="selection"
        :deleteFilterOnSave="false"
        @saveSelection="saveSelection"
        @removeSelection="removeSelection"
      />
    </template>
  </div>
</template>

<script>
import BaseFilterComponent from './BaseFilterComponent.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  props: ['max', 'allowFilter'],
  name: 'FilterComponentComponent',
  components: { BaseFilterComponent },
  emits: ['saveSelection'],
  data() {
    return {
      ready: false,
      selections: [],
      selectionCount: 1,
    };
  },
  computed: {},
  async mounted() {
    await this.$store.dispatch('fetchFilterData');
    this.ready = true;
  },
  methods: {
    saveSelection(selection) {
      if (selection.id) {
        const index = this.selections.findIndex((s) => s.id === selection.id);
        if (index !== -1) {
          this.selections[index].selection = { ...selection };
        }
      } else {
        this.selections.push({
          id: uuidv4(),
          name: {
            en: `${this.$t('FiltersComponent.selection')} ${this.selectionCount}`,
            de: `${this.$t('FiltersComponent.selection')} ${this.selectionCount}`,
          },
          selection,
        });
        this.selectionCount += 1;
      }
      this.$emit('saveSelection', this.selections);
    },
    removeSelection(id) {
      this.selections = this.selections.filter((s) => s.id !== id);
      this.$emit('saveSelection', this.selections);
    },
  },
};
</script>

<style scoped>
.filter-component {
  display: flex;
}
</style>
