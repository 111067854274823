import { createApp } from 'vue';
import { VTooltip } from 'floating-vue';

import axios from '@/includes/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './includes/i18n';
import VeeValidate from './includes/validation';
import FontAwesome from './includes/fontawesome';
import keycloak from '../keycloak';

import './assets/fonts/poppins.css';
import 'floating-vue/dist/style.css';

const app = createApp(App);
app.directive('tooltip', VTooltip);
app.use(i18n);
app.use(VeeValidate);
app.use(FontAwesome);
app.use(store);

if (process.env.VUE_APP_KEYCLOAK === 'true') {
  keycloak.init({
    checkLoginIframe: false,
    onLoad: 'check-sso',
    redirectUri: process.env.VUE_APP_KEYCLOAK_REDIRECT_URI,
  })
    .then(async () => {
      app.use(axios);
      app.use(router);
      app.mount('#app');
      setInterval(async () => {
        await keycloak.updateToken(process.env.VUE_APP_KEYCLOAK_TOKEN_MIN_VALIDITY_TIME);
      }, 5000);
    });
} else {
  app.use(axios);
  app.use(router);
  app.mount('#app');
}
