<template>
  <toast-notifications-component/>
  <router-view/>
  <overlay-container-component/>
  <frontend-overlay-component/>
  <home-screen-prompt/>
</template>
<script>
import ToastNotificationsComponent from '@/components/global/ToastNotificationsComponent.vue';
import FrontendOverlayComponent from '@/components/global/FrontendOverlayComponent.vue';
import OverlayContainerComponent from '@/components/global/OverlayContainerComponent.vue';
import HomeScreenPrompt from '@/components/PWA/HomeScreenPrompt.vue';

export default {
  components: {
    OverlayContainerComponent,
    ToastNotificationsComponent,
    FrontendOverlayComponent,
    HomeScreenPrompt,
  },
  mounted() {
    this.$root.$i18n.locale = this.$store.state.auth.lang;
  },
};
</script>
<style>
@import "/node_modules/flag-icons/css/flag-icons.min.css";

body {
  overflow-x: hidden;
  font-family: Poppins, sans-serif;
}

ol li {
    list-style-type: decimal;
    padding-left: 0.25rem;
}

ul li {
    list-style-type: disc;
    padding-left: 0.1rem;
}
</style>
