<template>
  <p id="question-text" class="question-text">
    {{ question.properties.content.text_question[getLocale] ?? '' }}
    <span v-if="getHoverInfo" class="icon-container">
      <i class="fas fa-info-circle">
        <font-awesome-icon icon="fa-circle-question"/>
      </i>
      <span class="hover-bubble">{{ getHoverInfo }}</span>
    </span>
  </p>
  <div class="nominate-choice">
    <i class="fa-solid fa-crown">
      <font-awesome-icon icon="fa-crown"/>
    </i>
    <img class="profile-photo" src="@/assets/smiley-3.png" alt="test">
    <span class="kss-champ">{{ $t('KssChampionQuestion.kssChampion') }}</span>
  </div>
  <div id="nominate-box" class="nominate-box">
    <label id="nominate-label" v-for="(member, i) in involvedUsers" :key="member._id"
           @click.prevent="select(i)">
      <span>{{ member.name }}</span>
      <div class="circle">
        <div :class="getCircleClass(member)"/>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'KssChampionQuestion',
  data() {
    return {
      question: this.$store.state.questionnaireManagement.selectedQuestion,
      involvedUsers: this.$store.getters.getInvolvedUsers,
    };
  },
  computed: {
    getLocale() {
      return this.$i18n.locale;
    },
    getHoverInfo() {
      return this.question.properties.content.description?.[this.getLocale];
    },
  },
  methods: {
    select(index) {
      this.$store.commit('selectInvolvedUser', index);
    },
    getCircleClass(member) {
      if (member.isChecked) {
        return 'circle-isChecked';
      }
      return 'circle-isNotChecked';
    },
  },
};
</script>

<style scoped>

.question-text {
  text-align: center;
  padding: 2rem 0;
}

.profile-photo {
  width: 60px;
  animation: 1;
  overflow: hidden;
  border-radius: 50%;
}

/*------------------ KSS NOMINATION -------------------*/
.nominate-choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--card-padding);
}

.nominate-choice i {
  color: var(--color-orange);
}

.nominate-choice .profile-photo {
  width: 5rem;
  border: 2px solid var(--color-orange);
  border-radius: 50%;
}

.nominate-choice span {
  color: var(--color-orange);
  font-weight: 600;
}

.nominate-box {
  padding: var(--card-padding);
  height: 325px;
  overflow-y: scroll;
}

.nominate-box label input {
  display: none;
}

.nominate-box label {
  display: block;
  cursor: pointer;
  margin-bottom: 20px;
  position: relative;
}

.nominate-box label .circle {
  height: 30px;
  width: 30px;
  border: 2px solid var(--color-disabled);
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 3px;
  transition: border-color .5s ease;
}

.nominate-box label .circle-isChecked {
  content: '';
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: var(--color-orange);
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  margin-left: 4px;
  margin-top: 4px;
  transition: transform .5s ease;
  transform: scale(1);
}

.nominate-box label .circle-isNotChecked {
  content: '';
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: var(--color-orange);
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  margin-left: 4px;
  margin-top: 4px;
  transition: transform .5s ease;
  transform: scale(0);
}

.nominate-box label span {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-start;
  transition: color .5s ease;
  padding-right: 20px;
}

.nominate-box .profile-photo {
  flex: 0 0 80px
}

.icon-container {
  position: relative;
  display: inline-block;
}

.hover-bubble {
  visibility: hidden;
  width: 150px;
  background-color: var(--color-orange);
  color: var(--color-milk);;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -75px;
}

.icon-container:hover .hover-bubble {
  visibility: visible;
}

.icon-container:hover i {
  color: var(--color-orange);
  transform: scale(1.2);
}

/*==================== MEDIA QUERIES =====================*/
@media (max-width: 520px) {
 .question-text {
    padding: 1rem 0.2rem;
  }
}

@media (min-width: 1920px) {
 .question-text {
    padding: 1rem 2rem 0;
    font-size: 23px;
  }
}
</style>
