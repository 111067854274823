import NetworkWorkshop from '@/helpers/networking/NetworkWorkshop';

export default {
  state: {
    workshopItems: [],
    selectedWorkshop: {},
    workshopTags: [],
  },
  getters: {
    currentWorkshopItems: (state) => state.workshopItems,
    currentSelectedWorkshop: (state) => state.selectedWorkshop,
    getWorkshopTags: (state) => state.workshopTags,
  },
  mutations: {
    setWorkshopItems: (state, payloads) => {
      state.workshopItems = payloads;
    },
    setSelectedWorkshop: (state, payloads) => {
      state.selectedWorkshop = payloads;
    },
    setWorkshopTags: (state, payloads) => {
      state.workshopTags = payloads;
    },
  },
  actions: {
    async getListWorkshop({ commit }) {
      try {
        const { data } = await NetworkWorkshop.getListWorkshop();
        commit('setWorkshopItems', data.workshops);
      } catch (error) {
        commit('setWorkshopItems', []);
      }
    },
    async createWorkshop({ dispatch }, params) {
      const { data } = await NetworkWorkshop.createWorkshop(params);
      if (data.success) {
        dispatch('getListWorkshop');
      }
    },
    async updateWorkshop({ dispatch }, params) {
      const { data } = await NetworkWorkshop.updateWorkshop(params._id, params);
      if (data.success) {
        dispatch('getListWorkshop');
      }
    },
    async deleteWorkshop({ dispatch }, id) {
      const { data } = await NetworkWorkshop.deleteWorkshop(id);
      if (data.success) {
        dispatch('getListWorkshop');
      }
    },
    async sendReflexionWorkshop({ dispatch }, params) {
      const { data } = await NetworkWorkshop.sendReflexionWorkshop(params);
      if (data.success) {
        dispatch('getListWorkshop');
      }
    },
    async getWorkshopTags({ commit }) {
      const { data } = await NetworkWorkshop.getWorkshopTags();
      if (data.success) {
        commit('setWorkshopTags', data.workshopTags);
      }
    },
  },
};
