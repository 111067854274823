import NetworkAdmin from '@/helpers/networking/NetworkAdmin';

export default {
  state: {
    // UserAdministrationComponent variables
    allActiveUsers: [],
    allActiveUsersCount: 0,
    allCheckedUsers: [],
    filterTags: [],
    filterTeams: [],
    filterWorkshops: [],
    startDate: '',
    endDate: '',
    minLevel: '',
    maxLevel: '',
    usersPerPage: 10,
    currentPage: 1,

    // SuperUserProfileOverlayComponent variables
    superUserProfileInfo: null,
    superUserProfileTags: [],
    superUserProfileTasks: [],
    superUserProfileCompletedTasks: [],
    superUserProfileOmindWorkshops: [],

    // UserAdministrationFilterComponent variables
    filterDataTags: [],
    filterDataTeams: [],
    filterDataOmindWorkshops: [],
    availableSchemas: [],
  },
  getters: {
    getAllActiveUsers: (state) => state.allActiveUsers,
    getAllActiveUsersCount: (state) => state.allActiveUsersCount,
    getAllCheckedUsers: (state) => state.allCheckedUsers,
    getFilterTags: (state) => state.filterTags,
    getFilterTeams: (state) => state.filterTeams,
    getFilterWorkshops: (state) => state.filterWorkshops,
    getStartDate: (state) => state.startDate,
    getEndDate: (state) => state.endDate,
    getMinLevel: (state) => state.minLevel,
    getMaxLevel: (state) => state.maxLevel,

    getUsersPerPage: (state) => state.usersPerPage,
    getCurrentPage: (state) => state.currentPage,

    isCheckedUser: (state) => (userId) => state.allCheckedUsers.some((user) => user._id === userId),
    getCheckedUsersCount: (state) => state.allCheckedUsers.length,

    superUserProfileInfo: (state) => state.superUserProfileInfo,
    superUserProfileTags: (state) => state.superUserProfileTags,
    superUserProfileTasks: (state) => state.superUserProfileTasks,
    superUserProfileCompletedTasks: (state) => state.superUserProfileCompletedTasks,
    superUserProfileOmindWorkshops: (state) => state.superUserProfileOmindWorkshops,

    filterDataTags: (state) => state.filterDataTags,
    filterDataTeams: (state) => state.filterDataTeams,
    filterDataOmindWorkshops: (state) => state.filterDataOmindWorkshops,
    getAvailableSchemas: (state) => state.availableSchemas,
  },
  mutations: {
    setAllActiveUsers: (state, data) => {
      state.allActiveUsers = data;
    },
    setAllActiveUsersCount: (state, count) => {
      state.allActiveUsersCount = count;
    },
    setCheckedUsers: (state, users) => {
      state.allCheckedUsers = users;
    },
    addCheckedUser: (state, user) => {
      state.allCheckedUsers.push(user);
    },
    removeCheckedUser: (state, userId) => {
      state.allCheckedUsers = state.allCheckedUsers.filter((user) => user._id !== userId);
    },
    checkAllUsers: (state, users) => {
      state.allCheckedUsers = users.slice();
    },
    uncheckAllUsers: (state) => {
      state.allCheckedUsers = [];
    },
    updateFilters: (
      state,
      {
        tagIds, teamIds, workshopIds, startDate, endDate, minLevel, maxLevel,
      },
    ) => {
      state.filterTags = tagIds;
      state.filterTeams = teamIds;
      state.filterWorkshops = workshopIds;
      state.startDate = startDate;
      state.endDate = endDate;
      state.minLevel = minLevel;
      state.maxLevel = maxLevel;
    },
    updateUsersPerPage: (state, usersPerPage) => {
      state.usersPerPage = usersPerPage;
    },
    updateCurrentPage: (state, currentPage) => {
      state.currentPage = currentPage;
    },
    setSuperUserProfileInfo(state, {
      user, dataTags, openTasks, completedTasks, omindWorkshops,
    }) {
      state.superUserProfileInfo = user;
      state.superUserProfileTags = dataTags;
      state.superUserProfileTasks = openTasks;
      state.superUserProfileCompletedTasks = completedTasks;
      state.superUserProfileOmindWorkshops = omindWorkshops;
    },
    setFilterData(state, {
      tags, teams, omindWorkshops, schemas,
    }) {
      state.filterDataTags = tags;
      state.filterDataTeams = teams;
      state.filterDataOmindWorkshops = omindWorkshops;
      state.availableSchemas = schemas;
    },
  },
  actions: {
    async getAllUsers({ state, commit }) {
      const postData = {
        tagIds: state.filterTags.map((item) => item._id),
        teamIds: state.filterTeams.map((item) => item._id),
        workshopIds: state.filterWorkshops.map((item) => item._id),
        startDate: state.startDate,
        endDate: state.endDate,
        minLevel: state.minLevel,
        maxLevel: state.maxLevel,
        usersPerPage: state.usersPerPage,
        currentPage: state.currentPage,
      };

      const { data } = await NetworkAdmin.postFilteredUsers(postData);
      commit('setAllActiveUsersCount', data.filteredUsersCount);
      commit('setAllActiveUsers', data.data);
    },
    async deleteUser(_, data) {
      await NetworkAdmin.deleteUser(data);
    },
    addCheckedUser({ commit }, user) {
      commit('addCheckedUser', user);
    },
    removeCheckedUser({ commit }, userId) {
      commit('removeCheckedUser', userId);
    },
    checkAllUsers({ commit }, users) {
      commit('checkAllUsers', users);
    },
    uncheckAllUsers({ commit }) {
      commit('uncheckAllUsers');
    },
    updateFilters(
      { commit },
      {
        tagIds, teamIds, workshopIds, startDate, endDate, minLevel, maxLevel,
      },
    ) {
      commit('updateFilters', {
        tagIds,
        teamIds,
        workshopIds,
        startDate,
        endDate,
        minLevel,
        maxLevel,
      });
    },
    updateUsersPerPage({ commit }, usersPerPage) {
      commit('updateUsersPerPage', usersPerPage);
    },
    updateCurrentPage({ commit }, currentPage) {
      commit('updateCurrentPage', currentPage);
    },
    async fetchSuperUserProfileInfo({ commit }, { userId, lang }) {
      try {
        const { data } = await NetworkAdmin.getDetailedUserInfos(userId, lang);
        const returnData = data.data[0];
        commit('setSuperUserProfileInfo', returnData);
      } catch (error) {
        console.error('Error fetching super user profile info:', error);
      }
    },
    async fetchFilterData({ commit }) {
      try {
        const { data } = await NetworkAdmin.getFilterComponentData();
        commit('setFilterData', data.data);
      } catch (error) {
        console.error('Error fetching filter data:', error);
      }
    },
  },
};
