<template>
  <div class="single-popup" id="tpl_kssChamp_popup">
    <div class="close" id="btn_close">
      <i @click.prevent="deactivate()">
        <font-awesome-icon icon="fa-xmark"/>
      </i>
    </div>
    <div class="text">
      <div class="headline">
        <img class="lamp" src="@/assets/Award.png" alt="">
        <h2>
          <span id="txt_kssChampionNotificationHeader">
            {{ $t('KssChampNotificationComponent.congratulations') }}
          </span>
        </h2>
      </div>
      <div class="second_head">
        <h3 id="tpl_kss_champion_popup_h3">
          {{ $t('KssChampNotificationComponent.youRecievedKssChampAward') }}
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KssChampNotificationComponent',
  props: ['notification'],
  methods: {
    deactivate() {
      this.$store.dispatch('deactivatePopUp', this.notification._id);
    },
  },
};
</script>

<style scoped>
.single-popup {
  display: block;
  padding: 10px 16px;
  margin: 7px 0;
  max-width: 500px;
  border-radius: var(--border-radius);
  box-shadow: 5px 10px 8px var(--color-light);
  background-color: var(--color-dark);
}

.single-popup .text {
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  word-wrap: break-word;
}

.single-popup .text .headline {
  display: flex;
  align-items: center;
  word-wrap: break-word;
}

.single-popup .text .headline .lamp {
  width: 50px;
  height: 50px;
}

.single-popup .text .headline h2 {
  color: white;
  padding-left: 10px;
}

.single-popup .text .second_head {
  display: flex;
  word-wrap: break-word;
}

.single-popup .text .second_head h3 {
  word-wrap: break-word;
  color: white;
}

.single-popup .text .award_img {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
}

.single-popup .text .award_img img {
  width: 50px;
  height: 50px;
}

.single-popup .close {
  cursor: pointer;
  position: absolute;
  /* float: right; */
  right: 20px;
  margin-top: 5px;
  /* top: -2px; */
  /* margin-right: 5px; */
  /* right: 20px; */
  /* top: 20px; */
  color: white;
}

.single-popup .close:hover {
  color: var(--color-orange);
}

.single-popup .text .headline h3 {
  font-size: 20px;
  line-height: 22px;
  color: white;
}

.single-popup .text .footline {
  font-size: 12px;
  float: right;
  color: var(--color-disabled);
  padding-bottom: 10px;
}

summary {
  padding: 20px 0;
  color: var(--color-orange);
  cursor: pointer;
}

.link {
  color: var(--color-orange);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.1px;
  line-height: 19px;
  text-align: right;
  text-decoration: none;
}

.link:hover {
  transition: .5s;
  color: var(--color-fulvous);
}

.accordeon {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

/*Scrollbar*/
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
  background: #000000;
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #666666;
}

::-webkit-scrollbar-track:active {
  background: #333333;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

@media screen and (max-width: 430px) {
  .popup-container {
    width: 97%;
    text-align: center;
  }
}

@media screen and (min-width: 431px) and (max-width: 500px) {
  .single-popup {
    border-radius: 1rem;
  }

  .single-popup .text {
    padding: 10px;
    text-align: center;
  }

  .single-popup .text .headline h3 {
    font-size: 16px;
    line-height: 22px;
  }

  .single-popup .text .headline .close {
    margin-top: -14px;
    margin-right: -14px;
  }

  .single-popup .text .description {
    font-size: 14px;
  }

  .single-popup .text .footline {
    font-size: 12px;
    float: right;
    color: var(--color-disabled);
  }

  .accordeon {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    font-size: 12px;
  }

  .accordeon::-webkit-scrollbar {
    width: 2px;
    margin-left: 5px;
    background: var(--color-white);
    border-radius: 0 8px 8px 0;
  }

  .accordeon::-webkit-scrollbar-thumb {
    background: var(--color-disabled);
    border-radius: 0 8px 8px 0;
  }
}
</style>
