<template>
  <div class="popup-feedback">
    <h2>{{ $t('LevelUpOverlayNoteComponent.congratulation') }}</h2>
    <div class="star-container">
      <div class="star-wrapper">
        <font-awesome-icon icon="star" class="star-left"/>
        <span class="star-number">{{ overlay.content?.oldLevel ?? '' }}</span>
      </div>
      <font-awesome-icon icon="arrow-right" class="arrow"/>
      <div class="star-wrapper">
        <font-awesome-icon icon="star" class="star-right"/>
        <span class="star-number">{{ overlay.content?.newLevel ?? '' }}</span>
      </div>
    </div>
    <h3 v-html="$t('LevelUpOverlayNoteComponent.motivationalMessage')">
    </h3>
    <div class="overlay-grid">
      <button @click.prevent="deactivate">{{
          $t('LevelUpOverlayNoteComponent.gotIt')
        }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LevelUpOverlayNoteComponent',
  props: {
    overlay: {
      type: Object,
      default: null,
    },
  },
  methods: {
    deactivate() {
      this.$store.dispatch('deactivateOverlay', this.overlay._id);
    },
  },
};
</script>

<style scoped>

.popup-feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 14px;
  background-color: rgb(0, 0, 0);
  padding: 3em 2.5em 0.5em 3em;
  border-radius: 2em;
  line-height: 1.5em;
  max-width: 90vh;
}

.popup-feedback h2,
.popup-feedback h3 {
  color: var(--color-milk);
}

.star-container {
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.star-wrapper {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.star-left {
  font-size: 64px;
  color: rgba(223, 197, 153, 255);
}

.star-right {
  font-size: 96px;
  color: var(--color-orange);
}

.star-number {
  position: absolute;
  font-weight: bold;
  font-size: 32px;
  color: var(--color-milk);
}

.arrow {
  font-size: 64px;
}

.overlay-grid {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.overlay-grid button {
  text-decoration: none;
  color: #1B1B1B;
  background-color: #F49718;
  border: 1px solid rgb(0, 0, 0);
  padding: 0.5em 1.5em 0.5em 1.5em;
  border-radius: 2em;
  font-size: inherit;
  cursor: pointer;
  outline: none;
}

.overlay-grid button:hover {
  text-decoration: none;
  color: #1B1B1B;
  background-color: white;
  border: 1px solid #F49718;
  padding: 0.5em 1.5em 0.5em 1.5em;
  border-radius: 2em;
}
</style>
