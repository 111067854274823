<template>
  <div class="inner-footer">
    <div class="left">
    </div>
    <div class="right">
      <span>© Copyright 2024 | <a href="https://omindplatform.com/" class="link" target="_blank">OMIND platform GmbH</a></span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FooterComponent',
  methods: {
    openTerms() {
      if (this.$i18n.locale === 'de') {
        window.open('https://omindplatform-generic.azureedge.net/content/Datenschutzerkla%CC%88rung_de.pdf', '_blank');
      } else {
        window.open('https://omindplatform-generic.azureedge.net/content/PrivacyPolicy_en.pdf', '_blank');
      }
      window.open('https://omindplatform-generic.azureedge.net/content/2023_02_04_AGB_de.pdf', '_blank');
    },
  },
};
</script>

<style scoped>
.inner-footer {
  margin: 0 auto;
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.inner-footer > * {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 300px;
}

.left {
  padding: 1rem 0;
  text-align: left;
}

.left a {
  color: var(--color-white);
  font-weight: 400;
}

.left a:hover {
  color: var(--color-orange);
}

.left #btn_legal-notice {
  margin-right: 3rem;
}

.right {
  padding: 1rem 0;
  text-align: right;
}

.right a {
  color: var(--color-white);
  font-weight: 400;
}

.right a:hover {
  color: var(--color-orange);
}

@media screen and (max-width: 960px) {
  .left {
    height: 0;
    padding: 0;
  }

  .right span {
    font-size: .85rem;
  }

}

</style>
