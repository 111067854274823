<template>
  <!----------- TEMPLATE TEAMCARD --------------->
  <div class="single-team" id="div_teamcard">
    <div class="card" @click.prevent="toogleDetails"> <!-- sichtbarer Teil der Teamcard -->
      <div class="headline">
        <h2>{{ teamName }}</h2>
        <div class="edit">
          <i style="display:none">
            <font-awesome-icon icon="fa-copy"/>
          </i>
          <i v-if="checkPermissionForTeamAdministration">
            <font-awesome-icon :icon="eyeIconToDisplay" @click.stop="showAndHideTeam"/>
          </i>
          <i>
            <font-awesome-icon icon="fa-pen"/>
          </i>
          <i>
            <font-awesome-icon icon="fa-trash-can" @click.stop="deleteTeam"/>
          </i>
        </div>
      </div>
      <div class="status">
        <p class="text-muted" id="txt_teamMitglieder">
          {{ $t('SingleEditTeamComponent.members') + memberCount }}
        </p>
      </div>
      <!--      <div class="teamleads">-->
      <!--        <div class="teamlead-label">-->
      <!--          <i>-->
      <!--            <font-awesome-icon icon="fa-user"/>-->
      <!--          </i>-->
      <!--          <p class="labelname">Teamleadname</p>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div v-if="showContainer" class="toggle-container">
      <h2>{{ $t('SingleEditTeamComponent.editThisTeam') }}</h2>
      <vee-form :validation-schema="schema" @submit="save" v-slot="{ errors }">
        <div class="inputs">
          <div class="input-field">
            <vee-field v-model="teamName" type="text" class="form-control" name="subject"
                       id="txt_subject" :class="{inputError : !!errors.subject}"/>
            <label for="subject" class="form-control inputlabel">
              {{ $t('SingleEditTeamComponent.editTeamName') }}
            </label>
          </div>
          <error-message class="error-message" name="subject"></error-message>
        </div>
        <!----------------- ADD TEAMLEADS & MATES ----------------->
        <div class="switch-user">
          <div class="leftside">
            <div v-if="checkPermissionForTeamAdministration" class="up">
              <!--Defaulter display = none,
               als Ansicht der Teamleads -
              Für Superuser Rolle style.display = block
              -- greift auch auf den dazugehörigen Button zu -->
              <div class="user-container">
                <div class="team-member">
                  <h3>{{ $t('SingleEditTeamComponent.teamLeads') }}</h3>
                  <div class="search-pill-element">
                        <input v-model="searchLeads"
                       class="search-input" type="text"
                       id="lead-search-input" ref="searchInput"
                       :placeholder="$t('SingleEditTeamComponent.searchPlaceholders.leads')"/>
                    </div>
                  <ul class="list-items" id="tpl_leadContainer">
                    <user-component v-for="(member) in filterLeads" :key="member"
                                    :user="member" @click.prevent="selectLeader(member)"/>
                    <li v-if="!filterLeads.length" class="item no-user">
                    <span class="item-text">
                      <div class="name">
                        <div>{{ $t('SingleEditTeamComponent.noUsers') }}</div>
                      </div>
                    </span>
                    </li>
                  </ul>
                </div>
              </div>
              <button type="button" class="out" name="Remove Teamlead"
                      @click.prevent="moveTeamleadsToGeneralUsers">
                {{ $t('SingleEditTeamComponent.removeTeamlead') }}
              </button>
              <div class="space50px">
                <!-- Space height = 50px -->
              </div>
            </div>
            <div class="down">
              <div class="user-container">
                <div class="team-member">
                  <h3 class="member">Team members</h3>
                  <div class="search-pill-element">
                        <input v-model="searchMembers"
                       class="search-input" type="text"
                       id="member-search-input" ref="searchInput"
                       :placeholder="$t('SingleEditTeamComponent.searchPlaceholders.members')"/>
                    </div>
                  <ul class="list-items" id="tpl_userContainer">
                    <user-component v-for="(member) in filterMembers" :key="member"
                                    :user="member" @click.prevent="selectMember(member)"/>
                    <li v-if="!filterMembers.length" class="item no-user">
                    <span class="item-text">
                      <div class="name">
                        <div>{{ $t('SingleEditTeamComponent.noUsers') }}</div>
                      </div>
                    </span>
                    </li>
                  </ul>
                </div>
              </div>
              <button type="button" class="out tmember" name="Remove Team member"
                      @click.prevent="moveMembersToGeneralUsers">
                {{ $t('SingleEditTeamComponent.removeTeamMember') }}
              </button>
            </div>
          </div>
          <div class="switch-container">
            <div v-if="checkPermissionForTeamAdministration" class="switch leads">
              <!-- class="leads" ruft entsprechende Pfeile ab,
               für Superuser Rolle style.display = block -->
              <div class="add-lead">
                <i @click.prevent="moveGeneralUsersToTeamLeads">
                  <font-awesome-icon icon="fa-left-long"/>
                </i>
              </div>
              <div class="remove-lead">
                <i @click.prevent="moveTeamleadsToGeneralUsers">
                  <font-awesome-icon icon="fa-right-long"/>
                </i>
              </div>
            </div>
            <div class="space150px leads">
              <!-- Space height = 150px -->
            </div>
            <div class="switch">
              <div class="add-user">
                <i @click.prevent="moveGeneralUsersToMembers">
                  <font-awesome-icon icon="fa-left-long"/>
                </i>
              </div>
              <div class="remove-user">
                <i @click.prevent="moveMembersToGeneralUsers">
                  <font-awesome-icon icon="fa-right-long"/>
                </i>
              </div>
            </div>
          </div>
          <div class="rightside">
            <div class="user-container general">
              <div class="platform-user">
                <h3>{{ $t('SingleEditTeamComponent.generalPlatformUser') }}</h3>
                <div class="search-pill-element">
                        <input v-model="searchUser"
                       class="search-input" type="text"
                       id="user-search-input" ref="searchInput"
                       :placeholder="$t('SingleEditTeamComponent.searchPlaceholders.users')"/>
                    </div>
                <ul class="list-items" id="tpl_selectLeadContainer">
                  <user-component v-for="(user) in filterUsers" :key="user" :user="user"
                                  @click.prevent="selectUser(user)"/>
                  <li v-if="!filterUsers.length" class="item no-user">
                    <span class="item-text">
                      <div class="name">
                        <div>{{ $t('SingleEditTeamComponent.noUsers') }}</div>
                      </div>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="add-container">
              <button type="button" class="in" name="Add to Leads"
                      @click.prevent="moveGeneralUsersToTeamLeads">
                {{ $t('SingleEditTeamComponent.addToLeads') }}
              </button>
              <button type="button" class="in" name="Add to Members"
                      @click.prevent="moveGeneralUsersToMembers">
                {{ $t('SingleEditTeamComponent.addToMembers') }}
              </button>
            </div>
          </div>
        </div>
        <div class="bottom-options">
          <div v-if="checkPermissionForTeamAdministration" class="displayTeamspace">
            {{ $t('SingleEditTeamComponent.displayTeamspace') }}
            <label class="switch">
              <input type="checkbox" :checked=this.displayTeamspace
                @input="$emit('input', $event.target.checked)" @change.stop="onToggleTeamspace">
              <span class="slider round"></span>
            </label>
          </div>
          <div >
            <button type="submit" class="btn-save" name="save" id="btn_save"
              :disabled="isSubmitting">
              {{ getSaveButtonText }}
            </button>
          </div>
        </div>

      </vee-form>
    </div>
  </div>
  <pop-up-confirmation-component v-if="showPopup"
                                 :message="popupMessage"
                                 :confirmButtonText="deleteButtonText"
                                 :cancelButtonText="cancelButtonText"
                                 @cancel="onPopupCancel"
                                 @confirm="onPopupConfirm"/>
</template>

<script>
import UserComponent from '@/components/Home/TeamAdministration/UserComponent.vue';
import PopUpConfirmationComponent from '@/components/global/PopUpConfirmationComponent.vue';

export default {
  name: 'SingleEditTeamComponent',
  components: {
    UserComponent,
    PopUpConfirmationComponent,
  },
  props: ['allUsers', 'leadedTeam'],
  data() {
    return {
      showContainer: false,
      leaders: this.leadedTeam.leaders,
      members: this.leadedTeam.members,
      users: this.leadedTeam.generalUsers,
      teamName: this.leadedTeam.name,
      schema: {
        subject: 'required',
      },
      isSubmitting: false,
      showPopup: false,
      popupMessage: this.$t('SingleEditTeamComponent.popup.deleteMessage'),
      deleteButtonText: this.$t('SingleEditTeamComponent.popup.deleteButton'),
      cancelButtonText: this.$t('SingleEditTeamComponent.popup.cancelButton'),
      searchUser: '',
      searchLeads: '',
      searchMembers: '',
      displayTeamspace: (this.leadedTeam.displayTeamspace !== undefined
        ? this.leadedTeam.displayTeamspace : true),
    };
  },
  watch: {
    checked() {
      this.$emit('input', this.displayTeamspace);
    },
  },
  computed: {
    memberCount() {
      return this.members.length;
    },
    getSaveButtonText() {
      return this.leadedTeam._id
        ? this.$t('SingleEditTeamComponent.save') : this.$t('SingleEditTeamComponent.saveInit');
    },
    checkPermissionForTeamAdministration() {
      return this.$store.state.auth.user.permissionsFlags.admin
        || this.$store.state.auth.user.permissionsFlags.companySuperuser;
    },
    filterUsers() {
      return this.users.filter((el) => el.name.toLowerCase()
        .includes(this.searchUser.toLowerCase())
        && (
          !this.filterMembers.find((s) => s._id === el._id)
          || this.checkPermissionForTeamAdministration
        ))
        .sort((a, b) => ((a.name > b.name) ? 1 : -1));
    },
    filterMembers() {
      return this.members
        .filter((el) => el.name.toLowerCase()
          .includes(this.searchMembers.toLowerCase()))
        .map((el) => {
          const isDisabled = !!this.filterLeads.find((s) => s._id === el._id);
          return { ...el, isDisabled };
        })
        .sort((a, b) => {
          if (a.isDisabled || b.isDisabled) return 1;
          return a.name > b.name ? 1 : -1;
        });
    },
    filterLeads() {
      return this.leaders
        .filter((el) => el.name.toLowerCase()
          .includes(this.searchLeads.toLowerCase()))
        .sort((a, b) => ((a.name > b.name) ? 1 : -1));
    },
    eyeIconToDisplay() {
      return this.displayTeamspace ? 'fa-eye' : 'fa-eye-slash';
    },
  },
  methods: {
    toogleDetails() {
      this.showContainer = !this.showContainer;
    },
    selectUser(selUser) {
      const index = this.users.findIndex((ele) => ele._id === selUser._id);
      this.users[index].isChecked = !this.users[index].isChecked;
    },
    selectMember(selMember) {
      if (selMember.isDisabled) return;
      const index = this.members.findIndex((ele) => ele._id === selMember._id);
      this.members[index].isChecked = !this.members[index].isChecked;
    },
    selectLeader(selLead) {
      const index = this.leaders.findIndex((ele) => ele._id === selLead._id);
      this.leaders[index].isChecked = !this.leaders[index].isChecked;
    },
    moveTeamleadsToGeneralUsers() {
      const idsToRemove = [];
      this.leaders.forEach((e) => {
        if (e.isChecked) {
          idsToRemove.push(e._id);
          this.users.push({
            ...e,
            isChecked: false,
          });
        }
      });

      this.leaders = this.leaders.filter((e) => !idsToRemove.includes(e._id));
    },
    moveGeneralUsersToTeamLeads() {
      const idsToRemove = [];
      this.users.forEach((e) => {
        if (e.isChecked) {
          idsToRemove.push(e._id);
          this.leaders.push({
            ...e,
            isChecked: false,
          });
          this.members.push({
            ...e,
            isChecked: false,
          });
        }
      });

      this.users = this.users.filter((e) => !idsToRemove.includes(e._id));
      this.members = [...new Map(this.members.map((item) => [item._id, item])).values()];
    },
    moveMembersToGeneralUsers() {
      const idsToRemove = [];
      this.members.forEach((e) => {
        if (e.isChecked) {
          idsToRemove.push(e._id);
          this.users.push({
            ...e,
            isChecked: false,
          });
        }
      });

      this.members = this.members.filter((e) => !idsToRemove.includes(e._id));
      this.leaders = this.leaders.filter((e) => !idsToRemove.includes(e._id));
      this.users = [...new Map(this.users.map((item) => [item._id, item])).values()];
    },
    moveGeneralUsersToMembers() {
      const idsToRemove = [];
      this.users.forEach((e) => {
        if (e.isChecked) {
          idsToRemove.push(e._id);
          e.isChecked = false;
          this.members.push({
            ...e,
          });
        }
      });
      this.members = [...new Map(this.members.map((item) => [item._id, item])).values()];
    },
    async save() {
      try {
        this.isSubmitting = true;
        const leaders = this.leaders.map((e) => e._id);
        const members = this.members.map((e) => e._id);
        if (this.leadedTeam._id) {
          await this.$store.dispatch('updateTeam', {
            team: {
              _id: this.leadedTeam._id,
              class: 'team_element',
              type: 'team',
              name: this.teamName,
              leaders,
              members,
              displayTeamspace: this.displayTeamspace,
            },
          });
          this.$store.commit('setToastNotification', {
            id: Math.random(),
            message: this.$t('ToastComponent.teamManagement.teamUpdated'),
            type: 'success',
          });
        } else {
          await this.$store.dispatch('createTeam', {
            team: {
              name: this.teamName,
              leaders,
              members,
              displayTeamspace: this.displayTeamspace,
            },
          });

          this.$store.commit('setToastNotification', {
            id: Math.random(),
            message: this.$t('ToastComponent.teamManagement.teamCreated'),
            type: 'success',
          });
        }
        this.$store.commit('resetTeamManagement');
        this.isSubmitting = false;
        this.showContainer = false;
        await this.$store.dispatch('getAllUsers');
        await this.$store.dispatch('getLeadedTeams');
      } catch (error) {
        this.isSubmitting = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    deleteTeam() {
      this.showPopup = true;
    },
    showAndHideTeam() {
      this.displayTeamspace = !this.displayTeamspace;
      if (!this.showContainer) {
        this.save();
      }
    },
    onPopupCancel() {
      this.showPopup = false;
    },
    async onPopupConfirm() {
      const data = {
        teamID: this.leadedTeam._id,
      };
      try {
        this.showPopup = false;
        await this.$store.dispatch('deleteTeam', data);
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('SingleEditTeamComponent.toast.successfulDelete'),
          type: 'success',
        });
        this.$store.commit('resetTeamManagement');
        await this.$store.dispatch('getLeadedTeams');
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('SingleEditTeamComponent.toast.errorDelete'),
          type: 'error',
        });
      }
    },
    onToggleTeamspace() {
      this.displayTeamspace = !this.displayTeamspace;
      this.$emit('input', this.displayTeamspace);
    },
  },
};
</script>

<style scoped>

.text-muted {
  color: var(--color-disabled);
}

.input-field .inputError {
  border: 2px solid var(--color-error);
}

.single-team {
  width: 100%;
  margin-bottom: 20px;
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: var(--color-light);
}

.single-team:hover {
  box-shadow: 0 4px 20px 0 #aaa;
  cursor: pointer;
}

.single-team .card {
  display: block;
}

.single-team .card .headline {
  display: flex;
  justify-content: space-between;
}

.single-team .card .headline h2 {
  font-size: 23px;
}

.single-team .card .headline .edit i {
  padding-left: 10px;
  font-size: 1.3rem;
  color: var(--color-disabled);
}

.single-team .card .headline .edit i:hover {
  color: var(--color-orange);
}

.single-team .card .status {
  padding-bottom: 30px;
  padding-left: 6px;
}

.single-team .card .teamleads {
  /*display: none;*/
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  align-content: center;
  flex-wrap: wrap;
}

.single-team .card .teamleads .teamlead-label {
  display: flex;
  align-items: center;
  padding: 0px 12px;
  margin-top: 5px;
  margin-right: 10px;
  border-radius: 10px;
  background: var(--color-yellow-orange);
  color: var(--color-white);
  font-size: 13px;
  cursor: default;
}

.single-team .card .teamleads .teamlead-label i {
  padding-right: 10px;
}

/*------------------ BUTTONS -------------------*/
.btns {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.btn-create {
  float: right;
  padding: 5px;
  font-size: 20px;
  width: 15rem;
  height: 2.5rem;
  color: var(--color-black);
  border: none;
  border-radius: 50px;
  background-color: var(--color-bisque);
  cursor: pointer;
}

.btn-create:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

.btn-save {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  padding: 10px 35px;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

.btn-save:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

.out {
  display: none;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 15px;
  border: none;
  border-radius: 50px;
  font-size: 15px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

.out:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

.add-container {
  display: none;
  flex-direction: row;
}

.add-container .in {
  padding: 10px 15px;
  margin-right: 10px;
  border: none;
  border-radius: 50px;
  font-size: 15px;
  color: var(--color-white);
  background-color: var(--color-orange);
}

.add-container .in:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

/*------------ TOOGLE CONTAINER ---------------*/
.single-team .toggle-container {
  /*display: none; !*Mit Javascript bei Klick auf ".single-team" display = block setzten!*!*/
  transition: all 0.3s ease-in-out;
  padding: 20px 30px;
}

.single-team .toggle-container h2 {
  padding-top: 20px;
  font-size: 23px;
  text-align: center;
}

.single-team .toggle-container .switch-user {
  width: 100%;
  /*height: 550px;*/
  display: grid;
  grid-template-columns: 40% 20% 40%;
  /*grid-template-rows: 1fr 1fr;*/
  justify-items: center;
}

.single-team .toggle-container .switch-user .leftside {
  grid-row: 1/3;
  grid-column: 1/2;
}

/*Bezieht sich auf Liste der Teamleads und dem dazugehörigen Button*/
.single-team .toggle-container .switch-user .leftside .up {
  /*display: none;*/
}

/*Pfeile für Leadsliste*/
.switch-container .leads {
  /*display: none;*/
}

.space50px {
  height: 50px;
}

.space150px {
  height: 150px;
}

.single-team .toggle-container .switch-user .user-container.general {
  grid-row: 1/3;
  grid-column: 1/2;
  /*height: 452px;*/
}

.user-container.general .list-items {
  display: block;
  max-height: 200px;
  overflow-y: scroll;
}

.user-container {
  display: flex;
  box-sizing: border-box;
  border-radius: 0px 0px 20px 20px;
  /*height: 200px;*/
  cursor: pointer;
  padding-bottom: 8px;
}

.user-container h3 {
  text-align: center;
  padding: 5px 0 10px;
}

.user-container .member {
  padding-top: 10px;
}

.switch-container {
  display: block;
  text-align: center;
}

.switch-container .add-lead i,
.switch-container .remove-lead i {
  font-size: 3rem;
}

.switch-container .add-lead i:hover,
.switch-container .remove-lead i:hover {
  color: var(--color-orange);
}

.switch-container .add-user i,
.switch-container .remove-user i {
  font-size: 3rem;
}

.switch-container .add-user i:hover,
.switch-container .remove-user i:hover {
  color: var(--color-orange);
}

.user-container .list-items {
  display: block;
  max-height: 200px;
  overflow-y: scroll;
}

.user-container .list-items::-webkit-scrollbar {
  width: 5px;
  background: transparent;
  border-radius: 0 8px 8px 0;
}

.user-container .list-items::-webkit-scrollbar-thumb {
  background: var(--color-disabled);
  border-radius: 0 8px 8px 0;
}

.list-items {
  max-height: 0;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  margin: 0;
  border: 1px solid var(--color-light-grey);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: var(--color-milk);
  /*display: none;*/
  overflow: hidden;
  z-index: 10;
}

.list-items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  cursor: pointer;
  transition: 0.3s;
}

.item .item-text .name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list-items .item:hover {
  transform: scale(1.02);
}

.list-items .item .item-text {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.list-items .item .item-text img {
  width: 2rem;
  margin-right: 12px;
}

.list-items .item .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border: 1px solid var(--color-dark);
  border-radius: 50px;
  transition: all 0.2s ease-in-out;
}

.checkbox .check-icon {
  color: var(--color-orange);
  font-size: 24px;
  transform: scale(0);
  transition: all 0.2s ease-in-out;
}

/*Item auf "checked"-Klasse setzten, wenn angeklickt. Dann erscheint Häckchen.*/
.item.checked .check-icon {
  transform: scale(1);
}

.item.no-user {
  color: var(--color-disabled);
  cursor: default;
}

.item.no-user:hover {
  transform: scale(1);
}

/*------------------ INPUTFIELD -------------------*/
.inputs {
  margin-top: 1.5rem;
}

input[type=text] {
  box-sizing: border-box;
  border: 1px solid var(--color-light-grey);
  background: transparent;
  border-radius: 5px;
  padding-left: 10px;
  width: 100%;
  height: 3rem;
  margin-right: 50px;
  font-size: 15px;
}

.input-field {
  transition: all .3s;
  height: 3.5rem;
}

.inputs .inputlabel {
  font-size: 1rem;
  color: #aaa;
  display: inline-block;
  opacity: 1;
  transform: translateY(-2.3rem);
  transform-origin: 0 0;
  transition: all .3s;
  padding-left: var(--card-padding);
}

.form-control::placeholder {
  color: transparent;
}

.form-control {
  transition: all .5s;
}

.form-control:focus {
  border-color: var(--color-orange);
}

.form-control:focus + label,
.form-control:not(:placeholder-shown) + label {
  transform: translateY(-3rem) scale(.8); /*formatiert Label nach links oben und reduziert Größe*/
}

.input-field:focus-within {
  transform: scale(1.02, 1.02);
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 23px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: var(--color-white);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--color-orange);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--color-orange);
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.bottom-options {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.displayTeamspace {
    margin-right: 1.5rem;
    margin-top: 1rem;
}

@media screen and (min-width: 769px) {
  .user-container .list-items {
    width: 300px;
  }

  .item .item-text .name {
    display: block;
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (min-width: 521px) and (max-width: 768px) {

  .switch-container {
    margin: 0 5px;
    height: 30px;
  }

  .single-team .toggle-container .switch-user {
    display: block;
    width: 100%;
    height: fit-content;
  }

  .user-container .team-member,
  .platform-user {
    width: 100%;
  }

  .user-container h3 {
    text-align: center;
    padding: 5px 0px 10px;
    font-size: 15px;
    line-height: 20px;
  }

  .user-container .platform-user h3 {
    margin-top: 30px;
  }

  .switch-container {
    display: none;
  }

  .user-container .member {
    padding-top: 10px;
    margin-top: 30px;
  }

  .list-items {
    width: 100%;
  }

  .list-items .item .item-text img {
    width: 2.5rem;
    margin-right: 12px;
  }

  .list-items .item .checkbox {
    height: 18px;
    width: 18px;
    margin-left: 10px;
  }

  .checkbox .check-icon {
    font-size: 22px;
  }

  .out {
    display: block;
    margin-top: 5px;
  }

  .out.tmember {
    margin-top: 5px;
  }

  .add-container {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }

  .space50px {
    display: none;
  }

  .space150px {
    display: none;
  }

  .switch-container {
    display: none;
  }

  .bottom-options {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }

}

/*==================== MEDIA QUERIES =====================*/
@media screen and (max-width: 400px) {
  .breadcrumbs-container {
    margin-left: -1rem;
  }

  .single-team .card .headline .edit i {
    padding-left: 3px;
    font-size: 1.2rem;
    color: var(--color-disabled);
  }

  .single-team {
    width: 100%;
    justify-content: center;
    justify-items: center;
  }

  .single-team .toggle-container {
    padding: 20px 0px;
  }

  .single-team .toggle-container .switch-user {
    display: block;
    width: 100%;
    height: fit-content;
  }

  .user-container .team-member,
  .platform-user {
    width: 100%;
  }

  .user-container h3 {
    text-align: center;
    padding: 5px 0px 10px;
    font-size: 15px;
    line-height: 20px;
  }

  .user-container .platform-user h3 {
    margin-top: 30px;
  }

  .user-container.general .list-items {
    max-height: 300px;
  }

  .switch-container {
    display: none;
  }

  .user-container .member {
    padding-top: 10px;
    margin-top: 30px;
  }

  .list-items {
    width: 100%;
  }

  .list-items .item .item-text img {
    width: 2.5rem;
    margin-right: 12px;
  }

  .list-items .item .checkbox {
    height: 18px;
    width: 18px;
    margin-left: 10px;
  }

  .checkbox .check-icon {
    font-size: 22px;
  }

  .out {
    display: block;
    margin-top: 5px;
  }

  .out.tmember {
    margin-top: 5px;
  }

  .add-container {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }

  .space50px {
    display: none;
  }

  .space150px {
    display: none;
  }

  .bottom-options {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }
}

@media screen  and (min-width: 401px) and (max-width: 520px) {
  .breadcrumbs-container {
    margin-left: -1rem;
  }

  .single-team {
    width: 100%;
    justify-content: center;
    justify-items: center;
  }

  .single-team .toggle-container .switch-user {
    display: block;
    width: 100%;
    height: fit-content;
  }

  .user-container .team-member,
  .platform-user {
    width: 100%;
  }

  .user-container h3 {
    text-align: center;
    padding: 5px 0px 10px;
    font-size: 15px;
    line-height: 20px;
  }

  .user-container .platform-user h3 {
    margin-top: 30px;
  }

  .switch-container {
    display: none;
  }

  .user-container .member {
    padding-top: 10px;
    margin-top: 30px;
  }

  .list-items {
    width: 100%;
  }

  .list-items .item .item-text img {
    width: 2.5rem;
    margin-right: 12px;
  }

  .list-items .item .checkbox {
    height: 18px;
    width: 18px;
    margin-left: 10px;
  }

  .checkbox .check-icon {
    font-size: 22px;
  }

  .out {
    display: block;
    margin-top: 5px;
  }

  .out.tmember {
    margin-top: 5px;
  }

  .add-container {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    justify-items: center;
    justify-content: center;
  }

  .space50px {
    display: none;
  }

  .space150px {
    display: none;
  }

  .switch-container {
    display: none;
  }

  .bottom-options {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
  }
}

@media (min-width: 521px) and (max-width: 768px) {
  .breadcrumbs {
    margin: -1rem -2rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .breadcrumbs {
    margin: 0 -3rem;
  }
}

@media (min-width: 1920px) {
  .breadcrumbs {
    margin: 0 -10rem -1rem;
  }
}

</style>
