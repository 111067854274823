<template>
  <div class="single-popup" id="tpl_kssChamp_popup">
    <div class="close" id="btn_close">
      <i @click.prevent="deactivate()">
        <font-awesome-icon
          class="icon"
          icon="fa-xmark"
        />
      </i>
    </div>
    <div class="text">
      <div class="headline">
        <font-awesome-icon
          class="icon"
          v-if="notification.content.icon"
          :icon="notification.content.icon"
        ></font-awesome-icon>
        <h2 v-if="notification.content.header[lang]">
          <span id="txt_kssChampionNotificationHeader">
            {{ notification.content.header[lang] }}
          </span>
        </h2>
      </div>
      <div v-if="notification.content.text[lang]" class="second_head">
        <h3 id="tpl_kss_champion_popup_h3">
          {{ notification.content.text[lang] }}
        </h3>
      </div>
    </div>
    <div class="buttonAlign">
      <button
        v-if="notification.content.positiveButton[lang]"
        type="submit"
        class="button"
        name="regist"
        id="btn_regist"
        v-html="notification.content.positiveButton[lang]"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GenericTaskNotificationComponent',
  props: ['notification'],
  computed: {
    lang() {
      return this.$i18n.locale;
    },
  },
  methods: {
    deactivate() {
      this.$store.dispatch('deactivatePopUp', this.notification._id);
    },
  },
};
</script>

<style scoped>
a {
  color: white;
}

.buttonAlign {
  display: flex;
  justify-content: flex-end;
}

.button {
  display: block;
  margin-left: auto;
  margin-right: 20px;
  margin-top: 1rem;
  padding: 10px 35px;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

.single-popup {
  display: block;
  padding: 10px 16px;
  margin: 7px 0;
  max-width: 500px;
  border-radius: var(--border-radius);
  box-shadow: 5px 10px 8px var(--color-light);
  background-color: var(--color-dark);
}

.single-popup .text {
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  word-wrap: break-word;
}

.single-popup .text .headline {
  display: flex;
  align-items: center;
  word-wrap: break-word;
}

.single-popup .text .headline .icon {
  width: 50px;
  height: 50px;
}

.single-popup .text .headline h2 {
  color: white;
  padding-left: 10px;
}

.single-popup .text .second_head {
  display: flex;
  word-wrap: break-word;
}

.single-popup .text .second_head h3 {
  word-wrap: break-word;
  color: white;
}

.single-popup .text .headline h3 {
  font-size: 20px;
  line-height: 22px;
  color: white;
}

.single-popup .close {
  cursor: pointer;
  position: absolute;
  right: 20px;
  margin-top: 5px;
  color: white;
}

.single-popup .close:hover {
  color: var(--color-orange);
}

.single-popup .text .headline h3 {
  font-size: 20px;
  line-height: 22px;
  color: white;
}

.summary {
  padding: 20px 0;
  color: var(--color-orange);
  cursor: pointer;
}

@media screen and (max-width: 430px) {
  .popup-container {
    width: 97%;
    text-align: center;
  }
}

@media screen and (min-width: 431px) and (max-width: 500px) {
  .single-popup {
    border-radius: 1rem;
  }

  .single-popup .text {
    padding: 10px;
    text-align: center;
  }

  .single-popup .text .headline h3 {
    font-size: 16px;
    line-height: 22px;
  }

  .single-popup .text .headline .close {
    margin-top: -14px;
    margin-right: -14px;
  }

  .single-popup .text .description {
    font-size: 14px;
  }
}
</style>
