<template>
  <div class="pagination-container">
    <div class="pagination">
      <div class="middle-container">
        <p
          v-for="pageNumber in displayedPages"
          :key="pageNumber"
          :class="{ 'page-active': pageNumber === currentPage }"
          @click="goToPage(pageNumber)"
        >
          {{ pageNumber }}
        </p>

        <div class="previous-container" v-if="maxPage>3">
          <p :class="{ disabled: !showPrevious }" title="next page" @click="goToPage(1)">
            <font-awesome-icon icon="fa-solid fa-angles-left" />
          </p>
          <p
            :class="{ disabled: !showPrevious }"
            title="previous page"
            @click="goToPage(currentPage - 1)"
          >
            <font-awesome-icon icon="fa-solid fa-angle-left" />
          </p>
        </div>

        <div class="next-container" v-if="maxPage>3">
          <p :class="{ disabled: !showNext }" title="next page" @click="goToPage(currentPage + 1)">
            <font-awesome-icon icon="fa-solid fa-angle-right" />
          </p>
          <p :class="{ disabled: !showNext }" title="next page" @click="goToPage(this.maxPage)">
            <font-awesome-icon icon="fa-solid fa-angles-right" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaginationComponent',
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    itemsPerPage: {
      type: Number,
      default: 1,
    },
    itemsCount: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    maxPage() {
      return Math.ceil(this.itemsCount / this.itemsPerPage);
    },
    displayedPages() {
      const pages = [];

      let start = Math.max(1, this.currentPage - 1);
      if (this.currentPage === this.maxPage) {
        start = this.maxPage - 2;
      }
      const end = Math.min(this.maxPage, start + 2);

      for (let i = start; i <= end; i += 1) {
        if (i > 0) {
          pages.push(i);
        }
      }

      return pages;
    },
    showPrevious() {
      return this.currentPage !== 1;
    },
    showNext() {
      return this.currentPage !== this.maxPage;
    },
  },
  watch: {
    itemsPerPage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit('updateCurrentPage', 1);
      }
    },
  },
  methods: {
    goToPage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.maxPage) {
        this.$emit('updateCurrentPage', pageNumber);
      }
    },
  },
};
</script>

<style scoped>
.pagination-container {
  display: flex;
  justify-content: center;
}
.pagination {
  display: flex;
  justify-content: space-between;
  position: relative;
}

p,
span {
  background-color: var(--color-milk);
  color: #636363;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 17px;
  margin: 0 3px 5px;
  width: 40px;
  height: 40px;
  text-decoration: none;
  position: relative;
  font-family: Helvetica, Arial, sans-serif;
  cursor: pointer;
}

span {
  pointer-events: none;
}

p:hover {
  background-color: var(--color-orange);
  color: #fff;
}

p.page-active {
  background-color: var(--color-orange);
  color: #452f2f;
}

.previous-container,
.middle-container,
.next-container {
  display: flex;
}

.previous-container,
.next-container {
  position: absolute;
  top: 0;
}

.previous-container {
  left: -66%;
}

.next-container {
  right: -66%;
}

.middle-container {
  position: relative;
}

.disabled {
  background-color: lightgray;
}
.disabled:hover {
  background-color: lightgray;
}

@media screen and (max-width: 500px) {
  p,
  span {
    width: 30px;
    height: 30px;
  }
}
</style>
