<template>
  <div class="auth-view">
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'AuthView',
};
</script>
<style>
*, *::before, *::after {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  border: none;
}

.auth-view {
  font-family: "Poppins", sans-serif;
  color: var(--color-black);
  background-image: url(../assets/fffbackground-image-groesser.png);
  background-repeat: repeat;
  background-attachment: fixed;
  background-position: center;
  overflow-x: hidden;
  /*Form Code*/
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*------------ GENERAL ----------------*/
.auth-view .container {
  position: relative;
  max-width: 500px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  background: var(--color-light);
}

.auth-view .link {
  color: var(--color-orange);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.1px;
  line-height: 19px;
  text-align: right;
  text-decoration: none;
}
</style>
