<template>
  <div class="rounded-box">
    <div class="left-content">
      <div class="icon-container">
        <img class="icon" :src= "getURL" alt="image">
      </div>
    </div>
    <div class="right-content">
      <div class="weekgoal-title">{{ getTitle }}</div>
      <div class="progress-bar" :style="progress">
        <div class="tasks-count">{{ doneTasks }}/{{ totalTasks }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WeekgoalComponent',
  props: {
    id: {
      type: Number,
      required: true,
    },
    doneTasks: {
      type: Number,
      required: true,
    },
    totalTasks: {
      type: Number,
      required: true,
    },
    reached: {
      type: Boolean,
      required: true,
    },
    representationProperties: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  computed: {
    getTitle() {
      if (this.representationProperties?.critereaName
        && Object.prototype
          .hasOwnProperty.call(this.representationProperties?.critereaName, this.getLocale)) {
        return this.representationProperties?.critereaName[this.getLocale];
      }
      return this.$t(`WeekgoalComponent.${this.id}`);
    },
    getLocale() {
      return this.$i18n.locale;
    },
    progress() {
      const donePercent = (this.doneTasks / this.totalTasks) * 100;
      return `background: linear-gradient(to right, var(--color-orange) ${donePercent}%, var(--color-light-grey) ${donePercent}% 100%)`;
    },
    getURL() {
      let image = '';
      if (this.reached) {
        image = this.representationProperties?.imageURL_active;
      } else if (!this.reached) {
        image = this.representationProperties?.imageURL_inactive;
      } else {
        image = this.$t(`WeekgoalComponent.${this.id}`);
      }
      return image;
    },
  },
};
</script>

<style scoped>

.left-content {
  width: 100px;
  height: 100%;
}
.right-content {
  width: 70%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 10px;
  height: 80%;
  align-self: center;
}

.rounded-box {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 40px);
  max-width: 330px;
  min-width: 290px;
  height: 150px;
  border-radius: 10px;
  background-color: var(--color-milk);
  color: black;
  font-family: Arial, sans-serif;
  flex: 1;
  margin-right:15px;
}

.weekgoal-title {
  position: relative;
  font-size: 18px;
  font-weight: 400;
  margin-top: 0.5rem;
  height: 50%;
  font-family: "Poppins", sans-serif;
}

.tasks-count {
  display: flex;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
}

.icon-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
}

.icon {
  width: 95%;
  height: auto;
  z-index: 9;
}
.progress-bar{
  width: 100%;
  height: 2rem;
  border-radius: 50px;
  overflow: hidden;
  background: linear-gradient(to right, var(--color-orange) 0%, var(--color-light-grey) 0% 100%);
  text-align: center;
  align-self: flex-end;
  margin-top: 1rem;
}

@media (max-width: 1024px) {
  .rounded-box {
    max-width: 100% !important;
    height: 150px;
    margin: 10px 10px;
  }

  .rounded-box .weekgoal-title {
    font-size: 16px;
  }
}
@media (max-width: 521px) {
  .rounded-box {
    margin-left: 0;
    padding: 5px;
    width: 100%;
    min-width: 250px;
  }
}

@media (max-width: 768px) {
  .rounded-box {
    min-width: 100%;
    margin: 5px 0;
  }
}

@media (min-width: 521px) and (max-width: 1024px) {
  .rounded-box {
    margin-bottom: 10px;
    max-width: 100%;
  }

  .rounded-box .weekgoal-title {
    font-size: 16px;
  }
}

@media (min-width: 1025px) {
  .rounded-box {
    margin-bottom: 10px;
    max-width: 100%;
  }
}

</style>
