<template>
  <div class="main-mission-ontainer" id="mission">
    <div class="mission-container">
      <h1>{{ $t('MissionCompleteComponent.missionCompleted') }}</h1>
      <i>
        <font-awesome-icon icon="fa-circle-check"/>
      </i>
      <p>{{ $t('MissionCompleteComponent.submittedAnswer') }}</p>
      <button @click.prevent="navigateToResults" type="button" name="submit"
              id="btn_submit" class="btn-submit">
        {{ $t('MissionCompleteComponent.seeResults') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MissionCompleteComponent',
  computed: {
    getRedirect() {
      return this.$store.state.questionnaireManagement.assessment?.properties?.linkAfterAssessment;
    },
  },
  methods: {
    navigateToResults() {
      if (this.getRedirect) {
        this.$router.push(this.getRedirect);
      } else {
        this.$router.push('/');
      }
    },
  },
  unmounted() {
    this.$store.commit('setIsDone', true);
  },
};
</script>

<style scoped>
/*------------------ CONTAINER -------------------*/
.main-mission-ontainer {
  margin: 0 0;
}

.mission-container {
  display: block;
  position: relative;
  max-width: 500px;
  width: 100%;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 10px;
  background: var(--color-milk);
}

.mission-container h1 {
  margin: 5rem 0 2rem;
}

.mission-container .fa-circle-check {
  font-size: 7rem;
  color: var(--color-orange);
  margin: 3rem 0;
}

.back {
  cursor: pointer;
  text-align: left;
  margin: -1rem;
  font-weight: 600;
}

.back:hover {
  color: var(--color-fulvous);
}

.back i {
  padding: 0 .5rem;
}

/*------------------ BUTTONS ------------------*/
button[type=button] {
  padding: 10px 35px;
  margin: 5rem 0 1rem;
  font-size: 20px;
  width: 190px;
  height: 50px;
  color: var(--color-white);
  border: none;
  border-radius: 50px;
  background-color: var(--color-orange);
  cursor: pointer;
}

button[type=button]:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

/*==================== MEDIA QUERIES =====================*/
@media (max-width: 520px) {
  .mission-container {
    width: 90vw;
  }

  .mission-container h1 {
    margin: 3rem 0 1rem;
  }

  .mission-container .fa-circle-check {
    font-size: 7rem;
    color: var(--color-orange);
    margin: 1rem 0;
  }
}

@media (min-width: 521px) and (max-width: 768px) {
  .mission-container {
    width: 85vw;
  }
}
</style>
