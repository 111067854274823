import NetworkContent from '@/helpers/networking/NetworkContent';

const publicVapidKey = process.env.VUE_APP_PUBLICVAPIDKEY;

// Copied from the web-push documentation
const urlBase64ToUint8Array = (base64String) => {
  // eslint-disable-next-line no-mixed-operators
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};
async function checkServiceAvailable() {
  return ('serviceWorker' in navigator);
}

async function checkSubscription() {
  const serviceWorker = await navigator.serviceWorker.register('../push_serviceworker.js', {
    scope: '../',
  });

  await serviceWorker.update();

  let registrations = await navigator.serviceWorker.getRegistrations();

  if (registrations.length === 0) {
    await navigator.serviceWorker.register('../sw.js', {
      scope: '../',
    });
    registrations = await navigator.serviceWorker.getRegistrations();
  }

  // check old subscription
  let subscription = await registrations[0].pushManager.getSubscription();

  // if subscription is not there
  // make new subscription
  if (subscription == null) {
    try {
      subscription = await registrations[0].pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
      });
      await NetworkContent.subscribeToPush({ subscription });
    } catch (error) {
      console.log(error);
    }
  // if subscription is made with old key
  // delete old subscription and make new one
  } else if (urlBase64ToUint8Array(publicVapidKey).toString()
  !== new Uint8Array(subscription.options.applicationServerKey).toString()) {
    try {
      console.log(registrations[0]);
      console.log(await subscription.unsubscribe());
      // console.log('unsubscribe', await registrations[0].pushManager.unsubscribe({}));
      subscription = await registrations[0].pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
      });
      await NetworkContent.subscribeToPush({ subscription });
    } catch (error) {
      console.log(error);
    }
  }

  return subscription;
}

export { checkServiceAvailable, checkSubscription };
