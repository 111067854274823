<template>
  <div class="container" v-if="init">
    <div class="forms">
      <div class="pass">
        <router-link to="/auth" class="link">
          <i>
            <font-awesome-icon icon="fa-arrow-left"/>
          </i>
          {{ $t('ActivateAccountComponent.backToLogin') }}
        </router-link>
      </div>
      <div class="content">
        <img class="platform-logo" src="@/assets/logo_transparent.png" alt="txt">
        <h1>{{ getMessage }}</h1>
        <i>
          <font-awesome-icon v-if="!hasError" icon="fa-circle-check"/>
          <font-awesome-icon v-else icon="fa-ban" class="error-color"/>
        </i>
        <router-link to="/auth" class="button"
                name="verify" id="btn_verify">
          {{ $t('ActivateAccountComponent.goToLogin') }}
        </router-link >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['email', 'validationKey'],
  name: 'ActivateAccountComponent',
  data() {
    return {
      init: false,
      hasError: false,
      message: null,
    };
  },
  computed: {
    getMessage() {
      return this.message;
    },
  },
  methods: {
    async activateUser() {
      try {
        if (!(this.email && this.validationKey)) {
          this.message = this.$t('ActivateAccountComponent.invalidUrl');
          this.hasError = true;
          this.init = true;
          return;
        }
        await this.$store.dispatch('activateAccount', {
          email: this.email,
          validationKey: this.validationKey,
        });
        this.message = this.$t('ActivateAccountComponent.accountActivated');
        this.init = true;
      } catch (error) {
        this.hasError = true;
        this.message = this.$t('ActivateAccountComponent.accountNotActivated');
        this.init = true;
      }
    },
  },
  mounted() {
    this.activateUser();
  },
};
</script>

<style scoped>
/*------------ GENERAL ----------------*/

h1 {
  color: var(--color-dark);
  font-family: "Poppins";
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 27px;
  text-align: center;
  padding: 0 0 20px 0;
}

p {
  text-align: center;
  margin: 2rem 0;
  padding: 0 10px;
}

.link:hover {
  transition: .5s;
  color: var(--color-fulvous);
}

/*------------ FORM ----------------*/
.container .forms {
  padding: 30px;
}

.platform-logo {
  display: block;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  width: 10rem;
  height: auto;
}

.forms .input-field {
  position: relative;
  width: 100%;
  height: 3rem;
  margin-top: 1rem;
  border-radius: 5px;
  border: 1px solid #E7E7E7;

}

.input-field input {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  font-size: 16px;
}

.pass {
  float: left;
  cursor: pointer;
  color: var(--color-orange);
  font-weight: 600;
  padding: 15px 10px;
}

.pass:hover {
  color: var(--color-fulvous);

}

.pass i {
  padding: 0 .5rem;
}

.content {
  text-align: center;
}

.content i {
  font-size: 8rem;
  color: var(--color-orange);
}

.error-color {
  color: var(--color-error);
}

.pass {
  float: left;
  cursor: pointer;
  color: var(--color-orange);
  font-weight: 600;
  padding: 15px 10px;
}

.pass:hover {
  color: var(--color-fulvous);

}

.pass i {
  padding: 0 .5rem;
}

/*------------------ BUTTONS ------------------*/
.button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  padding: 10px 35px;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

button[type=button]:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

/*==================== MEDIA QUERIES =====================*/
@media screen and (max-width: 500px) {
  .container {
    height: 100%;
    width: 100%;
    border-radius: 0;
  }
}

</style>
