import axios from 'axios';

export default class NetworkAssessment {
  static getAssessment(data, config) {
    return axios.post('assessment/getAssessment', { id: data }, config);
  }

  static updateAssessment(data, config) {
    return axios.post('assessment/updateAssessment', data, config);
  }

  static submitAssessment(data, config) {
    return axios.post('assessment/submitAssessment', data, config);
  }

  static cancelAssessment(data, config) {
    return axios.post('assessment/cancelAssessment', data, config);
  }
}
