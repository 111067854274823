<template>
  <div class="single-popup" id="tpl_reccommendation_popup">
    <div class="close" id="btn_close">
      <i @click.prevent="deactivate()">
        <font-awesome-icon icon="fa-xmark"/>
      </i>
    </div>
    <div class="text">
      <div class="headline">
        <img class="lamp" src="@/assets/lamp.png" alt="">
        <h2>
          <span id="txt_developmentTipHeader">
            {{ $t('RecommendationNotificationComponent.devTip') }}
          </span>
        </h2>
      </div>
      <div class="second_head">
        <div v-if="notification">
          <span v-html="notification.content?.text[lang] ?? '-'"></span>
        </div>
      </div>
    </div>
    <div class="buttons-wrapper">
      <div class="buttons-container">
        <button class="ok-button" @click.prevent="markSeen">
          {{ $t('RecommendationNotificationComponent.okay') }}
        </button>
        <button class="more-tips-btn" @click.prevent="setFeedbackOverlay">
          {{ $t('RecommendationNotificationComponent.giveFeedback') }} >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['notification'],
  name: 'RecommendationNotificationComponent',
  methods: {
    async deactivate() {
      await this.$store.dispatch('deactivatePopUp', this.notification._id);
    },
    async markSeen() {
      await this.$store.dispatch('setNoticeDevTipp', { id: this.notification.content?.id });
      await this.$store.dispatch('deactivatePopUp', this.notification._id);
    },
    async setFeedbackOverlay() {
      this.$store.commit('addFrontendOverlay', {
        id: Math.random(),
        type: 'feedback',
        notification: this.notification,
      });
      await this.deactivate();
    },
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style scoped>

.single-popup {
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  margin: 7px 0;
  max-width: 500px;
  border-radius: var(--border-radius);
  box-shadow: 5px 10px 8px var(--color-light);
  background-color: var(--color-dark);
}

.single-popup .text {
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  word-wrap: break-word;
}

.single-popup .text .headline {
  display: flex;
  align-items: center;
  word-wrap: break-word;
}

.single-popup .text .headline .lamp {
  width: 50px;
  height: 50px;
}

.single-popup .text .headline h2 {
  color: white;
  padding-left: 10px;
}

.single-popup .text .second_head {
  display: flex;
  word-wrap: break-word;
}

.single-popup .text .second_head h3 {
  word-wrap: break-word;
  color: white;
}

.single-popup .close {
  cursor: pointer;
  position: absolute;
  right: 20px;
  margin-top: 5px;
  color: white;
}

.single-popup .close:hover {
  color: var(--color-orange);
}

.single-popup .text .headline h3 {
  font-size: 20px;
  line-height: 22px;
  color: green;
}

.single-popup .text .footline {
  font-size: 12px;
  float: right;
  color: var(--color-disabled);
  padding-bottom: 10px;
}

summary {
  padding: 20px 0;
  color: var(--color-orange);
  cursor: pointer;
}

.link {
  color: var(--color-orange);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.1px;
  line-height: 19px;
  text-align: right;
  text-decoration: none;
}

.link:hover {
  transition: .5s;
  color: var(--color-fulvous);
}

.accordeon {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

/*Scrollbar*/
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}

::-webkit-scrollbar-thumb:active {
  background: #000000;
}

::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #666666;
}

::-webkit-scrollbar-track:active {
  background: #333333;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.ok-button {
  background-color: var(--color-orange);
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 50px;
  cursor: pointer;
  padding: 10px 35px;
  font-size: 20px;
  color: var(--color-white);
}

.more-tips-btn {
  background-color: transparent;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 30px;
  cursor: pointer;
}

.more-tips-btn:hover {
  color: var(--color-orange);
}

@media screen and  (max-width: 521px) {
  .single-popup {
    border-radius: 1rem;
  }

  .single-popup .text {
    padding: 10px;
  }

  .single-popup .text .headline h3 {
    font-size: 16px;
    line-height: 22px;
  }

  .single-popup .text .headline .close {
    margin-top: -14px;
    margin-right: -14px;
  }

  .single-popup .text .description {
    font-size: 14px;
  }

  .single-popup .text .footline {
    font-size: 12px;
    float: right;
    color: var(--color-disabled);
  }

  .accordeon {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    font-size: 12px;
  }

  .accordeon::-webkit-scrollbar {
    width: 2px;
    margin-left: 5px;
    background: var(--color-white);
    border-radius: 0 8px 8px 0;
  }

  .accordeon::-webkit-scrollbar-thumb {
    background: var(--color-disabled);
    border-radius: 0 8px 8px 0;
  }

  .single-popup .text .headline h2 {
    font-size: 23px;
  }

  .single-popup .buttons-wrapper .buttons-container {
    padding: 10px;
  }

  .single-popup .buttons-wrapper .buttons-container .ok-button {
    padding: 5px 30px;
    font-size: 18px;
  }
}

</style>
