<template>
  <div v-if="text" class="alert-box">
    <div class="alert-icon">
      <!-- SVG for triangle alert icon -->
      <font-awesome-icon :icon="['fas', 'triangle-exclamation']" />
    </div>
    <div class="alert-content">
      <strong>{{ $t('mngDashboardComponent.attention') }}</strong><br>
      {{text}}
    </div>
  </div>
</template>

<script>

export default {
  name: 'AlertBoxComponent',
  props: {
    text: String,
  },
  data() {
    return {
    };
  },
};
</script>
<style scoped>
.alert-box {
  z-index: 9;
  right: 150px;
  top: 70px;
  position: absolute;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 660px) {
.alert-box {
  right: 0;
  top: 100px;
}
}

.alert-icon {
}

.alert-icon svg {
  color: #ff948c; /* Dark red color for the icon */
  width: 90px;
  height: 90px;
}

.alert-content strong {
  font-weight: bold;
  color: white;
}

.alert-content {
  background-color: #ff948c; /* Light red background */
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #f5c6cb;
  margin: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 250px;
  font-size: 13px;
  font-weight: 300;
  color: white; /* Text color */
}
</style>
