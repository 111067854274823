<template>
  <div class="no-personal-goals-container">
    <div class="message-container">
      <h1>{{$t("PersonalGoalsComponent.noGoalsHeading")}}</h1>
      <h2>{{$t("PersonalGoalsComponent.noGoalsText")}}</h2>
      <button @click.prevent="openCreateChoicePopup" type="button" id="newGoalsButton">
        {{$t("PersonalGoalsComponent.newGoalButton")}}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NoPersonalGoalsComponent',
  data() {
    return {
    };
  },
  // TODO
  // Need to add logic to new goal button
  computed: {
  },
  methods: {
    async openCreateChoicePopup() {
      window.scrollTo(0, 0);
      await this.$store.commit('setSelectedCreateTab', 'TaskCreatorOverlayComponent.personalGoals');
      this.$store.commit('addFrontendOverlay', {
        id: Math.random(),
        type: 'taskCreator',
      });
    },
  },
};
</script>
<style scoped>
  .no-personal-goals-container {
    font-family: "Poppins", sans-serif;
    background: var(--color-light);
    border-radius: var(--card-border-radius);
    padding: var(--card-padding);
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 350px;
  }
  .message-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 75%;
    text-align: center;
  }
  .no-personal-goals-container h1 {
    font-size: 23px;
    margin-bottom: 10px;
    font-weight: 500;
    white-space: nowrap;
  }
  .no-personal-goals-container h2 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  #newGoalsButton {
    cursor: pointer;
    background-color: var(--color-orange);
    color: var(--color-white);
    padding: 10px 30px;
    border-radius: 100vw;
    font-size: 16px;
  }

  @media screen and (max-width: 521px) {
    .no-personal-goals-container h1 {
      font-size: 18px;
    }
    .no-personal-goals-container h2 {
      font-size: 16px;
    }
    #newGoalsButton {
      font-size: 14px;
      padding: 8px 26px;
    }
  }
  @media screen and (max-width: 400px) {
    .no-personal-goals-container {
      padding: 10px;
    }
    .no-personal-goals-container h1 {
      font-size: 15px;
      white-space: normal;
    }
    .no-personal-goals-container h2 {
      font-size: 14px;
    }
    #newGoalsButton {
      font-size: 14px;
      padding: 8px 26px;
    }
  }
</style>
