<template>
  <div class="dropdown-container">
    <div class="filter-component">
      <div style="position: relative">
        <font-awesome-icon class="filter-icon" icon="fa-solid fa-filter" />
        <div v-if="filterStatus" class="filter-indicator-on-icon"></div>
      </div>
      <p>{{ $t("UserAdministrationComponent.options.filter") }}</p>
    </div>
    <div class="filter-options-container">
      <div class="filter-options">
        <div class="filter-name">
          <div class="filter-title-container">
            <p>{{ $t("UserAdministrationComponent.options.filter") }}</p>
            <p class="pill-element-filter-count" v-if="filterCount">{{ filterCount }}</p>
          </div>
          <p @click.prevent="deleteFilters" class="delete-button">
            {{ $t("UserAdministrationComponent.filterOptions.deleteAll") }}
          </p>
        </div>
        <div class="filter-option">
          <div class="filter-title">
            <div class="filter-title-container">
              <p>
                {{ $t("UserAdministrationComponent.filterOptions.tags") }}
              </p>
              <div v-if="checkedTags.length !== 0" class="filter-indicator"></div>
            </div>
            <font-awesome-icon
              icon="fa-chevron-up"
              v-if="isOpenTags"
              @click.prevent="isOpenTags = !isOpenTags"
            />
            <font-awesome-icon
              icon="fa-chevron-down"
              v-else
              @click.prevent="isOpenTags = !isOpenTags"
            />
          </div>
          <div class="filter-picks padding0" v-if="isOpenTags">
            <div v-for="identifier in tagIdentifiers" :key="identifier">
              <div class="filter-title">
                <p>{{ identifier.name }}</p>
                <font-awesome-icon
                  icon="fa-chevron-up"
                  v-if="identifier.open"
                  @click.prevent="identifier.open = !identifier.open"
                />
                <font-awesome-icon
                  icon="fa-chevron-down"
                  v-else
                  @click.prevent="identifier.open = !identifier.open"
                />
              </div>
              <div v-if="identifier.open" class="tag-container">
                <div
                  v-for="tag in getFilterDataTags.filter(
                    (tag) =>
                      tag.identifier.en === identifier.name || tag.identifier.de === identifier.name
                  )"
                  :key="tag"
                  class="filter-picks-element"
                >
                  <div class="checkbox-icon-wrapper" @click.prevent="handleCheckboxChangeTag(tag)">
                    <font-awesome-icon
                      v-if="!isCheckedTag(tag)"
                      class="unchecked-icon"
                      icon="fa-regular fa-square"
                    />
                    <font-awesome-icon
                      v-if="isCheckedTag(tag)"
                      class="checked-icon"
                      icon="fa-square-check"
                    />
                  </div>

                  <p>{{ tag.tag[lang] }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-option">
          <div class="filter-title">
            <div class="filter-title-container">
              <p>
                {{ $t("UserAdministrationComponent.filterOptions.teams") }}
              </p>
              <div v-if="checkedTeams.length !== 0" class="filter-indicator"></div>
            </div>

            <font-awesome-icon
              icon="fa-chevron-up"
              v-if="isOpenTeams"
              @click.prevent="isOpenTeams = !isOpenTeams"
            />
            <font-awesome-icon
              icon="fa-chevron-down"
              v-else
              @click.prevent="isOpenTeams = !isOpenTeams"
            />
          </div>

          <div class="filter-picks" v-if="isOpenTeams">
            <div v-for="team in getFilterDataTeams" :key="team" class="filter-picks-element">
              <div class="checkbox-icon-wrapper" @click.prevent="handleCheckboxChangeTeam(team)">
                <font-awesome-icon
                  v-if="!isCheckedTeam(team)"
                  class="unchecked-icon"
                  icon="fa-regular fa-square"
                />
                <font-awesome-icon
                  v-if="isCheckedTeam(team)"
                  class="checked-icon"
                  icon="fa-square-check"
                />
              </div>
              {{ team.name }}
            </div>
          </div>
        </div>

        <div class="filter-option">
          <div class="filter-title">
            <div class="filter-title-container">
              <p>
                {{ $t("UserAdministrationComponent.filterOptions.registrationPeriod") }}
              </p>
              <div v-if="startDate !== '' || endDate !== ''" class="filter-indicator"></div>
            </div>

            <div class="pill-element" v-if="startDate !== '' || endDate !== ''">
              <p class="padding0" v-if="startDate !== '' && endDate !== ''">
                {{ formatDatePickerDate(startDate) }} - {{ formatDatePickerDate(endDate) }}
              </p>
              <p class="padding0" v-else-if="startDate !== ''">
                {{ $t("UserAdministrationComponent.filterOptions.from") }}
                {{ formatDatePickerDate(startDate) }}
              </p>
              <p class="padding0" v-else-if="endDate !== ''">
                {{ $t("UserAdministrationComponent.filterOptions.before") }}
                {{ formatDatePickerDate(endDate) }}
              </p>
              <button class="close-btn" @click.stop="clearMemberSinceDates">
                <font-awesome-icon icon="fa-xmark" />
              </button>
            </div>
            <font-awesome-icon
              icon="fa-chevron-up"
              v-if="isOpenRegistrationPeriod"
              @click.prevent="isOpenRegistrationPeriod = !isOpenRegistrationPeriod"
            />
            <font-awesome-icon
              icon="fa-chevron-down"
              v-else
              @click.prevent="isOpenRegistrationPeriod = !isOpenRegistrationPeriod"
            />
          </div>

          <div class="filter-picks padding0" v-if="isOpenRegistrationPeriod">
            <div class="datePicker">
              <input type="date" v-model="startDate" />
              -
              <input type="date" v-model="endDate" />
            </div>
          </div>
        </div>

        <div class="filter-option">
          <div class="filter-title">
            <div class="filter-title-container">
              <p>Level</p>
              <div v-if="minLevel !== '' || maxLevel !== ''" class="filter-indicator"></div>
            </div>

            <div
              class="pill-element"
              v-if="minLevel !== '' || maxLevel !== ''"
              style="padding: 0px 10px"
            >
              <p class="padding0" v-if="minLevel !== '' && maxLevel !== ''">
                {{ minLevel }} - {{ maxLevel }}
              </p>
              <p class="padding0" v-else-if="minLevel !== ''">
                >
                {{ minLevel }}
              </p>
              <p class="padding0" v-else-if="maxLevel !== ''">
                &lt;
                {{ maxLevel }}
              </p>
              <button class="close-btn" @click.stop="clearLevel">
                <font-awesome-icon icon="fa-xmark" />
              </button>
            </div>
            <font-awesome-icon
              icon="fa-chevron-up"
              v-if="isOpenLevel"
              @click.prevent="isOpenLevel = !isOpenLevel"
            />
            <font-awesome-icon
              icon="fa-chevron-down"
              v-else
              @click.prevent="isOpenLevel = !isOpenLevel"
            />
          </div>

          <div class="filter-picks padding0" v-if="isOpenLevel">
            <div class="datePicker">
              <input v-model="minLevel" />
              -
              <input v-model="maxLevel" />
            </div>
          </div>
        </div>

        <div class="filter-option" v-if="getFilterDataOmindWorkshops.length">
          <div class="filter-title">
            <div class="filter-title-container">
              <p>
                {{ $t("UserAdministrationComponent.filterOptions.workshopParticipation") }}
              </p>
              <div v-if="checkedWorkshops.length !== 0" class="filter-indicator"></div>
            </div>

            <font-awesome-icon
              icon="fa-chevron-up"
              v-if="isOpenWorkshop"
              @click.prevent="isOpenWorkshop = !isOpenWorkshop"
            />
            <font-awesome-icon
              icon="fa-chevron-down"
              v-else
              @click.prevent="isOpenWorkshop = !isOpenWorkshop"
            />
          </div>

          <div class="filter-picks" v-if="isOpenWorkshop">
            <div
              v-for="workshop in getFilterDataOmindWorkshops"
              :key="workshop"
              class="filter-picks-element"
            >
              <div
                v-if="workshop !== ''"
                class="checkbox-icon-wrapper"
                @click.prevent="handleCheckboxChangeWorkshop(workshop)"
              >
                <font-awesome-icon
                  v-if="!isCheckedWorkshop(workshop)"
                  class="unchecked-icon"
                  icon="fa-regular fa-square"
                />
                <font-awesome-icon
                  v-if="isCheckedWorkshop(workshop)"
                  class="checked-icon"
                  icon="fa-square-check"
                />
              </div>
              {{ workshop.name }} - {{ formatDate(workshop.date_workshop) }}
            </div>
          </div>
        </div>

        <div class="filter-button">
          <button @click.prevent="updateFilters" class="button">
            {{ $t("UserAdministrationComponent.filterOptions.filter") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserAdministrationFilterComponent',
  components: {},
  emits: ['changeUserTableLoadingState'],
  data() {
    return {
      isOpenTags: false,
      isOpenTeams: false,
      isOpenTask: false,
      isOpenRegistrationPeriod: false,
      isOpenLevel: false,
      isOpenWorkshop: false,
      checkedTags: [],
      checkedTeams: [],
      checkedWorkshops: [],
      tagIdentifiers: [],
      startDate: '',
      endDate: '',
      minLevel: '',
      maxLevel: '',
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    getFilterTags() {
      return this.$store.getters.getFilterTags;
    },
    getFilterTeams() {
      return this.$store.getters.getFilterTeams;
    },
    getFilterWorkshops() {
      return this.$store.getters.getFilterWorkshops;
    },
    getStartDate() {
      return this.$store.getters.getStartDate;
    },
    getEndDate() {
      return this.$store.getters.getEndDate;
    },
    getMinLevel() {
      return this.$store.getters.getMinLevel;
    },
    getMaxLevel() {
      return this.$store.getters.getMaxLevel;
    },
    filterStatus() {
      let filterStatus = this.getFilterTags.length
      + this.getFilterTeams.length
      + this.getFilterWorkshops.length;
      if (this.getStartDate) {
        filterStatus += 1;
      }
      if (this.getEndDate) {
        filterStatus += 1;
      }
      if (this.getMinLevel) {
        filterStatus += 1;
      }
      if (this.getMaxLevel) {
        filterStatus += 1;
      }

      return filterStatus;
    },
    filterCount() {
      let filterCount = this.checkedTags.length
                      + this.checkedTeams.length
                      + this.checkedWorkshops.length;
      if (this.startDate) {
        filterCount += 1;
      }
      if (this.endDate) {
        filterCount += 1;
      }
      if (this.minLevel) {
        filterCount += 1;
      }
      if (this.maxLevel) {
        filterCount += 1;
      }
      return filterCount;
    },
    getFilterDataTags() {
      return this.$store.getters.filterDataTags;
    },
    getFilterDataTeams() {
      return this.$store.getters.filterDataTeams;
    },
    getFilterDataOmindWorkshops() {
      return this.$store.getters.filterDataOmindWorkshops;
    },
  },
  async mounted() {
    await this.$store.dispatch('fetchFilterData');

    await this.parseTagIdentifiers();

    this.checkedTags = this.getFilterTags.slice();
    this.checkedTeams = this.getFilterTeams.slice();
    this.checkedWorkshops = this.getFilterWorkshops.slice();
    this.startDate = this.getStartDate;
    this.endDate = this.getEndDate;
  },
  methods: {
    isCheckedTag(tag) {
      return this.checkedTags.some((t) => t._id === tag._id);
    },
    handleCheckboxChangeTag(tag) {
      const index = this.checkedTags.findIndex((t) => t._id === tag._id);
      if (index > -1) {
        this.checkedTags.splice(index, 1);
      } else {
        this.checkedTags.push(tag);
      }
    },
    isCheckedTeam(team) {
      return this.checkedTeams.some((t) => t._id === team._id);
    },
    handleCheckboxChangeTeam(team) {
      const index = this.checkedTeams.findIndex((t) => t._id === team._id);
      if (index > -1) {
        this.checkedTeams.splice(index, 1);
      } else {
        this.checkedTeams.push(team);
      }
    },
    isCheckedWorkshop(workshop) {
      return this.checkedWorkshops.some((t) => t._id === workshop._id);
    },
    handleCheckboxChangeWorkshop(workshop) {
      const index = this.checkedWorkshops.findIndex((t) => t._id === workshop._id);
      if (index > -1) {
        this.checkedWorkshops.splice(index, 1);
      } else {
        this.checkedWorkshops.push(workshop);
      }
    },
    async updateFilters() {
      this.$emit('changeUserTableLoadingState', true);

      await this.$store.dispatch('updateFilters', {
        tagIds: this.checkedTags,
        teamIds: this.checkedTeams,
        workshopIds: this.checkedWorkshops,
        startDate: this.startDate,
        endDate: this.endDate,
        minLevel: this.minLevel,
        maxLevel: this.maxLevel,
      });
      await this.$store.dispatch('getAllUsers');
      this.$emit('changeUserTableLoadingState', false);

      this.checkedTags = this.getFilterTags.slice();
      this.checkedTeams = this.getFilterTeams.slice();
      this.checkedWorkshops = this.getFilterWorkshops.slice();
    },
    deleteFilters() {
      this.checkedTags = [];
      this.checkedTeams = [];
      this.checkedWorkshops = [];
      this.startDate = '';
      this.endDate = '';
      this.minLevel = '';
      this.maxLevel = '';

      this.updateFilters();
    },
    formatDate(originalDate) {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'CET',
        hour12: false,
      };

      const getLocale = () => (
        (navigator.languages && navigator.languages.length)
          ? navigator.languages[0] : navigator.language);
      const Locale = getLocale();
      // Locale = 'en-US';
      // Locale = 'de-DE';

      const formattedDate = new Date(originalDate).toLocaleString(Locale, options);
      if (formattedDate === 'Invalid Date') {
        return ' ';
      }
      return formattedDate;
    },
    formatDatePickerDate(originalDate) {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'CET',
        hour12: false,
      };

      const getLocale = () => (
        (navigator.languages && navigator.languages.length)
          ? navigator.languages[0] : navigator.language);
      const Locale = getLocale();
      // Locale = 'en-US';
      // Locale = 'de-DE';

      const formattedDate = new Date(originalDate).toLocaleString(Locale, options);
      if (formattedDate === 'Invalid Date') {
        return ' ';
      }
      return formattedDate;
    },
    async parseTagIdentifiers() {
      const allIdentifiers = this.getFilterDataTags.map((t) => {
        if (this.lang === 'en') {
          return t.identifier.en;
        }
        if (this.lang === 'de') {
          return t.identifier.de;
        }
        return t.identifier.en;
      });

      const uniqueIdentifiers = [];

      allIdentifiers.forEach((item) => {
        if (!uniqueIdentifiers.includes(item)) {
          uniqueIdentifiers.push(item);
        }
      });

      this.tagIdentifiers = uniqueIdentifiers.map((i) => {
        const identifier = {
          name: i,
          open: false,
        };
        return identifier;
      });
    },
    clearMemberSinceDates() {
      this.startDate = '';
      this.endDate = '';
    },
    clearLevel() {
      this.minLevel = '';
      this.maxLevel = '';
    },
  },
};
</script>

<style scoped>
input {
  font-family: "Poppins";
}
.filter-picks-element > p {
  padding: 0px;
}

.filter-name,
.filter-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0px var(--card-padding) 0px var(--card-padding);
}

.filter-title {
  border-top: 1px solid gray;
}

.filter-option {
  color: gray;
}

.filter-picks {
  background-color: peachpuff;
  max-height: 230px;
  overflow-y: auto;
  padding: 5px 0px 5px 25px;
}

.filter-title-container,
.filter-picks-element {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter-button {
  border-top: 1px solid gray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button {
  display: block;
  margin: 0.5rem;

  padding: 10px 35px;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}
.delete-button {
  color: var(--color-orange);
  cursor: pointer;
}

.close-btn {
  font-family: "Poppins";
  color: white;
  background: none;
  border: none;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  margin-left: 10px;
  padding: 0;
}

.close-btn:hover {
  color: black;
}

/*------------------- CHECKBOX -----------------------*/

.checkbox-icon-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 5px;
}

.checked-icon {
  color: var(--color-orange);
  font-size: 22px;
}

.unchecked-icon {
  font-size: 22px;
}

.filter-indicator-on-icon,
.filter-indicator {
  width: 8px;
  height: 8px;
  background-color: var(--color-orange);
  border-radius: 100px;
  margin-bottom: 10px;
}

.filter-indicator-on-icon {
  position: absolute;
  top: -3px;
  right: 3px;
}

/*------------ FILTER DROPDOWN ---------------*/

.filter-component {
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 5px 10px;
  font-size: 14px;
  margin: 5px 0.8rem 5px;
  border-radius: 0.5rem;
  border-radius: var(--card-border-radius);
  background-color: var(--color-light);
}

.filter-component:hover + .filter-options-container,
.filter-options-container:hover {
  display: flex;
  flex-direction: column;
}

.filter-icon {
  margin-right: 7px;
  height: 20px;
}

.dropdown-container {
  position: relative;
}

.filter-options-container {
  display: none;
  position: absolute;
  top: 92%;
  right: 0;
  z-index: 1;
  padding-top: 5px;
}

.filter-options-container.active {
  display: flex;
  flex-direction: column;
}

.filter-options {
  width: 400px;
  max-height: 500px;
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: var(--card-border-radius);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: var(--color-milk);
}

.tag-container {
  padding: 5px 0px 5px 25px;
}

.datePicker {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  min-height: 60px;
}

.pill-element-filter-count,
.pill-element {
  display: flex;
  align-items: center;
  background-color: var(--color-orange);
  border-radius: 20px;
  padding: 0px 3px;
  font-size: 11px;
  margin: 2px;
  color: var(--color-milk);
}

.pill-element-filter-count {
  min-width: 20px;
  justify-content: center;
  font-size: 13px;
}

.background-orange {
  background-color: var(--color-orange);
}

.padding0 {
  padding: 0px;
}

@media screen and (max-width: 738px) {
  .filter-options-container {
    left: 0;
    right: auto;
  }
}

@media screen and (max-width: 621px) {
  .filter-options-container {
    right: -100px;
    left: auto;
  }
}
@media screen and (max-width: 421px) {
  .filter-options-container {
    left: 0;
    right: auto;
  }
  .filter-options {
    width: 350px;
  }
}
@media screen and (max-width: 392px) {
  .filter-options-container {
    right: -55px;
    left: auto;
  }
}
</style>
