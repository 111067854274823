<template>
  <div class="tag-main-container">
    <div class="forms">
      <img class="platform-logo" src="@/assets/logo_transparent.png" alt="txt">
      <h1>{{ $t('TagSelectionOverlayComponent.chooseTags') }}</h1>
      <vee-form @submit="saveTags">
        <div v-for="tagQuestion in getTagQuestions"
             class="tag" id="tmpl_tagQuestion"
             :key="tagQuestion">
          <p class="legend">{{
              tagQuestion.hasOwnProperty('text') ? tagQuestion?.text[lang] : '-'
            }}</p>
          <div class="radio-container" id="tmpl_tag_radio_container">
            <label id="tmpl_tag_radio" v-for="tag in getQuestionTags(tagQuestion.tags)" :key="tag">
              <div class="content"
                   :class="{checked: isSelectedAnswer(tagQuestion._id, tag._id)}"
                   @click.prevent="selectTagAnswer(tagQuestion, tag._id)">
                <span>{{ tag.hasOwnProperty('tag') ? tag.tag[lang] : '-' }}</span>
              </div>
            </label>
          </div>
        </div>
        <div v-if="getTags.length !== 0" class="wrapper" id="div_tagFieldWrapper">
          <div id="div_tagField">
            <div class="legend">
              {{ $t('TagSelectionOverlayComponent.selectTags') }}
            </div>
            <div class="multiple-container" id="tmpl_tag_checkbox_container">
              <label class="option-item" id="tmpl_tag_checkbox" v-for="tag in getTags"
                     :key="tag" @click.prevent="toggleSelection(tag._id)">
                <div class="content" :class="{checked: isChecked(tag)}">
                  <span>{{ tag.hasOwnProperty('tag') ? tag.tag[lang] : '-' }}</span>
                  <div class="icon">
                    <i class="fa-solid fa-xmark"></i>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <button type="submit" class="tag-button" name="regist" id="btn_regist"
                :disabled="isSubmitting">
          {{ $t('TagSelectionOverlayComponent.submit') }}
        </button>
      </vee-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TagSelectionOverlayComponent',
  props: {
    overlay: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  computed: {
    getTags() {
      return this.$store.state.auth.unprotectedTags;
    },
    getTagQuestions() {
      return this.$store.state.auth.tagQuestions;
    },
    lang() {
      return this.$i18n.locale;
    },
  },
  methods: {
    toggleSelection(id) {
      this.$store.commit('toggleTagSelection', id);
    },
    isChecked(tag) {
      return this.$store.state.auth.selectedTags.includes(tag._id);
    },
    getQuestionTags(tagIds) {
      return this.$store.state.auth.protectedTags.filter((el) => tagIds.includes(el._id));
    },
    selectTagAnswer(tagQuestion, tagId) {
      this.$store.commit('setTagQuestionAnswer', {
        tagQuestion,
        tagId,
      });
    },
    isSelectedAnswer(questId, tagId) {
      if (questId in this.$store.state.auth.tagQuestonsAnswers) {
        return tagId === this.$store.state.auth.tagQuestonsAnswers[questId];
      }

      return false;
    },
    async saveTags() {
      try {
        this.isSubmitting = true;
        if (this.checkTagErrors()) {
          this.isSubmitting = false;
          return;
        }
        const questionTagObject = this.$store.state.auth.tagQuestonsAnswers;
        const questionTags = Object.keys(questionTagObject)
          .map((f) => questionTagObject[f]);
        const selectedTags = [
          ...this.$store.state.auth.selectedTags,
          ...questionTags];
        await this.$store.dispatch('setTags', {
          id: this.overlay._id,
          tags: selectedTags,
        });
        this.isSubmitting = false;
      } catch (error) {
        this.isSubmitting = false;
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
    checkTagErrors() {
      let hasError = false;
      this.$store.state.auth.tagQuestions.forEach((el) => {
        if (!(el._id in this.$store.state.auth.tagQuestonsAnswers)) {
          hasError = true;
          this.$store.commit('setToastNotification', {
            id: Math.random(),
            message: this.$t('TagSelectionOverlayComponent.pleaseAnswerQuestion', { question: el.text[this.lang] }),
            type: 'error',
          });
        }
      });
      return hasError;
    },
  },
  mounted() {
    this.$store.dispatch('getTags');
    this.$store.dispatch('getTagQuestions');
  },
  unmounted() {
    this.$store.commit('setSelectedTags', []);
    this.$store.commit('setTagQuestions', []);
    this.$store.commit('removeTagQuestionAnswers');
    this.$store.commit('removeUnprotectedTags');
    this.$store.commit('removeProtectedTags');
  },
};
</script>

<style scoped>
/*---------------- GENERAL ------------------*/
.tag-main-container {
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
  max-width: 500px;
  max-height: 90%;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  background: var(--color-milk);
  overflow-y: scroll;
}

h1 {
  text-align: center;
  font-family: "Poppins";
  font-size: 25px;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 27px;
  color: var(--color-dark);
  padding: var(--card-padding) 0 var(--card-padding);
}

h2 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 27px;
  color: var(--color-dark);
}

h3 {
  font-family: "Poppins";
  font-size: 30px;
  font-weight: normal;
  letter-spacing: 0.15px;
  line-height: 27px;
  color: var(--color-dark);
}

h4 {
  padding: 0.5rem 0;
}

p {
  padding: 0.5rem 0;
}

.link {
  color: var(--color-orange);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.1px;
  line-height: 19px;
  text-align: right;
  text-decoration: none;
}

.link:hover {
  transition: .5s;
  color: var(--color-fulvous);
}

.photo { /*gilt für alle Fotos*/
  display: block;
  width: 100%;
}

.pass {
  float: left;
  cursor: pointer;
  color: var(--color-orange);
  font-weight: 600;
  padding: 15px 10px;
}

.pass:hover {
  color: var(--color-fulvous);
}

.pass i {
  padding: 0 .5rem;
}

/*------------ FORM ----------------*/
.tag-main-container .forms {
  padding: 30px;
}

.platform-logo {
  display: block;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  width: 10rem;
  height: auto;
}

/*------------------ ADD TAGS -------------------*/
.tag-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.tag {
  flex-direction: column;
}

.legend {
  text-align: center;
  font-weight: bold;
}

.tag .radio-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.tag .radio-container label {
  display: flex;
  position: relative;
  margin: 5px;
  width: fit-content;
  cursor: pointer;
}

.tag .radio-container label .content {
  display: flex;
  padding: 15px;
  width: 100%;
  border: 1px solid var(--color-light-grey);
  border-radius: 10px;
  margin: 0px 10px;
}

.tag .radio-container input[type="radio"]:checked ~ .content span {
  font-weight: 600;
}

.tag .radio-container .content .icon {
  position: absolute;
  top: 1px;
  right: 0px;
}

.wrapper {
  position: relative;
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.multiple-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.multiple-container label {
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 5px;
  width: fit-content;
}

.multiple-container input[type="checkbox"] {
}

.multiple-container input[type="checkbox"]:checked ~ .content .icon {
  display: block;
}

.multiple-container input[type="checkbox"]:checked ~ .content {
  color: var(--color-orange);
  background-color: var(--color-bisque);
  border: var(--color-orange);
}

.checked {
  color: var(--color-orange);
  background-color: var(--color-bisque);
  border: var(--color-orange);
  font-weight: 600;
}

.multiple-container .option-item {
  display: block;
  position: relative;
  margin: 5px;
  width: fit-content;
  cursor: pointer;
}

.option-item .content {
  display: flex;
  padding: 15px;
  width: 100%;
  border: 1px solid var(--color-light-grey);
  border-radius: 10px;
  margin: 0px 10px;
}

/*------------------ BUTTONS -------------------*/
.tag-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  padding: 10px 35px;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

button[type=button]:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

@media (max-width: 575px) {
  .tag-main-container {
    margin-top: 50px;
    margin-bottom: 50px;
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 0;
    overflow-y: scroll;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .tag-main-container {
    margin-top: 50px;
    margin-bottom: 50px;
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    overflow-y: scroll;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .tag-main-container {
    margin-top: 50px;
    margin-bottom: 50px;
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    overflow-y: scroll;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .tag-main-container {
    margin-top: 50px;
    margin-bottom: 50px;
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 15px;
    overflow-y: scroll;
  }
}
</style>
