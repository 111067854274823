import axios from 'axios';

export default class NetworkContent {
  static getTeams(config) {
    return axios.get('content/getTeams', config);
  }

  static async getTeamspace(config) {
    return axios.get('content/getTeamspace', config);
  }

  static async getAssessmentsOverview(config) {
    return axios.get('assessment/getAssessmentOverview', config);
  }

  static async getParticipants(config) {
    return axios.get('content/ksscreator/getUser', config);
  }

  static async getArchivedTasks(config) {
    return axios.get('content/ksscreator/getArchiv', config);
  }

  static async getCreatedTasks(config) {
    return axios.get('content/ksscreator/getAlreadyCreated', config);
  }

  static async createKssTask(data, config) {
    return axios.post('assessment/kss/create', data, config);
  }

  static async create360Mindset(data, config) {
    return axios.post('assessment/mindset360/create', data, config);
  }

  static async deleteKssTask(data, config) {
    return axios.post('assessment/kss/delete', data, config);
  }

  static async updateTaskEventsStatus(data, config) {
    return axios.post('assessment/kss/updateTaskEventsStatus', data, config);
  }

  static getLeadedTeams(config) {
    return axios.get('content/getLeadedTeams', config);
  }

  static getAllUsers(config) {
    return axios.get('content/getAllUser', config);
  }

  static getPersonalMindset(config) {
    return axios.get('content/dashboard/personalMindset', config);
  }

  static getPersonalMindsetPlot(data, config) {
    return axios.post('content/dashboard/personalMindsetPlot', data, config);
  }

  static getNotifications(config) {
    return axios.get('notification/allPopups', config);
  }

  static deactivatePopUp(id, config) {
    return axios.post('notification/deactivatePopup', { id }, config);
  }

  static subscribeToPush(data, config) {
    return axios.post('notification/subscribe', data, config);
  }

  static getDashboardProfileDesc(config) {
    return axios.get('content/dashboard/profileDescription', config);
  }

  static async getPersonalKssPlot(config) {
    return axios.get('content/dashboard/personalKSSPlot', config);
  }

  static getPersonalMoodomatPlot(config) {
    return axios.get('content/dashboard/personalMoodomatPlot', config);
  }

  static getTeamDashboard(config) {
    return axios.get('content/teamDashboard/dashboardData', config);
  }

  static getDashboardHtml(config) {
    return axios.get('content/dashboard/underDiagramHTML', config);
  }

  static getTeamDashboardPlots(data, config) {
    return axios.post('content/teamDashboard/dashboardDataPlot', data, config);
  }

  static getInteractiveContent(data, config) {
    return axios.post('content/interactiveContent', data, config);
  }

  static getOverlayNotes(config) {
    return axios.get('notification/allOverlays', config);
  }

  static deactivateOverlay(id, config) {
    return axios.post('notification/deactivateOverlay', { id }, config);
  }

  static setTeams(data, config) {
    return axios.post('content/setTeams', data, config);
  }

  static setTags(data, config) {
    return axios.post('content/tags/setTags', data, config);
  }

  static getDevTippOverview(config) {
    return axios.get('content/devTippLib/getOverview', config);
  }

  static getDevTippLibrary(config) {
    return axios.get('content/devTippLib/getLibrary', config);
  }

  static setNoticeDevTipp(data, config) {
    return axios.post('content/devTippLib/noticeTip', data, config);
  }

  static giveFeedback(data, config) {
    return axios.post('content/devTippLib/giveFeedback', data, config);
  }

  static setTippDone(data, config) {
    return axios.post('content/devTippLib/_setTippDone', data, config);
  }

  static getKnowledgeDatabase(config) {
    return axios.get('content/knowledgeDatabase', config);
  }

  static getKssHistory(config) {
    return axios.get('content/dashboard/ksshistory', config);
  }

  static getQuickAccessLinks(config) {
    return axios.get('content/quickAccessLinks', config);
  }

  static getProjectNames(config) {
    return axios.get('content/projectNames', config);
  }

  static getPersonalGoals(config) {
    return axios.get('assessment/getPersonalGoals', config);
  }

  static changeGoalStatus(data, config) {
    return axios.post('assessment/changeGoalStatus', data, config);
  }

  static submitPersonalGoalFeedback(data, config) {
    return axios.post('assessment/submitPersonalGoalFeedback', data, config);
  }

  static async createPersonalGoal(data, config) {
    return axios.post('assessment/personalgoals/create', data, config);
  }

  static deleteGoal(data, config) {
    return axios.post('assessment/deleteGoal', data, config);
  }

  static async updatePersonalGoal(data, config) {
    return axios.post('assessment/updatePersonalGoal', data, config);
  }

  static async getTravelBuddyMeetingInfo(config) {
    return axios.get('assessment/getTravelBuddyMeetingInfo', config);
  }

  static async submitTravelBuddyFeedback(data, config) {
    return axios.post('assessment/submitTravelBuddyFeedback', data, config);
  }
}
