import NetworkContent from '@/helpers/networking/NetworkContent';

export default {
  state: {
    mindset360Participants: [],
  },
  getters: {
    get360Participants: (state) => state.mindset360Participants,
  },
  mutations: {
    set360Participants: (state, data) => {
      data.users.forEach((el) => {
        if (el.name !== data.loggedInName) {
          state.mindset360Participants.push({
            _id: el.id,
            name: el.name,
            teams: el.teams,
            isChecked: false,
          });
        }
      });
    },
    update360ParticipantSelection: (state, index) => {
      state.mindset360Participants[index]
        .isChecked = !state.mindset360Participants[index].isChecked;
    },
    reset360Participatns: (state) => {
      state.mindset360Participants = [];
    },
  },
  actions: {
    async get360Participants({ commit }, loggedInName) {
      const { data } = await NetworkContent.getParticipants();
      commit('set360Participants', {
        users: data.users,
        loggedInName,
      });
    },
    async create360Task({ commit }, data360) {
      const { data } = await NetworkContent.create360Mindset(data360);
      if (data.success) {
        commit('reset360Participatns');
      }
    },
  },
};
