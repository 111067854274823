/* eslint-disable */
export default class AssessmentHelper {
  static evaluateStatus(taskElement) {
    const checkSameDay = (date1, date2) => {
      if (date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()) return true;
      return false;
    };

    const getLocale = () => ((navigator.languages && navigator.languages.length) ? navigator.languages[0] : navigator.language);

    let dateToShow = '';
    let dateHeadline = '';
    let status = 'inactive';

    const taskBegin = new Date(Date.parse(taskElement.date_taskBegin));
    const taskEnd = new Date(Date.parse(taskElement.date_taskEnd));

    const Dateoptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    const Timeoptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      // timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    // date =  taskBegin.toLocaleDateString('de-DE', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
    const Locale = getLocale();
    // Locale = 'en-US';
    // Locale = 'de-DE';

    let showDate_Begin = taskBegin.toLocaleDateString(Locale, Dateoptions);
    // time =  taskBegin.toLocaleString('de-DE', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone ,hour: '2-digit', minute:'2-digit'});
    const showTime_Begin = taskBegin.toLocaleString(Locale, Timeoptions);

    let showDate_End = taskEnd.toLocaleDateString(Locale, Dateoptions);
    // time =  taskBegin.toLocaleString('de-DE', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone ,hour: '2-digit', minute:'2-digit'});
    const showTime_End = taskEnd.toLocaleString(Locale, Timeoptions);

    if (checkSameDay(new Date(), taskBegin)) showDate_Begin = '';
    else showDate_Begin += '/';

    if (checkSameDay(new Date(), taskEnd)) showDate_End = '';
    else showDate_End += '/';

    const beginString = String(showDate_Begin) + String(showTime_Begin);
    const endString = String(showDate_End) + String(showTime_End);

    switch (taskElement.status) {
      case 'scheduled':
        status = 'inactive';
        dateHeadline = 'taskBeginsAt';
        dateToShow = beginString;
        break;
      case 'open':
        status = 'active';
        dateHeadline = 'taskDoUntil';
        dateToShow = endString;
        break;
      case 'began':
        status = 'active';
        dateHeadline = 'taskDoUntil';
        dateToShow = endString;
        break;
      case 'submitted':
        status = 'inactive';
        dateHeadline = 'empty';
        dateToShow = 'submitted';
        break;
      case 'expired':
        status = 'inactive';
        dateHeadline = 'taskExpired';
        dateToShow = endString;
        break;
      case 'canceled':
        status = 'inactive';
        dateHeadline = '';
        dateToShow = 'canceled';
        break;
    }

    return {
      status,
      dateToShow,
      dateHeadline,
    };
    
  }
}
