<template>
  <div class="popup-overview" v-if="getSuperUserProfileInfo">
    <div class="popup-content">
      <div class="profile-popup-container">
        <div class="profile-popup-left">
          <div class="profile-name-block">
            <div class="profile-photo">
              <img :src="getProfilePicture" alt="Profile picture" class="photo"/>
            </div>
            <div class="profile-name">
              <p>{{ getSuperUserProfileInfo.name }}</p>
              <div
                class="profile-level"
                v-if="getSuperUserProfileInfo.currentRewardStatus?.currentLevel"
              >
                <font-awesome-icon icon="fa-star" style="color: var(--color-orange)"/>
                <p>
                  {{ $t("SuperUserProfileOverlayComponent.level") }}:
                  {{ getSuperUserProfileInfo.currentRewardStatus.currentLevel }}
                </p>
              </div>
            </div>
          </div>
          <h3>{{ $t("SuperUserProfileOverlayComponent.userInfo") }}</h3>

          <div class="profile-contact" v-if="getSuperUserProfileInfo.email">
            <font-awesome-icon class="profile-contact-icon" icon="fa-regular fa-envelope"/>
            <p>{{ getSuperUserProfileInfo.email }}</p>
          </div>

          <div class="profile-contact" v-if="getSuperUserProfileInfo.mobileNumber">
            <font-awesome-icon class="profile-contact-icon" icon="fa-solid fa-phone"/>
            <p>{{ getSuperUserProfileInfo.mobileNumber }}</p>
          </div>

          <p class="profile-subtitle" v-if="getSuperUserProfileInfo.date_lastLogin">
            {{ $t("SuperUserProfileOverlayComponent.lastActivity") }}
          </p>
          <p v-if="getSuperUserProfileInfo.date_lastLogin">
            {{ formatDate(getSuperUserProfileInfo.date_lastLogin) }}
          </p>

          <p class="profile-subtitle" v-if="getSuperUserProfileInfo.date_registered">
            {{ $t("SuperUserProfileOverlayComponent.memberSince") }}
          </p>
          <p v-if="getSuperUserProfileInfo.date_registered">
            {{ formatDate(getSuperUserProfileInfo.date_registered) }}
          </p>
          <p class="profile-subtitle">
            {{ $t("SuperUserProfileOverlayComponent.teams") }}
          </p>
          <div class="pill-container user-grid-element">
            <div v-for="team in getSuperUserProfileInfo.teams" :key="team" class="pill-element">
              {{ team }}
            </div>
          </div>

          <p class="profile-subtitle">
            {{ $t("SuperUserProfileOverlayComponent.tags") }}
          </p>
          <div class="pill-container user-grid-element">
            <div v-for="tag in getSuperUserProfileTags" :key="tag">
              <div
                class="pill-element"
                @mouseover="showIdentifier(tag)"
                @mouseout="hideIdentifier(tag)"
                @focusin="showIdentifier(tag)"
                @focusout="hideIdentifier(tag)"
              >
                <p>{{ tag.tag[lang] }}</p>
              </div>
              <div
                @mouseover="showIdentifier(tag)"
                @mouseout="hideIdentifier(tag)"
                @focusin="showIdentifier(tag)"
                @focusout="hideIdentifier(tag)"
                class="tag-identifier-hidden"
                :class="{ 'tag-identifier-shown': showTagIdentifier.includes(tag) }"
              >
                <p>{{ tag.identifier[lang] }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="profile-popup-right">
          <h3>{{ $t("SuperUserProfileOverlayComponent.activities") }}</h3>

          <p class="profile-subtitle">
            {{ $t("SuperUserProfileOverlayComponent.openTasks") }}
          </p>
          <div class="pill-container user-grid-element">
            <div v-for="task in getSuperUserProfileTasks" :key="task">
              <div
                class="pill-element"
                @mouseover="showTaskHover(task)"
                @mouseout="hideTaskHover(task)"
                @focusin="showTaskHover(task)"
                @focusout="hideTaskHover(task)"
              >
                <p>
                  {{ task.schemaName }}
                </p>
              </div>
              <div
                @mouseover="showTaskHover(task)"
                @mouseout="hideTaskHover(task)"
                @focusin="showTaskHover(task)"
                @focusout="hideTaskHover(task)"
                class="tagHover-hidden"
                :class="{ 'tagHover-shown': showTasksHover.includes(task) }"
              >
                <div class="task-hover-info">
                  <div>
                    <p v-if="task.name != ''">
                      <strong>{{ $t("SuperUserProfileOverlayComponent.name") }}:</strong>
                      {{ task.name }}
                    </p>
                    <p v-if="task.createdFrom != ''">
                      <strong>{{ $t("SuperUserProfileOverlayComponent.creator") }}: </strong>
                      {{ task.createdFrom }}
                    </p>
                    <p v-if="task.description != ''">
                      <strong>{{ $t("SuperUserProfileOverlayComponent.description") }}:</strong>
                      {{ task.description }}
                    </p>
                  </div>
                </div>

                <div
                  class="taskEvents-finished"
                  v-if="task.taskEvents.find((t) => t.status == 'finished')"
                >
                  <div
                    class="taskEvent"
                    v-for="taskEvent in task.taskEvents.filter((t) => t.status == 'finished')"
                    :key="taskEvent"
                  >
                    <p>{{ formatDate(taskEvent.date_eventBegin) }}</p>
                    <p>{{ taskEvent.status }}</p>
                  </div>
                </div>
                <div
                  class="taskEvents-finished"
                  v-if="task.taskEvents.find((t) => t.status == 'open')"
                >
                  <div
                    class="taskEvent"
                    v-for="taskEvent in task.taskEvents.filter((t) => t.status == 'open')"
                    :key="taskEvent"
                  >
                    <p>{{ formatDate(taskEvent.date_eventBegin) }}</p>
                    <p>{{ taskEvent.status }}</p>
                  </div>
                </div>
                <div
                  class="taskEvents-finished"
                  v-if="task.taskEvents.find((t) => t.status == 'scheduled')"
                >
                  <div
                    class="taskEvent"
                    v-for="taskEvent in task.taskEvents.filter((t) => t.status == 'scheduled')"
                    :key="taskEvent"
                  >
                    <p>{{ formatDate(taskEvent.date_eventBegin) }}</p>
                    <p>{{ taskEvent.status }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p class="profile-subtitle">
            {{ $t("SuperUserProfileOverlayComponent.completedTasks") }}
          </p>
          <div>
            <div v-for="completedTask in getSuperUserProfileCompletedTasks" :key="completedTask">
              <p v-if="completedTask?.assessmentName">
                {{ completedTask?.assessmentName }}:
                <strong>{{ formatDate(completedTask?.date_taskEnd) }}</strong>
              </p>
            </div>
          </div>
          <p
            class="profile-subtitle"
            v-if="
              getSuperUserProfileInfo.currentRewardStatus &&
              getSuperUserProfileInfo.currentRewardStatus.currentRewardData
            "
          >
            {{ $t("SuperUserProfileOverlayComponent.weeklyGoalsStatus") }}
          </p>
          <div
            v-if="
              getSuperUserProfileInfo.currentRewardStatus &&
              getSuperUserProfileInfo.currentRewardStatus.currentRewardData
            "
          >
            <div
              class="goals-status"
              v-for="goal in this.getSuperUserProfileInfo.currentRewardStatus.currentRewardData
                .critereas"
              :key="goal"
            >
              <p class="marginRight5">
                {{
                  goal?.representationProperties?.critereaName?.[lang] ??
                  $t(`SuperUserProfileOverlayComponent.weeklyGoals.${goal?.id}`)
                }}:
              </p>
              <p :style="{ color: goal.have >= goal.should ? 'var(--color-orange)' : 'gray' }">
                <strong>{{ goal.have }}</strong>
              </p>
              <p>
                <strong>/{{ goal.should }}</strong>
              </p>
            </div>
          </div>

          <p class="profile-subtitle">
            {{ $t("SuperUserProfileOverlayComponent.workshopParticipation") }}
          </p>
          <div>
            <div
              v-for="omindWorkshop in getSuperUserProfileOmindWorkshops"
              :key="omindWorkshop"
              class="goals-status"
            >
              <p class="marginRight5">{{ omindWorkshop.name }}:</p>
              <p
                :style="{
                  color: new Date(omindWorkshop.date_workshop) <= new Date() ? 'black' : 'gray',
                }"
              >
                <strong>{{ formatDate(omindWorkshop.date_workshop) }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="delete-btn-container">
        <div class="delete-btn" @click.prevent="deleteUser">
          <font-awesome-icon class="profile-contact-icon" icon="fa-solid fa-trash-can"/>
          <p>{{ $t("SuperUserProfileOverlayComponent.deleteUser") }}</p>
        </div>
      </div>
    </div>
    <button class="close-btn" @click.prevent="closePopup">
      <font-awesome-icon icon="fa-xmark"/>
    </button>
  </div>

  <pop-up-confirmation-component
    v-if="showPopup"
    :message="popupMessage"
    :confirmButtonText="deleteButtonText"
    :cancelButtonText="cancelButtonText"
    :confirmButtonColor="'red'"
    @cancel="onPopupCancel"
    @confirm="onPopupConfirm"
  />
</template>

<script>
import PopUpConfirmationComponent from '@/components/global/PopUpConfirmationComponent.vue';

export default {
  name: 'SuperUserProfileOverlayComponent',
  components: {
    PopUpConfirmationComponent,
  },
  props: {
    data: Object,
  },

  data() {
    return {
      showTagIdentifier: [],
      showTasksHover: [],

      showPopup: false,
      popupMessage: this.$t('UserAdministrationComponent.popup.deleteMessage'),
      deleteButtonText: this.$t('UserAdministrationComponent.popup.deleteButton'),
      cancelButtonText: this.$t('UserAdministrationComponent.popup.cancelButton'),
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    getProfilePicture() {
      if (this.getSuperUserProfileInfo.picture_profile?.small) {
        return this.getSuperUserProfileInfo.picture_profile.small;
      }

      // eslint-disable-next-line global-require
      return require('@/assets/smiley-profil-bild.png');
    },
    getSuperUserProfileInfo() {
      return this.$store.getters.superUserProfileInfo;
    },
    getSuperUserProfileTags() {
      return this.$store.getters.superUserProfileTags;
    },
    getSuperUserProfileTasks() {
      return this.$store.getters.superUserProfileTasks;
    },
    getSuperUserProfileCompletedTasks() {
      return this.$store.getters.superUserProfileCompletedTasks;
    },
    getSuperUserProfileOmindWorkshops() {
      return this.$store.getters.superUserProfileOmindWorkshops;
    },
  },
  async mounted() {
    await this.$store.dispatch('fetchSuperUserProfileInfo', {
      userId: this.data.userData._id,
      lang: this.lang,
    });
  },
  methods: {
    closePopup() {
      this.$store.commit('removeFrontendOverlay', this.data.id);
    },
    formatDate(originalDate) {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'CET',
        hour12: false,
      };

      const getLocale = () => (
        (navigator.languages && navigator.languages.length)
          ? navigator.languages[0] : navigator.language);
      const Locale = getLocale();
      // Locale = 'en-US';
      // Locale = 'de-DE';

      const formattedDate = new Date(originalDate).toLocaleString(Locale, options);
      if (formattedDate === 'Invalid Date') {
        return ' ';
      }
      return formattedDate;
    },
    async deleteUser() {
      this.showPopup = true;
    },
    onPopupCancel() {
      this.showPopup = false;
    },
    async onPopupConfirm() {
      this.showPopup = false;
      const data = {};
      data.userIDs = [this.getSuperUserProfileInfo._id];

      try {
        this.showPopup = false;
        await this.$store.dispatch('deleteUser', data);
        await this.$store.dispatch('getAllUsers');
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('UserAdministrationComponent.toast.successfulDelete'),
          type: 'success',
        });
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: this.$t('UserAdministrationComponent.toast.errorDelete'),
          type: 'error',
        });
      }
      this.closePopup();
    },
    showIdentifier(tag) {
      this.showTagIdentifier.push(tag);
    },
    hideIdentifier(tag) {
      this.showTagIdentifier = this.showTagIdentifier.filter((t) => t._id !== tag._id);
    },
    showTaskHover(task) {
      this.showTasksHover.push(task);
    },
    hideTaskHover(task) {
      this.showTasksHover = this.showTasksHover.filter((t) => t._id !== task._id);
    },
  },
};
</script>

<style scoped>
/*---------------- PROFILE POPUP ------------------*/

p {
  padding: 0px;
  padding-top: 0px;
}

.profile-popup-container {
  display: grid;
  grid-template-columns: 50% 50%;
}

.profile-popup-left,
.profile-popup-right {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  flex-direction: column;
}

.profile-photo {
  margin-right: 5px;
  width: 60px;
  animation: 1;
  overflow: hidden;
  border-radius: 50%;
}

.delete-btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.delete-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  margin-right: 30px;
  background-color: red;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 12px;
  margin: 5px;
  color: var(--color-milk);
}

.profile-name-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  margin-bottom: 15px;
}

.profile-name {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.profile-contact {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-contact-icon {
  margin-right: 5px;
}

.profile-subtitle {
  font-size: 18px;
  color: gray;
  margin-top: 15px;
}

.profile-level {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.pill-element {
  white-space: pre-wrap;

  display: flex;
  align-items: center;
  background-color: var(--color-orange);
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 11px;
  margin: 5px;
  color: var(--color-milk);
}

.pill-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.tag-identifier-hidden {
  display: none;
}

.tag-identifier-shown {
  display: block;
  position: absolute;
  z-index: 10000;
  display: flex;
  align-items: center;
  background-color: var(--color-milk);
  border: 1px solid var(--color-orange);
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 11px;
  margin: 5px;
  color: black;
  margin-top: -60px;
  margin-left: 15px;
}

.tagHover-hidden {
  display: none;
}

.tagHover-shown {
  display: block;
  position: absolute;
  z-index: 10000;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 265px;
  background-color: var(--color-milk);
  border: 1px solid var(--color-orange);
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 11px;
  margin: 5px;
  color: black;
  margin-top: -13px;
  margin-left: -220px;
}

.task-hover-info {
  border-bottom: 1px solid orange;
  width: 100%;
  padding-bottom: 15px;
  line-height: 17px;
}

.taskEvents-finished {
  width: 100%;
  max-height: 60px;
  overflow: auto;
  border-bottom: 1px solid orange;
}

.taskEvent {
  max-width: 90%;

  display: grid;
  grid-template-columns: 155px auto;
}

.goals-status {
  display: flex;
  flex-direction: row;
}

/*---------------- POPUP CSS ------------------*/
.popup-overview {
  font-size: 12px;
  background-color: rgb(255, 255, 255);
  padding: 1em;
  border-radius: 2em;
  line-height: 1.5em;
  box-shadow: 4px 5px 0 3px rgba(0, 0, 0, 0.1);
  min-width: 550px;
  max-width: 90%;
  max-height: 90%;
  width: 1000px;
  height: fit-content;

  position: relative;
  overflow-y: auto;
}

.popup-content {
  position: relative;
  height: fit-content;
}

.close-btn {
  font-family: "Poppins";
  position: absolute;
  top: 5px;
  right: 15px;
  background: none;
  border: none;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.close-btn:hover {
  color: var(--color-orange);
}

.popup-overview h3 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.marginRight5 {
  margin-right: 5px;
}

@media only screen and (min-width: 540px) {
  .popup-overview {
    font-size: 14px;
  }

  .button-grid {
    margin-right: 5em;
  }
}

@media only screen and (max-width: 700px) {
  .profile-popup-container {
    grid-template-columns: 100%;
    height: fit-content;
  }
}

@media only screen and (max-width: 1020px) {
  .popup-overview {
    width: 90%;
    min-width: 0px;
  }
}

@media only screen and (min-width: 1150px) {
  .popup-overview {
    font-size: 14px;
    max-width: 60%;
  }
}
</style>
