<template>
  <div class="popup-container" id="div_popup">
    <component :is="getTemplate(notification)" :notification="notification"
               v-for="(notification) in allPopUps"
               :index="notification._id"
               :key="notification._id"/>
  </div>
</template>

<script>
import NewTaskNotificationComponent
  from '@/components/Home/Shared/PushNotifications/NewTaskNotificationComponent.vue';
import RecommendationNotificationComponent
  from '@/components/Home/Shared/PushNotifications/RecommendationNotificationComponent.vue';
import TaskReminderNotificationComponent
  from '@/components/Home/Shared/PushNotifications/TaskReminderNotificationComponent.vue';
import KssChampNotificationComponent
  from '@/components/Home/Shared/PushNotifications/KssChampNotificationComponent.vue';
import GenericTaskNotificationComponent
  from '@/components/Home/Shared/PushNotifications/GenericTaskNotificationComponent.vue';

export default {
  name: 'PushNotificationComponent',
  components: {
    KssChampNotificationComponent,
    TaskReminderNotificationComponent,
    RecommendationNotificationComponent,
    NewTaskNotificationComponent,
    GenericTaskNotificationComponent,
  },
  computed: {
    allPopUps() {
      return this.$store.getters.getNotifications;
    },
  },
  methods: {
    getTemplate(notification) {
      switch (notification.event) {
        case 'begin_kss':
          return 'NewTaskNotificationComponent';
        case 'TaskReminder':
          return 'TaskReminderNotificationComponent';
        case 'kssChamp':
          return 'KssChampNotificationComponent';
        case 'developmentTip':
          return 'RecommendationNotificationComponent';
        default:
          return 'GenericTaskNotificationComponent';
      }
    },
  },
};
</script>

<style scoped>
/*-------------- TEMPLATE POPUP ------------------------*/
.popup-container {
  /*position: -webkit-sticky;*/
  position: fixed;
  bottom: 20px;
  right: 5px;
  width: 50vw;
  max-width: 500px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: all 1s ease-in;
  z-index: 999 !important;
}

@media screen and (max-width: 768px) {
  .popup-container {
    width: 97%;
  }
}
</style>
