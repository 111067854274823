<template>
  <div v-if="text" class="message-box" :class="{'warning': isWarning}">
    <div class="">
      <font-awesome-icon v-if="isWarning" :icon="['fas', 'triangle-exclamation']" />
      {{text}}
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MessageBoxComponent',
  props: {
    text: String,
    isWarning: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
    };
  },
};
</script>
<style scoped>
.warning {
  background-color: #ff948c !important;
  color: white;
  border: 1px solid #f5c6cb;
}
.message-box {
  padding: 12px;
  border-radius: 5px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 13px;
  font-weight: 400;
  border: none;
  color: black;
  background-color: white;
}
</style>
