<template>
  <div @click="isOpen = !isOpen" class="expandBox">
    <h2 id="txt_infotextTitle">
      {{ title }}
    </h2>
    <div class="icon-container">
      <font-awesome-icon :icon="getIcon(isOpen)"/>
    </div>
  </div>
  <div v-show="isOpen" v-html="content" class="html-container">
  </div>

</template>

<script>
export default {
  name: 'KnowledgeDatabaseItemComponent',
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    getIcon(value) {
      return value ? 'fa-solid fa-angle-up' : 'fa-solid fa-angle-down';
    },
  },
};
</script>

<style scoped>

.expandBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0 0 2px rgb(255, 255, 255),
  0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.icon-container {
  display: flex;
  align-items: center;
}

.html-container {
  margin-top: 5px;
}

.html-container :deep(ol), .html-container :deep(ul) {
    padding: 0 2.25rem 1rem;
}

@media screen and (max-width: 521px) {
  #txt_infotextTitle {
    font-size: 23px;
  }

  .html-container:deep(h3) {
    font-size: 18px;
  }
}

</style>
