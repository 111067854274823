<template>
  <div class="profile" :class="{ 'selected': isSelected }">
    <div class="profile-photo">
      <img :src="getProfilePicture" alt="Profile picture" class="photo"/>
    </div>
    <p>{{ name }}</p>
    <i v-if="isSelected" class="applause-icon">
      <font-awesome-icon icon="fa-hands-clapping"/>
    </i>
  </div>
</template>

<script>
export default {
  props: {
    isSelected: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: '',
    },
    pictureUrl: {
      type: String,
      default: '',
    },
  },
  name: 'KssChampionProfile',
  computed: {
    getProfilePicture() {
      if (this.pictureUrl) {
        return this.pictureUrl;
      }

      // eslint-disable-next-line global-require
      return require('@/assets/smiley-profil-bild.png');
    },
  },
};
</script>

<style scoped>
/*------------------- PROFIL -----------------------*/
.profile {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  min-height: 120px;
  max-width: 150px;
  position: relative;
  padding: 1rem;
  box-sizing: border-box;
  margin: 0.8rem;
  border-radius: 0.5rem;
  cursor: pointer;
}

.profile p {
  text-align: center;
  word-break: break-word;
}

.profile-photo {
  width: 80px;
  animation: 1;
  overflow: hidden;
  border-radius: 50%;
}

.profile.selected {
  border: 2px solid var(--color-orange);
}

.applause-icon {
  position: absolute;
  top: -36px;
  right: -24px;
  font-size: 3rem;
  color: #FFC107;
}

@media (max-width: 600px) {
  .profile {
    margin: 0.2rem;
    padding: 0.2rem;
  }

  .profile-photo {
    width: 40px;
    height: 40px;
  }

  .applause-icon {
    top: -20px;
    right: -16px;
    font-size: 2rem;
  }
}

</style>
