<template>
  <breadcrumbs-component :bread-crumbs="workshopBreadcrumb" />
  <div class="workshop-container">
    <div class="wrapper">
      <div class="box">
        <template v-if="items.length > 0">
          <WorkshopResponseComponent
            v-for="(workshop, index) in items"
            :key="index"
            :workshop="workshop"
          ></WorkshopResponseComponent>
        </template>
        <no-workshop-component v-else />
      </div>
    </div>
  </div>
</template>
<script>
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';
import WorkshopResponseComponent from '@/components/Home/WorkshopComponent/WorkshopResponseComponent.vue';
import NoWorkshopComponent from './NoWorkshopComponent.vue';

export default {
  name: 'WorkshopComponent',
  props: [],
  components: { WorkshopResponseComponent, BreadcrumbsComponent, NoWorkshopComponent },
  data() {
    return {
      workshopBreadcrumb: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: '/workshops',
          isActive: true,
          name: this.$t('BreadCrumbs.workshops'),
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.getters.currentWorkshopItems;
    },
  },
  mounted() {
    this.$store.dispatch('getListWorkshop');
  },
};
</script>
<style scoped>
.workshop-container {
  margin: 0 auto;
  position: relative;
}

.wrapper {
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 10px;
  width: 100vw;
  max-width: 960px;
  margin: 0 auto;
}
.box {
  position: relative;
}
</style>
