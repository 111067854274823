export default {
  "LoginComponent": {
    "welcomeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereit für Deine Mindset-Entwicklung?"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
    "dontHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noch kein Benutzerprofil?"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
    "placeholders": {
      "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emailadresse"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])}
    }
  },
  "RegisterComponent": {
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle ein Benutzerprofil"])},
    "tos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGB"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzbestimmungen"])},
    "termsOfUseAndPrivacy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([" Ich stimme den ", _interpolate(_list(0)), " und ", _interpolate(_list(1)), " zu."])},
    "placeholders": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname*"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachame*"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emailaddresse*"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort*"])},
      "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password wiederholen*"])},
      "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handynummer +49"])}
    },
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])}
  },
  "ActivateAccountComponent": {
    "accountActivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Benutzerkonto wurde erfolgreich aktiviert!"])},
    "accountNotActivated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Benutzerkonto konnte nicht aktiviert werden. Bitte melde dich bei deinem Administrator."])},
    "goToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Login wechseln"])},
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "invalidUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige Url"])}
  },
  "PasswordResetComponent": {
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
    "resetInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du wirst innerhalb der nächsten 5 Minuten eine Email mit weiteren Informationen erhalten."])},
    "placeholders": {
      "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emailadresse*"])},
      "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Emailadresse eintragen."])}
    }
  },
  "SuccessPasswordChangeComponent": {
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "passwordChangeSuccessMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Passwort wurde geändert."])}
  },
  "NewPasswordComponent": {
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "headingEnterNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergebe hier dein neues Passwort."])},
    "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
    "placeholders": {
      "enterNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort*"])},
      "repeatNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen*"])}
    }
  },
  "AuthTeamComponent": {
    "chooseYourTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle deine Gruppe"])},
    "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerkonto erstellen"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])}
  },
  "AuthTagSelectComponent": {
    "chooseTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle deine Tags"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "selectTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle die Tags aus, die auf dich zutreffen:"])},
    "pleaseAnswerQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bitte beantworte die Frage: ", _interpolate(_named("question"))])}
  },
  "SuccessEmailResetComponent": {
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "emailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Email wurde versendet."])},
    "emailSentMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Email wurde an die Emailadresse versendet, die in deinem Profil hinterlegt ist."])}
  },
  "SuccessRegistrationComponent": {
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "registerSuccessMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierung erfolgreich"])},
    "registerInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Registrierung war erfolgreich. Du wirst in den kommenden Minuten eine Email an die angegebene Emailadresse erhalten, um dein Profil zu aktivieren."])}
  },
  "FooterComponent": {
    "legalNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGB"])}
  },
  "HeaderComponent": {
    "workshops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workshops"])},
    "oiMindset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMindset"])},
    "evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
    "administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "questionCatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragenkatalog"])},
    "kssTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollaborationstracker"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultate"])},
    "yourOwnResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Ergebnisse"])},
    "teamResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamergebnisse"])},
    "teamspaceSetup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team einrichten"])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMIN"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausloggen"])},
    "goToProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil bearbeiten"])},
    "knowledgeDatabase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wissensdatenbank"])},
    "omindset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMindset"])},
    "mngDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management Dashboard"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
    "personalSpace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Aktivitäten"])},
    "personalGoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Ziele"])}
  },
  "HomeComponent": {
    "teamspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamübersicht"])},
    "myTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Aufgaben"])},
    "yourWeekgoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Wochenziele"])},
    "myPersonalGoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Ziele"])}
  },
  "WeekgoalComponent": {
    "kssTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollaborationstracker"])},
    "kssCreator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KSS Creator"])},
    "moodomat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmungsbarometer"])},
    "oi_kpi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trust Evaluation"])}
  },
  "PersonalGoalsComponent": {
    "noGoalsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast bisher keine persönlichen Ziele gesetzt"])},
    "noGoalsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setze dir persönliche Ziele, um diesen Bereich mit Inhalten zu füllen und Ihre Entwicklung auf die nächste Stufe zu heben."])},
    "newGoalButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Ziel setzen"])},
    "showCompletedGoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreichte Ziele anzeigen"])},
    "popup": {
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
      "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "deleteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du dir sicher, dass du dieses Ziel löschen möchten?"])}
    }
  },
  "TaskComponent": {
    "mindset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMindset Evaluation"])},
    "kss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollaborationstracker"])},
    "oi_kpi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Innovation Evaluation"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Evaluation"])},
    "taskBeginsAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe beginnt"])},
    "taskDoUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig bis"])},
    "taskExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe abgelaufen"])},
    "noDeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Läuft nicht aus"])},
    "Status:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erledigt"])},
    "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgebrochen"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "FiltersComponent": {
    "selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl"])},
    "saveSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl speichern"])},
    "compareToAllCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen vergleichen"])}
  },
  "KssCalendarComponent": {
    "weekdaysShort": {
      "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So"])},
      "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo"])},
      "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di"])},
      "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi"])},
      "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
      "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fr"])},
      "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa"])}
    },
    "months": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januar"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februar"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["März"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juni"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juli"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oktober"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dezember"])}
    }
  },
  "BreadCrumbs": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["STARTSEITE"])},
    "kssTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollaborationstracker"])},
    "personalGoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliche Ziele"])},
    "workshops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workshops"])},
    "kssCreator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KSS-Creator"])},
    "knowledgeDatabase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wissensdatenbank"])},
    "omindset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMindset"])},
    "evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
    "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADMIN"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard - Meine Ergebnisse"])},
    "teamDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard - Teamergebnisse"])},
    "editProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil bearbeiten"])},
    "Module2OIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module 2 OMindset Evaluation"])},
    "mngDashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management Dashboard"])},
    "administration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
    "teamadministration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams"])},
    "useradministration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "pauseNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausenbenachrichtigungen verwalten"])}
  },
  "mngDashboardComponent": {
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeige"])},
    "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstecke"])},
    "teams/tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "notEnoughDataToDisplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht genügend Daten zum Anzeigen"])},
    "collaborationTrendOverTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollaborationstrend"])},
    "theOMindsetInYourCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das OMindset in Ihrem Unternehmen"])},
    "avgKssTrend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⌀ Kollaborationstrend"])},
    "Attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis!"])},
    "teamsSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mannschaften ausgewählt"])},
    "tagSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags ausgewählt"])},
    "yourWeeklyReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr wöchentlicher Bericht"])},
    "relativeData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relative Werte"])},
    "csvDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV-Download"])},
    "performance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeit"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivität"])},
    "360OMindset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["360 OMindset"])},
    "collaborationTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollaborationstracker"])},
    "mood-o-mat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmungsbarometer"])},
    "developmentTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwicklungstipps"])},
    "totalUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzer gesamt"])},
    "vsLastQuarter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["im Vergleich zum letzten Quartal"])},
    "totalTeams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams gesamt"])},
    "avgTimespent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⌀ auf Plattform verbrachte Zeit in dieser Woche"])},
    "avgOvertime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⌀ auf Plattform verbrachte Zeit über alle Wochen"])},
    "avgLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⌀ Logins in dieser Woche"])},
    "activeUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzer mit Login in dieser Woche"])},
    "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gesendet"])},
    "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["erstellt"])},
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["abgelaufen"])},
    "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ausgefüllt"])},
    "avgSatisfaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⌀ Stimmungslage"])},
    "avgChecked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⌀ durchgeführte Tipps"])},
    "avgFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["⌀ hilfreiche Tipps"])},
    "clickToCompareCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicken Sie hier, um mit dem gesamten Unternehmen zu vergleichen"])},
    "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergleichen"])}
  },
  "KssTrackerComponent": {
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu"])},
    "alreadyCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereits erstellt"])},
    "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiv"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche..."])},
    "showArchivedTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivierte Aufgaben anzeigen"])}
  },
  "KssCreatorComponent": {
    "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum auswählen"])},
    "selectTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle die erwartete Endzeit des Termins aus"])},
    "furtherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Informationen hinzufügen"])},
    "nextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "createKssTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])}
  },
  "KssRuntimeComponent": {
    "createKssTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollaborationstracker erstellen"])},
    "headingRuntime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle den voraussichtlichen Endzeitpunkt des Termins"])},
    "selectRuntime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitpunkt bestätigen"])},
    "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum wählen"])},
    "startKssTrackerNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis: Die Startzeit des Kollaborationstrackers sollte der Endzeitpunkt des Termins sein."])},
    "showArchivedTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivierte Aufgaben anzeigen"])},
    "myTrackers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Kollaborationstracker"])}
  },
  "KssChampionUserListQuestion": {
    "beforeNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst noch "])},
    "afterNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Wertschätzungen verteilen."])},
    "maybeNextTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vielleicht nächstes Mal"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wertschätzen"])}
  },
  "KssFormComponent": {
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ausgewählt"])},
    "addParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer hinzufügen"])},
    "addTeams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams hinzufügen"])},
    "addDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung hinzufügen (optional)"])},
    "addMeetingSubject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Termins hinzufügen"])}
  },
  "ExternalUsersInviteComponent": {
    "externalInvites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Teilnehmer"])},
    "placeholder": {
      "enterEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emailadressen eingeben"])}
    }
  },
  "KssTaskArchivedComponent": {
    "collaborationTracker": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kollaborationstracker: ", _interpolate(_named("name"))])},
    "lessParticipants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["weniger als ", _interpolate(_named("count")), " Teilnehmer"])}
  },
  "KssTaskCreatedComponent": {
    "participants": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Teilnehmer"]), _normalize(["Teilnehmer"])])},
    "updateTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
    "nextEvents": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die nächsten ", _interpolate(_named("count")), " Events:"])},
    "collaborationTracker": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kollaborationstracker: ", _interpolate(_named("name"))])},
    "toast": {
      "successfulCancelDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracker erfolgreich deaktiviert"])},
      "successfulScheduleDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tracker erfolgreich reaktiviert"])},
      "successfulDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Aufgabe wurde gelöscht"])},
      "errorDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Aufgabe konnte nicht gelöscht werden"])}
    },
    "popup": {
      "deleteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du dir sicher, dass du diese Aufgabe löschen möchtest?"])},
      "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
    }
  },
  "TravelBuddy": {
    "travelBuddy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisepartner:in"])},
    "markAsDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erledigt"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aussetzen"])},
    "skipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgesetzt"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erledigt"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KW"])},
    "userMarkedMeetingAsSkipped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting als ausgesetzt markiert"])},
    "userMarkedMeetingAsCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meeting als erledigt markiert"])},
    "planMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktiere deine:n Reisepartner:in und verabredet euch."])},
    "meetingInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der/die Einladende:"])},
    "meetingArrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankommen: \"Wie geht es dir heute?\""])},
    "meetingQ1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Problemstellung beschäftigt dich gerade im Arbeitsalltag?"])},
    "meetingQ1Tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besprecht gemeinsam mögliche Lösungsideen"])},
    "meetingQ2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hast du kürzlich etwas Neues oder Interessantes gelernt, z.B. einen \"Life Hack\"?"])},
    "meetingQ2Tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teile dein neues Wissen und sprecht darüber"])},
    "switchRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollentausch"])},
    "wrapUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puffer am Ende"])},
    "noTravelBuddyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast keine:n Reisepartner:in diese Woche."])}
  },
  "PersonalGoals": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
    "nextButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "backButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "createGoal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel erstellen"])},
    "qSpecific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie lautet das Ziel in einem Satz?"])},
    "qMeasurable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie werde ich den Fortschritt messen und feststellen, wann das Ziel erreicht ist?"])},
    "qAchievable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welche Dinge muss ich tun, um dieses Ziel zu erreichen?"])},
    "qRelevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warum ist dieses Ziel wichtig und relevant für mich oder meine Organisation?"])},
    "qGoalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bis wann möchte ich das Ziel erreichen?"])},
    "qGoalType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um welche Art von Ziel handelt es sich?"])},
    "qGoalName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zielname"])},
    "popupMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast nicht alle Felder befüllt, bist du sicher, dass du fortfahren möchtest?"])},
    "selectGoal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte die Art des Ziels wählen"])},
    "enterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Zielnamen eingeben"])},
    "incorrectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum kann nicht in der Vergangenheit liegen"])},
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort eingeben"])},
    "specific": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spezifisch?"])},
    "measurable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messbar?"])},
    "achievable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realistisch?"])},
    "relevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attraktiv?"])},
    "deleteGoalButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziel Löschen"])},
    "goalType": {
      "habit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewohnheit"])},
      "mindset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindset"])},
      "reflection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reflexion"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere"])}
    },
    "successMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Ziel erstellt"])},
    "updateMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Goal aktualisiert"])},
    "toast": {
      "maximumGoalsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Anzahl aktiver Ziele erreicht."])}
    },
    "desc": {
      "mindset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle diesen Zieltyp aus, wenn du gezielt an deiner Einstellung und deinem Umgang mit gewissen Situationen arbeiten möchtest."])},
      "habit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Typ ist geeignet für Ziele mit denen du eine neue Gewohnheit aufbauen oder eine neue Routine etablieren möchtest."])},
      "reflection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Typ ist eine gute Wahl, wenn es dein Ziel ist inne zu halten, einen Schritt zurück zu machen und etwas zu reflektieren."])}
    }
  },
  "Mindset360CreatorComponent": {
    "furtherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Informationen hinzufügen"])},
    "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählt"])},
    "addParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer hinzufügen"])},
    "addTeams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams hinzufügen"])},
    "externalInvites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Externe Einladungen"])},
    "sendRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage senden"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle <span style=\"font-weight: bold;color: var(--color-orange);\">7-10 Personen</span> aus, von denen du eine 360° OMindset Evaluation über dich anfordern möchtest. Sobald mindestens 5 geantwortet haben, siehst du ein Ergebnis. Mehr Informationen findest du <a href=\"/knowledge-database\">hier</a>."])}
  },
  "Mindset360ParticipantsSelectionComponent": {
    "placeholder": {
      "searchInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmer suchen"])},
      "noParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Teilnehmer"])}
    }
  },
  "TeamAdministrationComponent": {
    "createNewTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Team"])}
  },
  "UserAdministrationComponent": {
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Ergebnisse"])},
    "popup": {
      "deleteMessage": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Möchtest du diesen Benutzer wirklich löschen?"]), _normalize(["Möchtest du diese Benutzer wirklich löschen?"])])},
      "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
    },
    "toast": {
      "successfulTaskCreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Aufgabe wurde erstellt"])},
      "errorTaskCreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Aufgabe konnte nicht erstellt werden"])},
      "successfulDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Benutzer wurden gelöscht"])},
      "errorDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Benutzer konnten nicht gelöscht werden"])},
      "successfulDeleteForOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen des Benutzers erfolgreich"])},
      "errorDeleteForOne": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Löschen des Benutzers"])}
    },
    "options": {
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtern"])},
      "deleteMarked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle markierten löschen"])},
      "createAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe erstellen"])},
      "downloadCSV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSV herunterladen"])},
      "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählt"])},
      "usersPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer pro Seite"])}
    },
    "columnNames": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emailadresse"])},
      "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
      "lastActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Aktivität"])}
    },
    "filterOptions": {
      "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
      "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams"])},
      "openTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offene Aufgaben"])},
      "registrationPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldezeitraum"])},
      "deleteAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle löschen"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtern"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach"])},
      "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vor"])},
      "workshopParticipation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workshop Teilnahme"])}
    },
    "downloadCSV": {
      "downloadSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgewählte Nutzer"])},
      "downloadAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Nutzer"])}
    }
  },
  "UserAdministrationCreateAssessmentComponent": {
    "customSchemaId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom SchemaID"])},
    "options": {
      "createAssessment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe erstellen"])}
    }
  },
  "SuperUserProfileOverlayComponent": {
    "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzerinfo"])},
    "lastActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Aktivität"])},
    "memberSince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied seit"])},
    "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivitäten"])},
    "openTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offene Aufgaben"])},
    "completedTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossene Aufgaben"])},
    "weeklyGoalsStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Wochenziele"])},
    "workshopParticipation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workshopteilnahme"])},
    "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
    "teams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams"])},
    "deleteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzer löschen"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersteller"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
    "weeklyGoals": {
      "kssTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KSS-Tracker"])},
      "kssCreator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KSS Creator"])},
      "moodomat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmungsbarometer"])},
      "oi_kpi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Innovation Evaluation"])}
    }
  },
  "SingleEditTeamComponent": {
    "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder: "])},
    "editThisTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team bearbeiten"])},
    "editTeamName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamname bearbeiten"])},
    "noUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Nutzer"])},
    "generalPlatformUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Plattformnutzer"])},
    "teamLeads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führungskräfte"])},
    "addToLeads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Führungskräften hinzufügen"])},
    "addToMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu Mitgliedern hinzufügen"])},
    "removeTeamMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglied entfernen"])},
    "removeTeamlead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führungskraft entfernen"])},
    "newTeamNamePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Team"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "saveInit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
    "toast": {
      "successfulDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Team wurde gelöscht."])},
      "errorDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Team konnte nicht gelöscht werden."])}
    },
    "popup": {
      "deleteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du dir sicher, dass du dieses Team löschen möchtest?"])},
      "deleteButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
    },
    "searchPlaceholders": {
      "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche Nutzer"])},
      "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche Mitglieder"])},
      "leads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche Führungskräfte"])}
    },
    "displayTeamspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Teamspace anzeigen"])}
  },
  "QuestionnaireComponent": {
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächste"])}
  },
  "KssChampionQuestion": {
    "kssChampion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KSS Champ"])}
  },
  "IntegrativeComplexityQuestion": {
    "placeholders": {
      "answerTextbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Antwort hier eintragen"])}
    }
  },
  "TeamSelectionOverlayComponent": {
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
    "chooseYourTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle deine Gruppe"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppe ausgewählt!"])}
  },
  "TagSelectionOverlayComponent": {
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "chooseTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle deine Tags"])},
    "selectTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wähle die Tags aus, die auf dich zutreffen:"])},
    "pleaseAnswerQuestion": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bitte beantworte die Frage: ", _interpolate(_named("question"))])}
  },
  "KssChampNotificationComponent": {
    "congratulations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glückwunsch!"])},
    "youRecievedKssChampAward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du wurdest im letzten Termin zum KSS-Champion gewählt."])}
  },
  "KssParticipantsSelectionComponent": {
    "placeholder": {
      "searchInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche Teilnehmer"])},
      "noParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Teilnehmer"])}
    }
  },
  "EditKssParticipantsSelectionComponent": {
    "placeholder": {
      "searchInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche Teilnehmer"])},
      "noParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Teilnehmer"])}
    }
  },
  "KssTeamSelectionComponent": {
    "placeholder": {
      "searchInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche Teams"])},
      "noTeams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Teams"])}
    }
  },
  "MissionCompleteComponent": {
    "missionCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe erfolgreich abgeschlossen"])},
    "submittedAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Antworten wurden übermittelt."])},
    "seeResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])}
  },
  "SliderQuestion": {
    "datalistOptions": {
      "stronglyDisagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimme überhaupt nicht zu"])},
      "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimme nicht zu"])},
      "indifferent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neutral"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimme zu"])},
      "stronglyAgree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimme voll zu"])}
    }
  },
  "TextQuestion": {
    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Antwort eingeben"])}
  },
  "RateIntegrativeComplexityValue": {
    "datalistOptions": {
      "notImportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht wichtig"])},
      "lessImportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenig wichtig"])},
      "indifferent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weder wichtig noch unwichtig"])},
      "important": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wichtig"])},
      "stronglyImportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehr wichtig"])},
      "footlineOMIND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMIND platform GmbH"])}
    }
  },
  "KssRecurringTaskComponent": {
    "isRecurringTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, dies ist eine wiederkehrende Aufgabe."])},
    "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jeden Arbeitstag (Mo - Fr)"])},
    "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wöchentlich"])},
    "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monatlich"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerdefiniert"])},
    "repeatEvery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholen alle"])},
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tage"])},
    "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochen"])},
    "daysShort": {
      "MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mo"])},
      "TU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di"])},
      "WE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi"])},
      "TH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do"])},
      "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fr"])},
      "SA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa"])},
      "SU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So"])}
    }
  },
  "RecommendationNotificationComponent": {
    "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfahre mehr"])},
    "footlineOMIND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMIND platform GmbH"])},
    "devTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwicklungstipp"])},
    "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles klar!"])},
    "giveFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback geben"])}
  },
  "TaskReminderNotificationComponent": {
    "finishIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beende sie"])},
    "taskiWillExpireInfoMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", bitte denke daran, dass deine Aufgabe abläuft in "])},
    "rightAway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jetzt"])},
    "footlineOMIND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMIND platform GmbH"])},
    "hoursShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunde"])},
    "hoursPlural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunden"])},
    "minutesShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
    "secondsShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekunden"])}
  },
  "NewTaskNotificationComponent": {
    "clickHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klicke hier"])},
    "footlineOMIND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMIND platform GmbH"])},
    "wantToCheckItOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willst du sie anschauen?"])},
    "newTaskCreatedForYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", du hast eine neue Aufgabe erhalten."])}
  },
  "DashboardComponent": {
    "yourCurrentOMindset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein aktuelles OMindset"])},
    "yourKnowlExchangeHis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Kollaborationstrend"])},
    "notEnoughDataAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten verfügbar"])},
    "yourMoodomat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmungsbarometer"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lädt..."])},
    "collaborationTrend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollaborationstrend"])},
    "defaultErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist aufgetreten"])}
  },
  "DashboardKssHistoryComponent": {
    "kssChampionDataTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Wissensaustausch im letzten vollen Kalendermonat"])},
    "positiveFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["positives Feedback für deine aktive Mitarbeit"])},
    "kssCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollaborationstracker erstellt"])},
    "kssSubmtted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollaborationstracker erfolgreich abgeschlossen"])}
  },
  "TeamDashboardComponent": {
    "omindsetTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das OMindset deiner Teams"])},
    "kssTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der KSS deiner Teams"])},
    "notEnoughDataAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten verfügbar"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lädt..."])},
    "moodomat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmungsbarometer"])},
    "moodomatThisWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmungsbarometer in der aktuellen Woche"])},
    "moodomatPrevWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmungsbarometer in der letzten Woche"])},
    "peoplePrevCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Teilnehmer"])},
    "peopleCurrCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Teilnehmer"])}
  },
  "CompareOimComponent": {
    "compareWithPastOrTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergleiche deine Ergebnisse"])},
    "notEnoughDataAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Daten verfügbar"])},
    "compareTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vergleichen mit"])}
  },
  "NotFoundComponent": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite wurde nicht gefunden"])},
    "backToHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])}
  },
  "EditProfileComponent": {
    "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "chooseLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache auswählen"])},
    "receiveSmsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS"])},
    "receivePushText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push"])},
    "receiveMailText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "receiveSmsTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen per SMS erhalten"])},
    "receivePushTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen per Push erhalten"])},
    "receiveMailTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen per Email erhalten"])},
    "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handy (Format: +49...)"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emailadresse"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "profileSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil wurde gespeichert"])},
    "errorSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil konnte nicht gespeichert werden"])},
    "NotificationSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungseinstellungen"])},
    "NotificationSettingsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte Benachrichtigungen erhalten per:"])},
    "PauseNotificationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte Benachrichtigungen pausieren"])},
    "PauseNotificationsWeekendsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochenende:<br> Keine Benachrichtigungen zwischen Freitag, 18:00 Uhr und Montag, 6:00 Uhr"])},
    "PauseNotificationsWorkingDayText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werktags (Mo–Fr):<br>Keine Benachrichtigungen zwischen "])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und"])}
  },
  "ImageUploadComponent": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "successUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild wurde erfolgreich hochgeladen!"])},
    "errorUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild konnte nicht hochgeladen werden."])}
  },
  "DashboardProfileComponent": {
    "yourMindsetInWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein OMindset in Wörtern"])},
    "yourMindset360InWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein OMindset aus Sicht deiner Kolleg:innen"])},
    "yourDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine stärksten Mindsetdimensionen sind:"])},
    "youImprove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst dich in diesen Bereichen verbessern:"])},
    "developmentTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwicklungstipp"])}
  },
  "LevelUpOverlayNoteComponent": {
    "congratulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herzlichen Glückwunsch!"])},
    "motivationalMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch dein Engagement bist du ein Level aufgestiegen. <br> Mach weiter so!"])},
    "gotIt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstanden!"])}
  },
  "IntroKssTrackerOverlayComponent": {
    "kssTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollaborationstracker"])},
    "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmen"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator"])},
    "didntParticipate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich habe an diesem Termin nicht teilgenommen."])},
    "mistakeChoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich wurde aus Versehen ausgewählt."])},
    "days": {
      "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sontag"])},
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montag"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstag"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittwoch"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donnerstag"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freitag"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samstag"])}
    }
  },
  "DevTipLibraryOverlayComponent": {
    "yourTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Entwicklungstipps"])},
    "noTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Entwicklungstipps"])}
  },
  "DevTipItemContainerComponent": {
    "yourTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deine Entwicklungstipps"])},
    "noTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Entwicklungstipps"])},
    "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehr anzeigen"])}
  },
  "DevTipItemComponent": {
    "recievedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhalten am:"])},
    "wasThisTipHelpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["War dieser Tipp hilfreich für dich?"])},
    "errorCouldNotFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konnte kein Feedback abgeben!"])},
    "errorCouldSetDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konnte Tipp nicht markieren!"])}
  },
  "FeedbackOverlayComponent": {
    "devTipFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwicklungstipp"])},
    "ourTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser Tipp:"])},
    "yourFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Feedback:"])},
    "wasTipHelpful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["War der Tipp für dich hilfreich?"])},
    "yesSuper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, super"])},
    "notReally": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein, nicht wirklich"])},
    "sendFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback senden"])},
    "placeholderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du dein Feedback eintippen..."])},
    "errorCouldNotFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konnte kein Feedback abgeben!"])},
    "errorRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler: Feedback ist erforderlich."])},
    "wasTipCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hast du den Tipp erledigt?"])},
    "notYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein, noch nicht"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
  },
  "TravelBuddyFeedbackOverlayComponent": {
    "yourFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Feedback"])},
    "sendFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback absenden"])},
    "howDoYouFeel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reflektiere für doch selbst: Was war dein Highlight eures Gesprächs?"])},
    "howDoYouFeelSkip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gibt es einen bestimmten Grund, dieses Treffen auszulassen?"])},
    "placeholderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du dein Feedback eintippen..."])},
    "errorCouldNotFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Feedback konnte leider aufgrund eines technischen Fehlers nicht gespeichert werden!"])},
    "meetingStatusQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat das Treffen stattgefunden?"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte aussetzen"])},
    "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
    "provideFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback geben"])}
  },
  "PersonalGoalFeedbackOverlayComponent": {
    "yourFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Feedback"])},
    "sendFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback absenden"])},
    "howDoYouFeel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie fühlst du dich?"])},
    "placeholderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kannst du dein Feedback eintippen..."])},
    "errorCouldNotFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Feedback konnte leider aufgrund eines technischen Fehlers nicht gespeichert werden!"])}
  },
  "SimilarKssWarningOverlayComponent": {
    "similarFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ähnliche Kollaborationstracker wurden gefunden."])},
    "doYouWantToCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du den Kollaborationstracker trotzdem erstellen?"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
  },
  "SimilarKssItemComponent": {
    "users": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([" ", _interpolate(_named("count")), " Teilnehmer"]), _normalize([_interpolate(_named("count")), " Teilnehmer"])])}
  },
  "KnowledgeDatabaseComponent": {
    "allYouNeedToKnow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles, was du über das Open Innovation Mindset (OMindset) wissen musst"])},
    "TheOMindset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das OMindset"])},
    "infotextParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ist definiert als <i>‘die Werte, Einstellungen und Überzeugungen einer Person, die die Offenheit für das Geben und (An-)Nehmen von Wissen widerspiegeln und somit den tatsächlichen Wissensaustausch beeinflussen. Dies bezieht sich insbesondere auf den gegenseitigen Austausch von Wissen innerhalb und außerhalb der Unternehmensgrenzen.’.</i> <br> (Engelsberger et al. 2021)"])},
    "ThatMeans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das bedeutet"])},
    "diagramDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In dem Diagramm auf Ihrem Dashboard siehst du die Ergebnisse deiner OMindset Evaluation. Hier kannst du sehen, wo deine Stärken und Entwicklungsbereiche liegen."])},
    "diagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die jüngste Forschung in diesem Bereich (Engelsberger et al. 2020) hat die folgenden 6 Dimensionen aufgedeckt, die eine Innovationsmentalität ausmachen:"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offenheit (Bist du offen für Veränderungen und bereit neue Wege zu gehen?)"])},
    "creativ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreativität (Schaust du auch mal über den Tellerrand hinaus und denkst unkonventionell?)"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Risiko- und Fehlertoleranz (Bist du bereit einer vielversprechenden Idee eine Chance zu geben und das Risiko einzugehen, dass dies auch scheitern könnte? Und kannst du dir gemachte Fehler schnell verzeihen und daraus lernen?)"])},
    "toSharing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haltung zu “Knowledge Sharing” (Teilst du gerne aktiv dein Wissen und Erfahrungen mit anderen?)​"])},
    "toSourcing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haltung zu “Knowledge Sourcing” (Gehst du gerne aktiv auf deine Kolleg:innen zu und fragst nach Unterstützung, wenn du etwas selbst nicht weißt oder einfach gerne eine weitere Perspektive hättest?)​"])},
    "ic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empathie (Versetzt du dich aktiv in die Perspektive von anderen und versuchst andere Sichtweisen zu berücksichtigen?)​"])},
    "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind deine Ergebnisse so wie erwartet und wie du dich auch selbst eingeschätzt hast?​"])},
    "results2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oder warst du etwas überrascht und hättest deine Ergebnisse anders erwartet?​"])},
    "resultParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzt du diese neugewonnene Erkenntnis über deine Stärken und Entwicklungsfelder zur Selbstreflektion. Es gibt hier kein richtig oder falsch. Wenn du auf den ersten Blick schon sehr hohe Ergebnisse erzielt hast, achte nochmal gezielt auf die Felder, in denen du nicht ganz so hoch abgeschnitten hast wie in den anderen. Es gibt für jeden noch etwas Entwicklungspotenzial und eine ständige Weiterentwicklung auf diesen Feldern fördert langfristig dein Kollaborations- und Innovationsverhalten."])},
    "basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Siehst du dies als eine Grundlage sich im Arbeitsalltag anhand dieser 6 Dimensionen zu reflektieren und fragst du dich im Alltag, wo du ab und zu mal etwas neues probieren könntest, dein Wissen aktiv mit deinen Kolleg:innen teilen könntest, oder auch dich öfters mal in die Perspektive deines Gegenübers versetzen solltest."])},
    "reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerst du dich im Alltag an deine Entwicklungsfelder und versuchst bewusst darauf zu achten, offener zu werden. Sei gespannt, wie sich deine Offenheit gegenüber Kollaboration und Innovation über die Zeit entwickelt. "])},
    "HugoBoss": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUGO BOSS Werte"])},
      "trust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRUST ist der übergreifende Wert von HUGO BOSS. Hier geht es vor allem um Glaubwürdigkeit (Kommunikation, Kompetenz, Integrität), Respekt (Unterstützung, Kollaboration, Fürsorge) und Fairness (Gerechtigkeit, Unvoreingenommenheit, Gleichheit).<br><br>Vertrauen in deinem Team ist unentbehrlich, damit du auf deine Kolleg:innen zählen kannst, sie deine Interessen berücksichtigen und dich auf dem Laufenden halten. In einem Team ist es wichtig, dass man sich gegenseitig aufeinander verlassen kann und das gegebene Wort etwas zählt."])},
      "eSpirit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTREPRENEURIAL SPIRIT: Ein Entrepreneur im Unternehmen nennt man einen Intrapreneur. Ein Intrapreneur ist ein:e Mitarbeiter:in, der/die unternehmerisch denkt, mutig ist und Innovationen vorantreibt, indem er/sie neue Ideen, Produkte oder Prozesse entwickelt und umsetzt, die mit den Zielen des Unternehmens übereinstimmen. Wichtig dafür ist eine Denkweise bei der Herausforderungen als Chancen betrachtet werden, um dadurch innovative Lösungen zu entwickeln. Es ist eine Fähigkeit, die Potenziale der Veränderung zu erkennen und proaktiv nach neuen Geschäftschancen oder Möglichkeiten zur Verbesserung bestehender Prozesse zu suchen, die nachhaltiges Wachstum der Organisation fördern."])},
      "personalOwnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSONAL OWNERSHIP: Hier geht es darum Verantwortung zu übernehmen, risikofreudig zu sein und ständig nach neuen innovativen Lösungen zu suchen. Dabei ist es wichtig Fehler als Chance zu sehen, sich kontinuierlich und vor allem schnell weiterzuentwickeln. Erfolge wollen gefeiert werden - auch die kleinen. Es geht darum, eine Atmosphäre der Akzeptanz, Toleranz und Offenheit zu schaffen, in der alle Menschen respektiert werden und andere Perspektiven und Erfahrungen wertgeschätzt werden. Eine inklusive Umgebung fördert die Zusammenarbeit, Innovation und Kreativität, da verschiedene Sichtweisen und Erfahrungen dazu beitragen können, dass bessere Entscheidungen getroffen und eine breitere Palette von Lösungen gefunden werden kann."])},
      "teamMentality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEAM MENTALITY: Gemeinsam sind wir stärker! Um unsere unterschiedlichen Fähigkeiten bestmöglich zu nutzen, ist es wichtig Wissen und Erfahrungen mit Kolleg:innen zu teilen und aktiv zu erfragen. Dabei gilt es, sich gegenseitig zu respektieren, inspirieren und befähigen und mit Mut als Vorbild voranzugehen. Eine Grundvoraussetzung für eine erfolgreiche Teammentalität ist es, sich in die Lage anderer zu versetzen und zu versuchen, verschiedene Sichtweisen zu verstehen und daraus zu lernen. Es ist sehr wertvoll, globale Netzwerke aufzubauen, um von den unterschiedlichen Denkweisen zu profitieren. Gemeinsam kann man etwas so Starkes schaffen, das alleine niemals möglich ist. "])},
      "youthfulSpirit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YOUTHFUL SPIRIT: Offen gegenüber Neuem zu sein und Veränderungen nicht zu scheuen, sondern diesen positiv und neugierig zu begegnen. Wandel ist eine große Chance zu lernen und zu wachsen. Es ist wichtig, das Kind in einem selbst nicht zu verlieren, immer wissbegierig zu bleiben, leidenschaftlich dabei zu sein und Spaß zu haben. Die eigene Kreativität und Engagement sind Grundsteine, um über den eigenen Tellerrand hinausschauen zu können."])}
    },
    "KXDatabase": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anreiz- und Belohnungssystem"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt verschiedene Entwicklungsstufen auf deiner OMindset Development Journey. Die nächste Stufe kann durch aktive Plattformnutzung und die Erreichung der Wochenziele freigeschaltet werden. Die Wochenziele findest du oben auf dem Startbildschirm. Sie zeigen dir deinen aktuellen Status an. Der Statuszähler wird jeden Sonntagabend resettet. Wird eine Woche versäumt, kann das Wochenziel in der nächsten Woche nachgeholt werden."])},
      "requirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die folgenden Anforderungen müssen innerhalb einer Woche erzielt werden, damit diese Woche für die Wertung der Levelereichung zählt:<ul><li>Erstellen von 2 Kollaborationstrackern mit der KSS Creator Funktion für mindestens 2 Teilnehmer.</li><li>Hinweis: Wiederkehrende Ereignisse zählen nur in der Woche der Erstellung.</li><li>3 Kollaborationstracker pro Woche ausfüllen.</li><li>1 Stimmungsbarometer pro Woche ausfüllen.</li></ul>"])},
      "levelTwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um in das zweite Level aufsteigen zu können:<ul><li>sind insgesamt 4 vollständig erreichte Wochenziele erforderlich. Dabei ist es nicht notwendig, dass diese Wochen aufeinanderfolgend sind.</li><li>Die Trust Evaluation ist notwendig, um das nächste Level zu erreichen. Diese muss nur einmal zu Beginn und einmal am Ende des ersten Jahres durchgeführt werden.</li></ul>"])},
      "levelThree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für Level 3 gilt:<ul><li>Zusätzlich zur vorherigen Stufe: Insgesamt 12 vollständige Wochenziele erforderlich</li></ul>"])},
      "levelFour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für Level 4 gilt:<ul><li>Zusätzlich zur vorherigen Stufe: Insgesamt 16 vollständige Wochenziele erforderlich</li></ul>"])}
    }
  },
  "AssessmentExpiredComponent": {
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Evaluation ist abgelaufen!"])},
    "backHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Startseite"])}
  },
  "TaskCreatorOverlayComponent": {
    "alreadyRequested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindset 360° bereits in den letzten 6 Monaten angefragt"])},
    "createEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Ereignis erstellen"])},
    "chooseEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle:"])},
    "kssTracker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollaborationstracker"])},
    "mindset360": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["360° OMindset"])},
    "personalGoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persönliches Ziel"])},
    "personalGoalsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Anzahl der erreichten aktiven Ziele"])},
    "workshop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workshop"])}
  },
  "LegalNoticeComponent": {
    "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben gemäß § 5 TMG"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktinformationen"])},
    "internet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internetadresse: https://www.omindplatform.com"])},
    "graphics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafiken und Bildquellen"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haftungsausschluss"])},
    "accountability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwortlichkeit für den Inhalt"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Gemäß den gesetzlichen Bestimmungen sind wir darüber hinaus für eigene Inhalte auf diesen Seiten verantwortlich. Wir weisen in diesem Zusammenhang darauf hin, dass wir nicht verpflichtet sind, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben nach den §§ 8 bis 10 TMG hiervon unberührt."])},
    "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwortlichkeit für Links"])},
    "responsibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für den Inhalt externer Links (zu Webseiten Dritter) sind ausschließlich deren Betreiber verantwortlich. Zum Zeitpunkt der Verlinkung waren für uns keine Rechtsverstöße ersichtlich. Bei Bekanntwerden von Rechtsverstößen werden wir den entsprechenden Link umgehend entfernen."])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright"])},
    "web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsere Webseiten und deren Inhalte unterliegen dem deutschen Urheberrecht. Jede Form der Verwertung, Vervielfältigung oder Bearbeitung von urheberrechtlich geschützten Werken auf unseren Webseiten bedarf, soweit nicht ausdrücklich gesetzlich erlaubt, der vorherigen Zustimmung des jeweiligen Rechteinhabers. Einzelne Vervielfältigungen eines Werkes sind nur für den privaten Gebrauch gestattet. Die Materialien auf diesen Seiten sind urheberrechtlich geschützt und jede unerlaubte Nutzung kann gegen das Urheberrecht verstoßen."])}
  },
  "PrivacyComponent": {
    "txt_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzrichtlinie für die OMIND platform GmbH"])},
    "txt_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf der OMIND platform GmbH, die über www.omindplatform.com zugänglich ist, ist eine unserer wichtigsten Prioritäten der Schutz der Privatsphäre unserer Besucher. Diese Datenschutzrichtlinie enthält die Arten von Informationen, die von der OMIND platform GmbH gesammelt und aufgezeichnet werden, und wie wir sie verwenden."])},
    "txt_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du weitere Fragen hast oder mehr Informationen über unsere Datenschutzpolitik benötigst, zöger nicht, uns zu kontaktieren."])},
    "txt_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Datenschutzrichtlinie gilt nur für unsere Online-Aktivitäten und für die Besucher unserer Website in Bezug auf die Informationen, die sie auf der OMIND platform GmbH teilen und/oder sammeln. Diese Richtlinie gilt nicht für Informationen, die offline oder über andere Kanäle als diese Website gesammelt werden."])},
    "txt_headline2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einwilligung"])},
    "txt_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch die Nutzung unserer Website erklärst du dich mit unserer Datenschutzrichtlinie einverstanden und stimmst deren Bedingungen zu."])},
    "txt_headline3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfasste Informationen"])},
    "txt_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die personenbezogenen Daten, um die du gebeten wirst, und die Gründe, warum du um diese Daten gebeten wirst, werden dir an dem Punkt mitgeteilt, an dem wir dich um die Angabe deiner personenbezogenen Daten bitten. <br> Wenn du dich direkt mit uns in Verbindung setzt, können wir zusätzliche Informationen über dich einholen, z. B. deinen Namen, deine Email-Adresse, deine Telefonnummer, den Inhalt der Nachricht und/oder der Anhänge, die du uns schickst, und andere Informationen, die du uns zur Verfügung stellen möchtest."])},
    "txt_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du dich für ein Konto registrierst, fragen wir dich möglicherweise nach deinen Kontaktinformationen, z.B. nach Name, Firmenname, Adresse, Email-Adresse und Telefonnummer."])},
    "txt_headline4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie wir deine Informationen verwenden"])},
    "txt_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir verwenden die von uns gesammelten Informationen auf verschiedene Weise, unter anderem um:"])},
    "txt_datenVerwendung": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereitstellung, Betrieb und Pflege unserer Website"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbesserung, Personalisierung und Erweiterung unserer Website"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstehen und Analysieren, wie Du unsere Website nutzt"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwicklung neuer Produkte, Dienstleistungen, Merkmale und Funktionen"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit dir zu kommunizieren, entweder direkt oder über einen unserer Partner, u. a. für den Kundendienst, um dir Aktualisierungen und andere Informationen über die Website zukommen zu lassen, sowie für Marketing- und Werbezwecke"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Versenden von Emails"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufdeckung und Verhinderung von Betrug"])}
    ],
    "txt_logFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protokolldateien"])},
    "txt_p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die OMIND platform GmbH verwendet standardmäßig Protokolldateien. Diese Dateien protokollieren die Besuche von Besuchern auf Websites. Alle Hosting-Firmen tun dies als Teil der Analytik der Hosting-Dienste. Zu den in den Protokolldateien gesammelten Informationen gehören Internetprotokolladressen (IP), Browsertyp, Internetdienstanbieter (ISP), Datums- und Zeitstempel, verweisende und verlassende Seiten und möglicherweise die Anzahl der Klicks. Diese Daten werden nicht mit Informationen verknüpft, die eine persönliche Identifizierung ermöglichen. Der Zweck dieser Informationen ist die Analyse von Trends, die Verwaltung der Website, die Verfolgung der Bewegungen der Benutzer auf der Website und die Erfassung demografischer Informationen. Unsere Datenschutzrichtlinie wurde mit Hilfe des Online Privacy Policy Generator erstellt."])},
    "txt_advertising": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werbepartner Datenschutzrichtlinien"])},
    "txt_p9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst diese Liste konsultieren, um die Datenschutzbestimmungen für jeden der Werbepartner der OMIND platform GmbH zu finden."])},
    "txt_p10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werbeserver oder Werbenetzwerke von Drittanbietern verwenden Technologien wie Cookies, JavaScript oder Web Beacons, die in ihren jeweiligen Werbeanzeigen und Links, die auf OMIND platform GmbH erscheinen, verwendet werden und direkt an den Browser der Nutzer gesendet werden. Du erhälst automatisch deine IP-Adresse, wenn dies geschieht. Diese Technologien werden verwendet, um die Effektivität ihrer Werbekampagnen zu messen und/oder um die Werbeinhalte, die du auf den von deinen besuchten Websites siehst, zu personalisieren."])},
    "txt_p11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beachte, dass die OMIND platform GmbH keinen Zugang zu oder Kontrolle über diese Cookies hat, die von Drittanbietern verwendet werden."])},
    "txt_thirdParty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzrichtlinien von Dritten"])},
    "txt_p12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Datenschutzrichtlinie der OMIND platform GmbH gilt nicht für andere Werbetreibende oder Websites. Wir raten dir daher, die jeweiligen Datenschutzrichtlinien dieser Werbeserver Dritter zu konsultieren, um genauere Informationen zu erhalten. Diese können deren Praktiken und Anweisungen darüber enthalten, wie Sie bestimmte Optionen deaktivieren können."])},
    "txt_p13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst Cookies über die Optionen deines Browsers deaktivieren. Ausführlichere Informationen zur Cookie-Verwaltung mit bestimmten Webbrowsern findest du auf den jeweiligen Websites der Browser."])},
    "txt_ccpa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CCPA-Datenschutzrechte (Verkaufe meine persönlichen Daten nicht)"])},
    "txt_p14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach dem CCPA haben die kalifornischen Verbraucher unter anderem das Recht auf:"])},
    "txt_listCCPA": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlangen, dass ein Unternehmen, das personenbezogene Daten eines Verbrauchers sammelt, die Kategorien und spezifischen Teile der personenbezogenen Daten offenlegt, die das Unternehmen über den Verbraucher gesammelt hat."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufforderung an ein Unternehmen, personenbezogene Daten über den Verbraucher zu löschen, die ein Unternehmen gesammelt hat"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verlangen, dass ein Unternehmen, das die persönlichen Daten eines Verbrauchers verkauft, die persönlichen Daten des Verbrauchers nicht verkauft."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du eine Anfrage stellst, haben wir einen Monat Zeit, dir zu antworten. Wenn du eines dieser Rechte ausüben möchten, nehme bitte Kontakt mit uns auf."])}
    ],
    "headline_gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR Datenschutzrechte"])},
    "txt_p15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir möchten sicherstellen, dass du über alle deine Datenschutzrechte informiert bist. Jeder Nutzer hat Anspruch auf die folgenden Rechte:"])},
    "gdpr_list": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Recht auf Zugang - Du haben das Recht, Kopien deiner personenbezogenen Daten anzufordern. Für diesen Service können wir dir eine geringe Gebühr in Rechnung stellen."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Recht auf Berichtigung - Du hast das Recht, von uns die Berichtigung von Informationen zu verlangen, die du für unrichtig hälst. Du hast auch das Recht zu verlangen, dass wir die Informationen vervollständigen, die du für unvollständig hälst."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Recht auf Löschung - Du hast das Recht, unter bestimmten Bedingungen die Löschung deiner personenbezogenen Daten zu verlangen."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recht auf Einschränkung der Verarbeitung - Du hast das Recht zu verlangen, dass wir die Verarbeitung deiner personenbezogenen Daten unter bestimmten Bedingungen einschränken."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recht auf Widerspruch gegen die Verarbeitung - Du hast das Recht, der Verarbeitung deiner personenbezogenen Daten durch uns unter bestimmten Bedingungen zu widersprechen."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Recht auf Datenübertragbarkeit - Du hast das Recht, unter bestimmten Bedingungen zu verlangen, dass wir die von uns erfassten Daten an eine andere Organisation oder direkt an dich weitergeben."])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn du eine Anfrage stellst, haben wir einen Monat Zeit, dir zu antworten. Wenn du eines dieser Rechte ausüben möchtest, nehme bitte Kontakt mit uns auf."])}
    ],
    "headnline_childInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen für Kinder"])},
    "txt_p16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein weiterer Teil unserer Priorität ist der Schutz von Kindern bei der Nutzung des Internets. Wir ermutigen Eltern und Erziehungsberechtigte, ihre Online-Aktivitäten zu beobachten, daran teilzunehmen und/oder sie zu überwachen und anzuleiten."])},
    "txt_p17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die OMIND platform GmbH sammelt wissentlich keine personenbezogenen Daten von Kindern unter 13 Jahren. Wenn du glaubst, dass dein Kind diese Art von Informationen auf unserer Website angegeben hat, bitten wir dich dringend, uns sofort zu kontaktieren, und wir werden unser Bestes tun, um solche Informationen umgehend aus unseren Unterlagen zu entfernen."])}
  },
  "ToastComponent": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschafft"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnung"])},
    "kss": {
      "selectDateErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle zuerst ein Datum"])},
      "selectTimeErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle zuerst eine Uhrzeit"])},
      "selectSubjectErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle zuerst einen Titel"])},
      "selectParticipantsErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte füge zuerst die Teilnehmer hinzu"])},
      "kssCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollaborationstracker erstellt"])},
      "kssUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kollaborationstracker aktualisiert"])},
      "selectRecurringDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle mindestens einen Tag für die Serie aus."])},
      "pastDateErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das ausgewählte Datum liegt mehr als 60 Minuten in der Vergangenheit."])}
    },
    "mindset360": {
      "mindset360Created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindset 360° erstellt"])},
      "mindset360UserCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle 7 bis 10 Benutzer"])}
    },
    "questionnaire": {
      "assessmentSubmitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation übermittelt"])},
      "answerSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort gespeichert"])},
      "noInputErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle eine Antwort aus"])}
    },
    "teamManagement": {
      "teamUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team aktualisiert"])},
      "teamCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team erstellt"])}
    },
    "workshop": {
      "workshopCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workshop erfolgreich erstellt"])},
      "workshopDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workshop erfolgreich gelöscht"])},
      "workshopSendReflection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reflexionsaufgabe erfolgreich gesendet"])},
      "workshopSendReflectionError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler beim Erstellen der Reflexionsaufgabe"])},
      "workshopUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workshop erfolgreich aktualisiert"])},
      "workshopDeletedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Workshop konnte nicht gelöscht werden"])},
      "workshopDeletedConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du sicher, dass du diesen Workshop löschen möchtest?"])},
      "selectDateErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Datum hat nicht das richtige Format"])}
    }
  },
  "VeeValidations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist ein Pflichtfeld"])},
    "min": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Mindestlänge sollte sein: ", _interpolate(_named("length"))])},
    "max": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die maximale Länge sollte sein: ", _interpolate(_named("length"))])},
    "alphaSpaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Feld darf nur Buchstaben enthalten"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Feld muss eine gültige Emailadresse enthalten"])},
    "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Felder stimmen nicht mit deinen Werten überein"])},
    "is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte akzeptiere die Bedingungen um fortzufahren"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Eingabeformat. Die angegebene Handynummer muss mit einer Ländervorwahl (z.B. +49) beginnen und darf keine Leerzeichen enthalten."])},
    "dependent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für den Erhalt von SMS wird eine gültige Handynummer benötigt."])}
  },
  "OimIntroComponent": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schön, dass du bereit bist, an deiner offenen Haltung für Innovation & Kollaboration zu arbeiten!"])},
    "introContent1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur wenn du dich als Mitarbeiter:in mit anderen austauschst und zusammenarbeitest, können neue Ideen entstehen! Und das nicht nur innerhalb des Unternehmens, sondern auch mit externen Partnern wie beispielsweise Kunden, Lieferanten, Start-Ups oder auch Wettbewerbern. Das heißt nicht, dass man all seine Geheimnisse teilen muss. Sondern Erfahrungen austauschen, um Lösungen für gemeinsame Probleme zu finden."])},
    "introContent2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur gemeinsam sind wir stärker! Und das beginnt in unseren Köpfen… mit unserer Haltung und Einstellung… unserem MINDSET!"])},
    "introContent3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Selbstevaluierung, deren Ergebnisse nur du selbst von dir sehen kannst, wird dir helfen dich zu reflektieren, wie offen du eigentlich wirklich bist.<br> Die Ergebnisse werden dir einen guten Überblick geben, wo deine Stärken und deine Entwicklungsfelder liegen. Und danach bist du dran: Reflektiere deine Entwicklungsfelder im Alltag und versuche diese zu stärken! Viel Spaß bei der Evaluation!"])},
    "letsStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los geht's"])},
    "howItWorks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktionsweise"])},
    "howItWorks1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Während der Evaluation werden dir verschiedene Aussagen gezeigt."])},
    "howItWorks2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte nehme dir genügend Zeit, um über deine Antworten nachzudenken und so ehrlich wie möglich zu antworten."])},
    "howItWorks3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst diese beantworten, indem du den Schieberegler mit der Maus zu der gewünschten Position schiebst von: "])},
    "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ich stimme überhaupt nicht zu"])},
    "howItWorks3a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" bis "])},
    "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ich stimme voll und ganz zu)."])},
    "howItWorks4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niemand außer dir selbst wird deine persönlichen Ergebnisse sehen. Die Ergebnisse werden durch Aggregation auf Team/Gruppen-Ebene anonymisiert und sind somit nicht auf dich zurückzuführen."])},
    "howItWorks5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umso ehrlicher du mit dir selbst bist, desto präziser werden deine Ergebnisse, deine Profilbeschreibung und die Entwicklungstipps sein."])},
    "definitionHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Open Innovation Mindset"])},
    "definition1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["... ist definiert als"])},
    "definition2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 'die Werte, Einstellungen und Überzeugungen eines Individuums, die Offenheit gegenüber dem Geben und Nehmen von Wissen reflektieren und somit den tatsächlichen Wissensaustausch beeinflussen. Insbesondere bezieht sich dies auf den wechselseitigen Wissensaustausch sowohl innerhalb als auch außerhalb der unternehmerischen Grenzen.'"])}
  },
  "kss_guideline_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Kollaborationstracker wird über Outlook verwaltet, daher können Änderungen nur über Outlook vorgenommen werden. \nEinzige Ausnahme: Das Löschen des Kollaborationstracker ist nur auf der Plattform möglich."])},
  "HomeScreenPrompt": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Verknüpfung auf Startbildschirm hinzufügen?\n"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verknüpfung hinzufügen"])},
    "iphone": {
      "andThenTap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["und dann auf \"Zum Startbildschirm hinzufügen\"."])},
      "installTheWebapp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installiere die Webapp auf deinem Mobiltelefon: Tippe auf das"])}
    }
  },
  "PauseNotificationComponent": {
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "toast": {
      "successfulCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Datensatz wurde erstellt"])},
      "errorCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Datensatz konnte nicht erstellt werden"])},
      "requiredStartTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle zuerst die Startzeit aus"])},
      "requiredEndTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle zuerst die Endzeit aus"])},
      "requiredStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle zuerst das Startdatum aus"])},
      "requiredEndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle zuerst das Enddatum aus"])},
      "invalidEndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Enddatum ist ungültig"])},
      "invalidStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Startdatum ist ungültig"])},
      "startDateAfterToday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Startdatum muss nach dem heutigen Tag liegen"])},
      "startDateBeforeEndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Startdatum muss vor dem Enddatum liegen"])},
      "successfulDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Datensatz wurde gelöscht"])},
      "errorDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Datensatz konnte nicht gelöscht werden"])},
      "successfulUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Datensatz wurde aktualisiert"])},
      "errorUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Datensatz konnte nicht aktualisiert werden"])}
    },
    "form": {
      "Weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wöchentlich"])},
      "OneTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einmal"])},
      "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wöchentlich"])},
      "oneTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einmal"])},
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
      "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endtermin"])},
      "startTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startzeit"])},
      "endTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endzeit"])}
    },
    "createPauseNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle Benachrichtigungspausen"])},
    "popup": {
      "deleteMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du sicher, dass du diesen Datensatz löschen möchten?"])}
    }
  },
  "WorkshopTagsSelectionComponent": {
    "placeholder": {
      "searchInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche Tags"])},
      "noTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Tags"])}
    }
  },
  "WorkshopComponent": {
    "noWorkshopHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du hast keinen Workshop"])},
    "newWorkshopButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Workshop"])},
    "Facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderator:in"])},
    "SendReflection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reflexion senden"])},
    "SendReflectionToAllParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reflexion an alle Teilnehmenden senden"])},
    "YouNeedToChooseAtLeastOneParticipant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle mindestens eine:n Teilnehmer:in aus"])},
    "noWorkshopText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestaltung ansprechender Workshops zur Förderung des persönlichen Wachstums und der Kompetenzentwicklung"])},
    "dataUpdating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Daten werden aktualisiert. \nDies dauert einen kurzen Moment. \nBitte versuche es noch einmal"])}
  },
  "WorkshopFormComponent": {
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung (optional)"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
    "projectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektname (optional)"])},
    "beginDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
    "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende"])},
    "addParticipants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilnehmende hinzufügen"])},
    "addTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags hinzufügen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
    "theBeginDateMustBeEarlier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Anfangsdatum muss vor dem Enddatum liegen"])},
    "titleRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle zunächst einen Titel aus"])},
    "addFacilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderator:in hinzufügen"])},
    "addProjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektnamen hinzufügen"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
    "selectParticipantsErr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte füge zuerst die Teilnehmer hinzu"])},
    "selectTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wähle zunächst einen Tag aus"])}
  },
  "FacilitatorsSelectionComponent": {
    "placeholder": {
      "searchInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche Moderator:in"])},
      "noFacilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein:e Moderator:in"])}
    }
  },
  "ProjectNameSelectionComponent": {
    "placeholder": {
      "searchInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektnamen suchen"])},
      "noProjectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Projektname"])}
    }
  }
}