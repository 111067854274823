import NetworkAuth from '@/helpers/networking/NetworkAuth';
import store from '@/store';
import keycloak from '../../../keycloak';

// eslint-disable-next-line import/prefer-default-export
export async function checkIfUserLoggedIn(to, from, next) {
  try {
    if (process.env.VUE_APP_KEYCLOAK === 'true') {
      const isAuthRequired = to.meta.requiresAuth;
      if (isAuthRequired && !keycloak.authenticated) {
        // User is not authenticated, redirect to sso login page or handle as desired
        next('/auth');
      } else {
        // Proceed to the next route
        next();
      }
    } else {
      const config = {
        headers: {
          Authorization: store.state.auth.token,
        },
      };
      const { data } = await NetworkAuth.checkIfUserLoggedIn({}, config);
      store.commit('updatePermissionFlags', data.permissionFlags);
      next();
    }
  } catch (error) {
    next('/auth');
  }
}
