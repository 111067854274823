<!-- HomeScreenPrompt.vue -->
<template>
    <div v-if="showPrompt && showPromptMobileIOS === false" class="home-screen-prompt">
      <div>
        <p class="text">{{ $t('HomeScreenPrompt.text') }}</p>
        <button class="add-button" @click="addToHomeScreen">
          {{ $t('HomeScreenPrompt.button') }}
        </button>
      </div>
      <button class="close-button" @click="onDeclinePrompt">
        <font-awesome-icon icon="fa-xmark"/>
      </button>
    </div>
    <div v-if="showPromptMobileIOS" class="home-screen-prompt-mobile-ios">
      <div class="plus">
        <font-awesome-icon icon="fa-plus"/>
      </div>
      <p class="text">
        {{ $t('HomeScreenPrompt.iphone.installTheWebapp') }}
        <img class="platform-logo" src="@/assets/share-icon-iso.png" alt="txt">
        {{ $t('HomeScreenPrompt.iphone.andThenTap') }}
      </p>
    </div>
  </template>

<script>
export default {
  data() {
    return {
      showPrompt: false,
      showPromptMobileIOS: false,
      deferredPrompt: null,
    };
  },
  computed: {
    promptCount: {
      get() {
        return Number(localStorage.getItem('promptCount')) ?? 0;
      },
      set(value) {
        localStorage.setItem('promptCount', value);
      },
    },
    consecutiveDeclines: {
      get() {
        return Number(localStorage.getItem('consecutiveDeclines')) ?? 0;
      },
      set(value) {
        localStorage.setItem('consecutiveDeclines', value);
      },
    },
  },
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
    isMobileIOS() {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    },
    isNotInAppMode() {
      return !window.matchMedia('(display-mode: standalone)').matches;
    },
    addToHomeScreen() {
      if (this.deferredPrompt) {
        // Show the browser's installation prompt
        this.deferredPrompt.prompt();

        // Wait for the user's choice
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            // accepted
          } else {
            // no accepted
          }
          this.showPrompt = false;
          this.deferredPrompt = null;
        });
      }
    },
    onShowPrompt() {
      this.promptCount += 1;
      if (this.consecutiveDeclines <= 3
      && (this.promptCount % 10 === 0 || this.promptCount === 0)) this.showPrompt = true;
    },
    onDeclinePrompt() {
      this.showPrompt = false;
      this.consecutiveDeclines += 1;
    },
    onShowPromptMobileIOS() {
      this.promptCount += 1;
      if (this.consecutiveDeclines <= 3
      && (this.promptCount % 10 === 0 || this.promptCount === 0)) this.showPromptMobileIOS = true;
    },
  },
  created() {
    if (this.isNotInAppMode() && this.isMobile()) {
      // Listen for the beforeinstallprompt event
      if (this.isMobileIOS() && this.isSafari()) {
        this.onShowPromptMobileIOS();
        setTimeout(() => {
          this.showPromptMobileIOS = false;
        }, 3000);
      }
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault(); // Prevent the default browser prompt
        this.deferredPrompt = e;
        this.onShowPrompt();
      });
    }
  },
};
</script>

  <style scoped>
  .home-screen-prompt {
    background-color: #ffffff;
    padding: 10px;
    border: 1px solid #ccc;
    text-align: center;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    width: 100%;
  }
  .home-screen-prompt-mobile-ios {
    border-radius: 8px;
    background-color: #ffffff;
    padding: 10px;
    position: fixed;
    bottom: 40px;
    z-index: 1000;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
  }
  .home-screen-prompt-mobile-ios:before {
    background-color: #ffffff;
    width: 50px;
    height: 50px;
    content: '';
    position: absolute;
    z-index: -1;
    bottom: -15px;
    transform: rotate(45deg);
  }
  .home-screen-prompt-mobile-ios .plus {
    background: #8e8e8e;
    padding: 2px 7px;
    border-radius: 4px;
  }
  .home-screen-prompt-mobile-ios .plus svg {
    color: var(--color-white);
  }
  .home-screen-prompt-mobile-ios .text {
    width: 375px;
    font-size: 12px;
  }
  .home-screen-prompt-mobile-ios .text img {
    height: 20px;
    padding: 0 2px;
  }

  .home-screen-prompt .add-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 10px 35px;
    border: none;
    border-radius: 50px;
    font-size: 20px;
    color: var(--color-white);
    background-color: var(--color-dark);
    cursor: pointer;
  }
  .home-screen-prompt .text {
    color: var(--color-orange);
  }
  @media (max-width: 400px) {
    .home-screen-prompt .text {
      width: 340px
    }
  }
  .home-screen-prompt .close-button {
    font-family: 'Poppins';
    position: absolute;
    top: 5px;
    right: 15px;
    background: none;
    border: none;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }
  </style>
