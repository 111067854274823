<template>
  <div class="main-not-found-ontainer" id="not-found">
    <div class="not-found-container">
      <h1>{{ $t('NotFoundComponent.notFound') }}</h1>
      <i>
        <font-awesome-icon icon="fa-solid fa-ban" class="icon-size"/>
      </i>
      <p>{{ $t('NotFoundComponent.notFound') }}</p>
      <button @click.prevent="navigateToHome" type="button" name="submit"
              id="btn_submit" class="btn-submit">
        {{ $t('NotFoundComponent.backToHome') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundComponent',
  methods: {
    navigateToHome() {
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>
/*------------------ CONTAINER -------------------*/
.main-not-found-ontainer {
  margin: 0 0;
}

.icon-size {
  font-size: 250px;
  color: var(--color-error);
}

.not-found-container {
  display: block;
  position: relative;
  max-width: 500px;
  width: 100%;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  padding: 30px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 10px;
  background: var(--color-milk);
}

.not-found-container h1 {
  margin: 5rem 0 2rem;
}

.not-found-container .fa-circle-check {
  font-size: 7rem;
  color: var(--color-orange);
  margin: 3rem 0;
}

.back {
  cursor: pointer;
  text-align: left;
  margin: -1rem;
  font-weight: 600;
}

.back:hover {
  color: var(--color-fulvous);
}

.back i {
  padding: 0 .5rem;
}

/*------------------ BUTTONS ------------------*/
button[type=button] {
  padding: 10px 35px;
  margin: 5rem 0 1rem;
  font-size: 20px;
  width: 190px;
  height: 50px;
  color: var(--color-white);
  border: none;
  border-radius: 50px;
  background-color: var(--color-orange);
  cursor: pointer;
}

button[type=button]:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

/*==================== MEDIA QUERIES =====================*/
@media (max-width: 520px) {
  .not-found-container {
    width: 90vw;
  }

  .not-found-container h1 {
    margin: 3rem 0 1rem;
  }

  .not-found-container .fa-circle-check {
    font-size: 7rem;
    color: var(--color-orange);
    margin: 1rem 0;
  }
}

@media (min-width: 521px) and (max-width: 768px) {
  .not-found-container {
    width: 85vw;
  }
}
</style>
