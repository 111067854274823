<template>
  <breadcrumbs-component :bread-crumbs="personalGoalsBreadcrumb"/>
  <div class="personal-goals-container">
    <div class="wrapper">
      <div class="box">
        <div class="switch-container">
          {{ $t('PersonalGoalsComponent.showCompletedGoals') }}
          <label class="switch">
            <input type="checkbox" :checked=this.showCompletedGoals
                   @input="$emit('input', $event.target.checked)" @change="onToggleCompletedTasks">
            <span class="slider round"></span>
          </label>
        </div>
        <div v-if="personalGoals.length > 0">
          <goal-component v-for="goal in filterActiveGoals" :key="goal._id" :goal="goal"/>
          <div v-if="showCompletedGoals">
            <goal-component v-for="goal in filterCompletedGoals" :key="goal._id" :goal="goal"/>
          </div>
        </div>
        <div class="no-goals" v-if="!showCompletedGoals && !filterActiveGoals.length
        || !personalGoals.length">
          <no-personal-goals-component></no-personal-goals-component>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NoPersonalGoalsComponent
  from '@/components/Home/PersonalGoalsComponent/NoPersonalGoalsComponent.vue';
import GoalComponent from '@/components/Home/HomeComponent/GoalComponent.vue';
import BreadcrumbsComponent from '@/components/Home/Shared/BreadcrumbsComponent.vue';

export default {
  name: 'PersonalGoalsFormComponent',
  components: {
    BreadcrumbsComponent,
    NoPersonalGoalsComponent,
    GoalComponent,
  },
  data() {
    return {
      showCompletedGoals: false,
      personalGoalsBreadcrumb: [
        {
          goTo: '/',
          isActive: false,
          name: 'HOME',
        },
        {
          goTo: '/personal-goals',
          isActive: true,
          name: this.$t('BreadCrumbs.personalGoals'),
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch('getPersonalGoals');
  },
  computed: {
    personalGoals() {
      const personalGoalsArray = this.$store.getters.getPersonalGoals;
      if (!personalGoalsArray || personalGoalsArray.length === 0) {
        return false;
      }
      return personalGoalsArray;
    },
    filterActiveGoals() {
      const personalGoalsArray = this.$store.getters.getPersonalGoals;
      const activeGoals = personalGoalsArray.filter((goal) => goal.status === false);
      this.$store.commit('setActiveGoalsCount', activeGoals.length);
      return activeGoals;
    },
    filterCompletedGoals() {
      const personalGoalsArray = this.$store.getters.getPersonalGoals;
      const completedGoals = personalGoalsArray.filter((goal) => goal.status === true);
      if (!completedGoals.length) {
        return false;
      }
      return completedGoals;
    },
  },
  methods: {
    onToggleCompletedTasks() {
      this.showCompletedGoals = !this.showCompletedGoals;
      this.$emit('input', this.showCompletedGoals);
    },
  },
};

</script>
<style scoped>
.personal-goals-container {
  margin: 0 auto;
  position: relative;
}

.wrapper {
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 10px;
  width: 100vw;
  max-width: 960px;
  margin: 0 auto;
}

.box {
  position: relative;
}

.no-goals {
  display: flex;
  margin-top: 33px;
  justify-content: center;
  width: 100%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 4px;
  bottom: 4px;
  background-color: var(--color-white);
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--color-orange);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--color-orange);
}

input:checked + .slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media  (max-width: 1024px) {
  .box {
    margin: 0 10px;
  }
}
</style>
