<template>
    <canvas id="oMindSetCompanyChart" width="100%" height="100%"></canvas>
    <div class="chart-no-data" v-if="!hasData">
      {{ $t('mngDashboardComponent.notEnoughDataToDisplay') }}
    </div>
  </template>
<script>
import { tooltipTeamPlotHelper } from '@/helpers/graphPlotHelper';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';

export default {
  name: 'OMindSetChartComponent',
  data() {
    return {
      hasData: false,
    };
  },
  methods: {
    OMindSetCompanyCharts(OMindSetCompanyData) {
      const ctx = document.getElementById('oMindSetCompanyChart');
      ctx.parentNode.style.width = '100%';
      ctx.parentNode.style.height = '100%';
      if (this.mindSetChart) {
        // Destroy the existing chart
        this.mindSetChart.destroy();
      }
      const datasets = OMindSetCompanyData?.data?.datasets?.filter(
        (dataset) => dataset !== null && typeof dataset !== 'undefined',
      ) ?? [];
      this.hasData = !!datasets.find((s) => s.data.find((z) => z));
      if (this.hasData === false) return;
      // eslint-disable-next-line no-param-reassign
      OMindSetCompanyData.options.scales.x.ticks = {
        maxRotation: 0,
        callback(index) {
          const arr = OMindSetCompanyData.data.labels[index].split(' ');
          const combinedArray = [];
          for (let i = 0; i < arr.length; i += 2) {
            if (arr[i + 1]) {
              combinedArray.push(`${arr[i]} ${arr[i + 1]}`);
            } else {
              combinedArray.push(arr[i]);
            }
          }
          return combinedArray;
        },

      };
      this.mindSetChart = new Chart(ctx, {
        type: OMindSetCompanyData?.type,
        data: OMindSetCompanyData?.data,
        options: {
          ...OMindSetCompanyData?.options,
          plugins: {
            tooltip: {
              callbacks: {
                label: (ctx1) => tooltipTeamPlotHelper(ctx1),
              },
            },
          },
        },
      });
    },
  },
};
</script>
