<template>
  <div class="calender" id="calender">
    <div class="month">
      <font-awesome-icon icon="fa-angle-left"
                         id="btn_prev"
                         class="icon-class"
                         :class="{'color-disabled': editable === false}"
                         @click.prevent="subMonthToDate"/>
      <div class="date">
        <h2 id="txt_month"
        :class="{'color-disabled': editable === false}">
          {{ $t('KssCalendarComponent.months.' + dateObject.getMonth()) }}
        </h2>
      </div>
      <font-awesome-icon icon="fa-angle-right"
                         id="btn_next"
                         class="icon-class"
                         :class="{'color-disabled': editable === false}"
                         @click.prevent="addMonthToDate"/>
    </div>
    <div class="weekdays">
      <div id="txt_sunday">{{ $t('KssCalendarComponent.weekdaysShort.sunday') }}</div>
      <div id="txt_monday">{{ $t('KssCalendarComponent.weekdaysShort.monday') }}</div>
      <div id="txt_tuesday">{{ $t('KssCalendarComponent.weekdaysShort.tuesday') }}</div>
      <div id="txt_wednesday">{{ $t('KssCalendarComponent.weekdaysShort.wednesday') }}</div>
      <div id="txt_thursday">{{ $t('KssCalendarComponent.weekdaysShort.thursday') }}</div>
      <div id="txt_friday">{{ $t('KssCalendarComponent.weekdaysShort.friday') }}</div>
      <div id="txt_saturday">{{ $t('KssCalendarComponent.weekdaysShort.saturday') }}</div>
    </div>
    <div class="days" id="txt_days">
      <div v-for="day in daysInMonthBefore" :key="day" class="prev-date"> {{ day.getDate() }}</div>
      <div v-for="day in daysInMonth" :key="day" @click.prevent="selectDate(day)"
           :class="{'today day' : isToday(day),
           'day dayClicked': isSelected(day),
           'bg-disabled border-disabled': editable === false && isSelected(day)}">
        {{ day.getDate() }}
      </div>
      <div v-for="day in daysInMonthAfter" :key="day" class="next-date"> {{ day.getDate() }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    editable: {
      type: Boolean,
    },
    initialDate: {
      type: Date,
      default: null,
    },
  },
  name: 'EditKssCalendarComponent',
  data() {
    return {
      dateObject: new Date(),
      selectedDateData: null,
    };
  },
  computed: {
    selectedDate() {
      return this.selectedDateData;
    },
    daysInMonth() {
      const year = this.dateObject.getFullYear();
      const month = this.dateObject.getMonth();
      const date = new Date(year, month, 1);
      const days = [];
      while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      return days;
    },
    daysInMonthBefore() {
      const date = new Date(this.dateObject);
      date.setDate(1);
      date.setMonth(this.dateObject.getMonth() - 1);
      const month = date.getMonth();
      const days = [];
      while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      const subtractCount = date.getDay() === 0 ? -days.length : date.getDay();
      return days.slice(-subtractCount);
    },
    daysInMonthAfter() {
      const date = new Date(this.dateObject);
      date.setDate(1);
      date.setMonth(this.dateObject.getMonth() + 1);
      const refDate = new Date(date);
      const month = date.getMonth();
      const days = [];
      while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      const takeCount = refDate.getDay() === 0 ? 0 : 7 - refDate.getDay();
      return days.slice(0, takeCount);
    },
  },
  methods: {
    addMonthToDate() {
      this.dateObject.setMonth(this.dateObject.getMonth() + 1);
      this.dateObject = new Date(this.dateObject);
    },
    subMonthToDate() {
      this.dateObject.setMonth(this.dateObject.getMonth() - 1);
      this.dateObject = new Date(this.dateObject);
    },
    selectDate(date) {
      if (this.editable === false) return;
      this.selectedDateData = date;
      this.$emit('updateDate', date);
    },
    isToday(day) {
      const today = new Date();
      return (today.toDateString() === day.toDateString());
    },
    isSelected(day) {
      if (this.selectedDate) {
        return (day.toDateString() === this.selectedDate.toDateString());
      }
      return false;
    },
  },
  mounted() {
    this.selectedDateData = this.initialDate;
  },
};
</script>

<style scoped>
/*------------ CALENDER ---------------*/
.calender {
  width: 100%;
  border-radius: 20px;
  box-shadow: 5px 10px 8px var(--color-light-grey);
  padding: 0.5rem;
  margin-bottom: 1rem;
  background-color: var(--color-milk);
}

.month {
  width: 100%;
  height: 3rem;
  background-color: transparent;
  color: var(--color-orange);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  text-align: center;
}

.month .icon-class {
  font-size: 1.74rem;
  cursor: pointer;
}

.month h2 {
  font-size: 1.5rem;
  color: var(--color-orange);
}

.weekdays {
  height: 3rem;
  width: 100%;
  padding: 0 0.4rem;
  display: flex;
  align-items: center;
}

.weekdays div {
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 0.1rem;
  width: calc(100% / 7); /*width = (Gesamtwidth - padding of weekdays) / 7*/
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-disabled);
}

.days {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0.2rem;
}

.days div {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0.3rem;
  height: 3rem;
  /*width = (Gesamtwidth - margin * 14 - padding of weekdays) / 7*/
  width: calc((100% - (0.3rem * 14) - 0.2rem) / 7);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s;
}

.days div:hover:not(.today) {
  border: 0.2rem solid var(--color-deep-champagne);
  border-radius: 8px;
  cursor: pointer;
}

.prev-date,
.next-date {
  opacity: 0.2;
}

.today {
  border-bottom-color: var(--color-deep-champagne);
  border-bottom-style: solid;
  border-left-color: var(--color-deep-champagne);
  border-left-style: solid;
  border-right-color: var(--color-deep-champagne);
  border-right-style: solid;
  border-top-color: var(--color-deep-champagne);
  border-top-style: solid;
  border-radius: 8px;
}

.dayClicked {
  background-color: var(--color-orange);
  border-radius: 8px;
}

.color-disabled {
  color: var(--color-disabled) !important;
}
.bg-disabled {
  background-color: var(--color-disabled) !important;
}
.border-disabled {
  border-color: var(--color-disabled) !important;
}

</style>
