<template>
  <div class="recurring_task">
    <div class="checkbox-container" v-if="editable === false && isRecurringTask">
      <input v-model="isRecurringTask" type="checkbox" :id="id" :disabled="editable === false">
      <label :for="id" class="rec_label" :class="{'label-disabled': editable === false}">
        {{ $t('KssRecurringTaskComponent.isRecurringTask') }}
      </label>
    </div>
    <div v-if="isRecurringTask" class="drop_event" id="drop_event">
      <div class="drop_container">
        <div class="drop_select_event">
          <select  v-model="type" name="eventtime" id="select_event" class="event"
          :disabled="editable === false"
          :class="{'color-disabled': editable === false}">
            <option value="daily">
              {{ $t('KssRecurringTaskComponent.daily') }}
            </option>
            <option value="weekly">
              {{ $t('KssRecurringTaskComponent.weekly') }}
            </option>
            <option value="monthly">
              {{ $t('KssRecurringTaskComponent.monthly') }}
            </option>
            <option value="custom">
              {{ $t('KssRecurringTaskComponent.custom') }}
            </option>
          </select>
        </div>
        <div v-if="type === 'custom'" class="custom_choice" id="custom_choice">
          <label for="nmbr" class="nmbr_label">
            {{ $t('KssRecurringTaskComponent.repeatEvery') }}
          </label>
          <div>
            <input :readonly="editable === false"
             v-model="everyX" type="number" min="1" name="nmbr" class="nmbr">
            <select :disabled="editable === false"
             v-model="subtype" name="nmbrevent" id="nmbr_event" class="event">
              <option value="days">
                {{ $t('KssRecurringTaskComponent.days') }}
              </option>
              <option value="weeks">
                {{ $t('KssRecurringTaskComponent.weeks') }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div v-if="showWeekDays" class="seven_days" id="seven_days">
        <label id="day" v-for="(day, index) in getDays" :key="day.name" @click="selectDay(index)">
          <div class="content-day" :class="{checked: day.isSelected}">
            <span>{{ $t('KssRecurringTaskComponent.daysShort.' + day.rSchName) }}</span>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  props: {
    editable: {
      type: Boolean,
    },
    initialRecurring: {
      type: Object,
      default: null,
    },
  },
  emits: ['updateReccuring'],
  name: 'EditKssRecurringTaskComponent',
  data() {
    return {
      id: `id_${uuidv4()}`,
      type: null,
      subtype: null,
      everyX: null,
      recurringDays: [],
      isRecurringTaskData: false,
    };
  },
  computed: {
    isRecurringTask: {
      get() {
        return this.isRecurringTaskData;
      },
      set(newValue) {
        this.isRecurringTaskData = newValue;
        this.updateReccuringObject();
      },
    },
    getDays() {
      return this.recurringDays;
    },
    showWeekDays() {
      if (this.type === 'weekly') {
        return true;
      }

      return this.type === 'custom' && this.subtype === 'weeks';
    },
  },
  methods: {
    selectDay(index) {
      if (this.editable === false) return;
      this.recurringDays[index].isSelected = !this.recurringDays[index].isSelected;
      this.updateReccuringObject();
    },
    initRecurringDays(byDays) {
      this.recurringDays = [
        {
          rSchName: 'MO',
          isSelected: byDays.includes('MO'),
        },
        {
          rSchName: 'TU',
          isSelected: byDays.includes('TU'),
        },
        {
          rSchName: 'WE',
          isSelected: byDays.includes('WE'),
        },
        {
          rSchName: 'TH',
          isSelected: byDays.includes('TH'),
        },
        {
          rSchName: 'FR',
          isSelected: byDays.includes('FR'),
        },
        {
          rSchName: 'SA',
          isSelected: byDays.includes('SA'),
        },
        {
          rSchName: 'SU',
          isSelected: byDays.includes('SU'),
        },
      ];
    },
    determineType() {
      if (Object.prototype.hasOwnProperty.call(this.initialRecurring, 'interval')) {
        return 'custom';
      }

      if (this.initialRecurring.frequency === 'MONTHLY') {
        return 'monthly';
      }

      if (this.initialRecurring.frequency === 'WEEKLY') {
        return 'weekly';
      }

      return 'daily';
    },
    determineSubType() {
      if (this.type === 'custom') {
        if (this.initialRecurring.frequency === 'WEEKLY') {
          return 'weeks';
        }
      }
      return 'days';
    },
    updateReccuringObject() {
      this.$emit('updateReccuring', this.getRecurringObject());
    },
    getRecurringObject() {
      switch (this.type) {
        case 'daily':
          return {
            isReccuring: this.isRecurringTask,
            frequency: 'DAILY',
            byDayOfWeek: ['MO', 'TU', 'WE', 'TH', 'FR'],
          };
        case 'weekly':
          return {
            isReccuring: this.isRecurringTask,
            frequency: 'WEEKLY',
            byDayOfWeek: this.recurringDays
              .filter((e) => e.isSelected)
              .map((e) => e.rSchName),
          };
        case 'monthly':
          return {
            isReccuring: this.isRecurringTask,
            frequency: 'MONTHLY',
          };
        case 'custom':
          if (this.subtype === 'days') {
            return {
              isReccuring: this.isRecurringTask,
              frequency: 'DAILY',
              interval: this.everyX,
            };
          }
          if (this.subtype === 'weeks') {
            return {
              isReccuring: this.isRecurringTask,
              frequency: 'WEEKLY',
              interval: this.everyX,
              byDayOfWeek: this.recurringDays
                .filter((e) => e.isSelected)
                .map((e) => e.rSchName),
            };
          }
        // eslint-disable-next-line no-fallthrough
        default:
          return [];
      }
    },
  },
  watch: {
    type(value) {
      this.type = value;
      this.updateReccuringObject();
    },
    subtype(value) {
      this.subtype = value;
      this.updateReccuringObject();
    },
    everyX(value) {
      this.everyX = value;
      this.updateReccuringObject();
    },
  },
  mounted() {
    if (this.initialRecurring && this.initialRecurring?.frequency !== 'MINUTELY') {
      this.isRecurringTask = true;
      if (this.initialRecurring?.byDayOfWeek) {
        this.initRecurringDays(this.initialRecurring.byDayOfWeek);
      } else {
        this.initRecurringDays([]);
      }
      this.type = this.determineType();
      this.subtype = this.determineSubType();
      this.everyX = this.initialRecurring?.interval || 1;
      this.updateReccuringObject();
    } else {
      this.isRecurringTask = false;
      this.type = 'daily';
      this.subtype = 'days';
      this.everyX = 1;
      this.initRecurringDays([]);
      this.updateReccuringObject();
    }
  },
};
</script>

<style scoped>
/*---------------- CONTENT NEW: RECURRING TASKS ------------------*/
.recurring_task {
  display: block;
}

.recurring_task .checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recurring_task .checkbox-container .rec_label {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.recurring_task .checkbox-container input[id^='id_'] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.recurring_task .checkbox-container .rec_label::before {
  content: '';
  width: 1em;
  height: 1em;
  border-radius: .15em;
  margin-right: .5em;
  border: .05em solid black;
}

.recurring_task .checkbox-container .rec_label:hover::before,
.recurring_task .checkbox-container input[id^='id_']:hover + label::before {
  border-color: var(--color-orange);
}

.recurring_task .checkbox-container input[id^='id_']:checked + label::before {
  content: '\002714';
  background-color: var(--color-orange);
  border-color: var(--color-orange);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.drop_event {
  width: 100%;
  padding: 0.5rem 0rem 0.5rem 1rem;
  margin-bottom: 1rem;
  margin-top: 0.5em;
}

.drop_event .drop_container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

select#select_event {
  width: 196px;
  height: 40px;
  margin-top: 10px;
}

select#nmbr_event {
  width: 81px;
  height: 40px;
}

select {
  height: 100%;
  font-weight: 300;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  color: var(--color-orange);
  background-color: var(--color-white);
  box-shadow: 5px 10px 8px var(--color-light-grey);
  cursor: pointer;
}

select option {
  width: 150px;
  height: 30px;
  font-size: 14px;
  padding: 10px;
  margin: 10px;
  border: none;
  color: var(--color-orange);
  background-color: var(--color-milk);
  cursor: pointer;
}

label {
  color: var(--color-dark);
  font-family: "Poppins";
  font-size: 18px;
  letter-spacing: 0.15px;
  line-height: 27px;
  text-align: center;
}

.custom_choice {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.nmbr_label {
  padding-right: 10px;
}

input[type="number"].nmbr {
  border-radius: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--color-light-grey);
  width: 70px;
  margin-right: 5px;
  font-size: 15px;
  padding-left: 10px;
  height: 3rem;
  background: transparent;
  box-sizing: border-box;
}

.seven_days {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 45px;
}

.seven_days label {
  display: flex;
  position: relative;
  margin: 5px;
  font-size: 13px;
  width: fit-content;
}

.seven_days label#mon {
  margin-left: 0;
}

.seven_days label .content-day {
  display: flex;
  padding: 15px;
  width: 100%;
  border: 1px solid var(--color-light-grey);
  border-radius: 10px;
  margin: 0px;
  cursor: pointer;
}

.seven_days span {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.seven_days .checked span {
  font-weight: 600;
}

.seven_days .checked .content-day .icon {
  display: block;
}

.seven_days .content-day .icon {
  position: absolute;
  top: 0px;
  right: 7px;
}

.seven_days .checked {
  color: var(--color-orange);
  background-color: var(--color-bisque);
  border: var(--color-orange);
}

.color-disabled {
  color: var(--color-disabled) !important;
}
.label-disabled:before {
  background-color: var(--color-disabled) !important;
  border-color: var(--color-disabled) !important;
}

@media (max-width: 720px) {
  .drop_event .drop_container {
    flex-direction: column;
  }

  .drop_event .drop_container .custom_choice {
    margin-top: 10px;
  }
}

@media (max-width: 420px) {
  .custom_choice {
    flex-direction: column;
  }
}
</style>
