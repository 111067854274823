<template>
  <p id="question-text" class="question-text">
    {{ question.properties.content.text_question[getLocale] ?? '' }}
    <span v-if="getHoverInfo" class="icon-container">
      <i class="fas fa-info-circle">
        <font-awesome-icon icon="fa-circle-question"/>
      </i>
      <span class="hover-bubble">{{ getHoverInfo }}</span>
    </span>
  </p>
  <div class="answer-group" name="ic-answers-group" id="div_icContainer">
    <div v-for="answer in answers" :key="answer">
      <input v-model="answer.text" type="text" class="ic_input_field" :key="answer"/>
    </div>
    <input v-model="currentEntry" type="text" name="pos"
           :placeholder="this.$t('IntegrativeComplexityQuestion.placeholders.answerTextbox')"
           id="txt_icInput"
           class="ic_input_field">
  </div>
  <div class="add-ic">
    <i id="btn_icInput" @click.prevent="addEntry">
      <font-awesome-icon icon="fa-circle-plus"/>
    </i>
  </div>
</template>

<script>
export default {
  name: 'AddIntegrativeComplexityValues',
  data() {
    return {
      question: this.$store.state.questionnaireManagement.selectedIcQuestion,
      answers: this.$store.state.questionnaireManagement
        .selectedIcQuestion.properties.data.integrativeComplexityValues,
    };
  },
  computed: {
    getLocale() {
      return this.$i18n.locale;
    },
    getHoverInfo() {
      return this.question.properties.content.description?.[this.getLocale];
    },
    currentEntry: {
      get() {
        return this.$store.state.questionnaireManagement.currentIcAnswerEntry;
      },
      set(value) {
        this.$store.commit('setCurrentIcAnswerEntry', value);
      },
    },
  },
  methods: {
    addEntry() {
      const answer = {
        class: 'integrativeComplexityValue',
        positive: this.question.positive,
        text: this.currentEntry,
      };
      this.answers.push(answer);
      this.$store.commit('addRateIcQuestion', answer);
      this.$store.commit('setCurrentIcAnswerEntry', null);
    },
  },
};
</script>

<style scoped>

.question-text {
  text-align: center;
  padding: 2rem 0;
}

.icon-container {
  position: relative;
  display: inline-block;
}

.hover-bubble {
  visibility: hidden;
  width: 150px;
  background-color: var(--color-orange);
  color: var(--color-milk);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -75px;
}

.icon-container:hover .hover-bubble {
  visibility: visible;
}

.icon-container:hover i {
  color: var(--color-orange);
  transform: scale(1.2);
}

/*------------------ INTEGRATIVE COMPLEXITY TEMPLATE -------------------*/
input[type=text] {
  box-sizing: border-box;
  border: 1px solid var(--color-light-grey);
  background: transparent;
  border-radius: 5px;
  padding: var(--card-padding);
  width: 100%;
  height: 3rem;
  margin-top: 5px;
  margin-right: 50px;
  outline: none;
  font-size: 15px;
}

.add-ic {
  box-sizing: border-box;
  border: 1px solid var(--color-light-grey);
  border-radius: 5px;
  width: 100%;
  height: 3rem;
  margin-right: 50px;
  margin-top: 1rem;
  outline: none;
  font-size: 15px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.add-ic > i {
  color: var(--color-orange);
  font-size: 1.7rem;
}

/*==================== MEDIA QUERIES =====================*/
@media (max-width: 520px) {
  .question-text {
    padding: 1rem 0.2rem;
  }
}

</style>
