<template>
  <div class="questions">
    <font-awesome-icon :icon="fa-clock" class="question-icon" />
    <label class="text-muted">{{ this.questionKeyword }}</label>
    <label v-if="!this.editable" class="answer">{{this.answer}}</label>
    <textarea v-if="this.editable"
              :disabled="!this.isEditable"
              class="input-field"
              :class="{'editEnabled' : this.editable}"
              v-model="this.answer"
              maxlength="500"
              @keyup="handleText"
    />
  </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'PersonalGoalQnAComponent',
  components: { FontAwesomeIcon },
  props: ['qna', 'editable'],
  computed: {
    isEditable() {
      return this.editable;
    },
    questionKeyword() {
      return this.qna.questionKeyword;
    },
    answer() {
      return this.qna.answer;
    },
  },
  methods: {
    handleText(value) {
      const updatedValue = this.$store.getters.getGoalUpdateObject;
      updatedValue[this.questionKeyword] = value.target.value;
      this.$store.commit('setGoalUpdateObject', updatedValue);
    },
  },
};
</script>
<style scoped>
.questions{
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  padding-right: 1.25rem;
  min-width: 200px;
}

.input-field:focus-within {
  transform: scale(1.02, 1.02);
  border-color: var(--color-orange);
}

textarea {
  transition: 100ms ease-in;
  font-family: Poppins, sans-serif;
  border: 1px solid transparent;
  box-sizing: border-box;
  background-color: var(--color-light);
  border-radius: 7px;
  padding-left: 10px;
  width: 98%;
  height: 100px;
  margin-right: 50px;
  font-size: 15px;
  resize: none;
}

.editEnabled {
  border-radius: 7px;
  border: 1px solid var(--color-light-grey);
}

.text-muted {
  color: var(--color-disabled);
}

.answer {
  font-size: 16px;
  word-wrap: break-word;
}
</style>
