<template>
  <div class="mainCollaborationTrend">
    <div class="upperCollaborationTrend">
      <button
        @click="toggleVisibility"
        :style="{
          backgroundColor: showTeamsAndTags ? '#f49718' : 'white',
          color: showTeamsAndTags ? 'white' : 'black',
        }"
      >
        <img width="23" src="@/assets/eye-slash.svg" alt="eye-slash" v-show="showTeamsAndTags" />
        <img width="23" src="@/assets/eye.svg" alt="eye" v-show="!showTeamsAndTags" />
        {{
          showTeamsAndTags ? $t("mngDashboardComponent.hide") : $t("mngDashboardComponent.show")
        }}
        {{
          $t("mngDashboardComponent.teams/tags")
        }}
      </button>
      <FiltersComponent
        :allowFilter="{
          team: true,
          tag: true
        }"
        :max="collaborationMaxSelection"
        :multiple="false"
        v-if="showTeamsAndTags"
        @saveSelection="saveSelection"
      />
    </div>
    <div class="lowerCollaborationTrend">
      <AlertBox
        @mouseover="isAlertBoxVisible = true"
        @mouseout="isAlertBoxVisible = false"
        @focusin="isAlertBoxVisible = true"
        @focusout="isAlertBoxVisible = false"
        tabindex="0"
        v-show="isAlertBoxVisible"
        :text="collaborationTrendText?.alert"
      />
      <div class="leftCollaborationContainer">
        <div class="topCollaborationChartContainer">
          <h1>{{ $t('mngDashboardComponent.collaborationTrendOverTime') }}</h1>
          <select v-model="KSSSelectFilter" @change="applyKSSFilter">
            <option
              v-for="item in collaborationTrendPeriod"
              :key="item?.identifier"
              :value="item?.identifier"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="collaborationChartContainer" @mouseover="isAlertBoxVisible = true"
          @mouseout="isAlertBoxVisible = false"
          @focusin="isAlertBoxVisible = true"
          @focusout="isAlertBoxVisible = false"
          tabindex="0">
          <CollaborationTrendChart ref="collaborationTrend" />
        </div>
      </div>
      <div class="rightCollaborationContainer">
        <div class="avgChartContainer" v-show="showAverageChart">
          <div class="topAvgCollaborationContainer">
            <h1>{{ $t('mngDashboardComponent.avgKssTrend') }}</h1>

            <select v-model="avgKSSSelectFilter" @change="applyAvgKSSFilter">
              <option
                v-for="item in collaborationTrendPeriod"
                :key="item?.identifier"
                :value="item?.identifier"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="avgCollaborationChart">
            <AvgCollaborationTrendChart ref="avgCollaborationTrend" />
          </div>
        </div>
        <div class="collaborationTextContainer">
          <div style="display: flex">
            <img src="@/assets/light.svg" alt="light" />
            <h1>
              {{
                collaborationTrendText?.headline
              }}
            </h1>
          </div>
          <p>
            {{
              collaborationTrendText?.text
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '../managementDashboard.css';
import CollaborationTrendChart from '@/components/Home/ManagementDashboard/CollaborationTrendComponent/CollaborationTrendChartComponent.vue';
import AvgCollaborationTrendChart from '@/components/Home/ManagementDashboard/CollaborationTrendComponent/AvgCollaborationChartComponent.vue';
import FiltersComponent from '@/components/Home/ManagementDashboard/common/FiltersComponent.vue';
import AlertBox from '@/components/Home/ManagementDashboard/common/AlertBoxComponent.vue';

export default {
  name: 'CollaborationTrendComponent',
  data() {
    return {
      isAlertBoxVisible: false,
      selections: [],
      KSSSelectFilter: 'Last7Days',
      avgKSSSelectFilter: 'Last7Days',
      showTeamsAndTags: false,
      showAverageChart: true,
    };
  },
  components: {
    CollaborationTrendChart,
    AvgCollaborationTrendChart,
    FiltersComponent,
    AlertBox,
  },
  computed: {
    collaborationTrendOverTimeData() {
      return this.$store.getters.getCollaborationKss?.collaborationTrendOverTimeData;
    },
    avgCollaborationTrendData() {
      return this.$store.getters.getCollaborationKss?.avgCollaborationTrendData;
    },
    collaborationTrendText() {
      return this.$store.getters.getCollaborationKss?.collaborationTrendText;
    },
    collaborationTrendPeriod() {
      return this.$store.getters.getCollaborationKss?.collaborationTrendPeriod;
    },
    collaborationMaxSelection() {
      return this.$store.getters.getCollaborationKss?.maxSelection;
    },
  },
  methods: {
    // --------- Main Function to call the api on every filters change ---------------- //
    async KSSTrendOverAllFunction(isKSSTrend) {
      try {
        await this.$store.dispatch('fetchInitialCollaborationTrendData', {
          selections: this.selections,
          kssPeriodType: this.KSSSelectFilter,
          avgKssPeriodType: this.avgKSSSelectFilter,
        });
        if (isKSSTrend === 'isKSSTrend') {
          this.$refs.collaborationTrend.collaborationTrendChart(
            this.collaborationTrendOverTimeData,
          );
        } else if (isKSSTrend === 'isAvgKSSTrend') {
          this.$refs.avgCollaborationTrend.avgCollaborationTrendChart(
            this.avgCollaborationTrendData,
          );
        } else {
          this.$refs.collaborationTrend.collaborationTrendChart(
            this.collaborationTrendOverTimeData,
          );
          this.$refs.avgCollaborationTrend.avgCollaborationTrendChart(
            this.avgCollaborationTrendData,
          );
        }
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
      }
    },

    // -----------show / hide Teams and Tags function -------------- //
    toggleVisibility() {
      this.showTeamsAndTags = !this.showTeamsAndTags;
      if (this.selections.length > 0) {
        this.selections = [];
        this.KSSTrendOverAllFunction('');
      }
    },

    // --------------- Filters---------------- //
    applyKSSFilter() {
      this.KSSTrendOverAllFunction('isKSSTrend');
    },
    applyAvgKSSFilter() {
      this.KSSTrendOverAllFunction('isAvgKSSTrend');
    },
    saveSelection(selection) {
      this.selections = selection;
      this.KSSTrendOverAllFunction('');
    },
  },
  mounted() {
    this.KSSTrendOverAllFunction('');
  },
};
</script>
