<template>
  <canvas id="collaborationTrendsChart" width="100%" height="100%"></canvas>
  <div class="chart-no-data" v-if="!hasData">
    {{ $t('mngDashboardComponent.notEnoughDataToDisplay') }}
  </div>
</template>
<script>
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';

export default {
  name: 'CollaborationTrendChartComponent',
  data() {
    return {
      hasData: false,
    };
  },
  methods: {
    // Function to draw a vertical line when hovering over a point
    drawHoverLine(chart, xCoord) {
      const { ctx } = chart;
      const yAxis = chart.scales.y;

      // Save the current line dash pattern
      const originalLineDash = ctx.getLineDash();
      ctx.setLineDash([5, 5]);
      // Draw the vertical line
      ctx.save();
      ctx.beginPath();
      ctx.strokeStyle = '#808080'; // Line color
      ctx.lineWidth = 2; // Line width
      ctx.moveTo(xCoord, yAxis.top);
      ctx.lineTo(xCoord, yAxis.bottom);
      ctx.stroke();
      ctx.restore();

      // Restore the original line dash pattern
      ctx.setLineDash(originalLineDash);
    },
    collaborationTrendChart(collaborationTrendData) {
      // Get the chart container element
      const ctx = document.getElementById('collaborationTrendsChart');
      ctx.parentNode.style.width = '100%';
      ctx.parentNode.style.height = '100%';
      if (this.collaborationChart) {
        // Destroy the existing chart
        this.collaborationChart.destroy();
      }

      // Extract data from this.collaborationChart
      const labels = collaborationTrendData?.data?.labels ?? [];
      const datasets = collaborationTrendData?.data?.datasets ?? [];
      const options = collaborationTrendData?.options;
      if (options?.plugins?.tooltip) {
        options.plugins.tooltip = {
          callbacks: {
            title(context) {
              const parts = context[0]?.label.split(/[\s,]+/);
              const reformattedDate = `${parts[0]} ${parts[1]}, ${parts[2]}`;
              return reformattedDate;
            },
            label(context) {
              return `${context?.dataset?.label}: ${context?.raw?.y.toFixed(2)}`;
            },
          },
        };
      }
      this.hasData = !!datasets.find((s) => s.data.find((z) => z));
      if (this.hasData === false) return;

      const hoverLinePlugin = {
        beforeDraw: (chart) => {
          const { tooltip, scales } = chart;
          if (tooltip._active && tooltip._active.length && scales) {
            const xCoord = scales.x.getPixelForValue(tooltip.dataPoints[0].parsed.x);
            this.drawHoverLine(chart, xCoord);
          }
        },
      };
      this.collaborationChart = new Chart(ctx, {
        type: collaborationTrendData?.type,
        data: {
          labels,
          datasets,
        },
        options,
        plugins: [hoverLinePlugin],
      });
    },
  },
};
</script>
