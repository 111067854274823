<template>
  <div class="links-container">
    <a :href="url" target="_blank">
      <div class="link-logo">
        <img :src="icon" alt="icon" class="link-image">
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'QuickAccessLinksComponent',
  props: {
    url: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.links-container {
  width: 20%;
  height: 100px;
  border-radius: 10px;
  color: white;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  margin-left: 1.5rem !important;
}

.link-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 106px;
  border-radius: 50%;
  overflow: hidden;
}

.link-image {
  height: auto;
  width: 100%;
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
}

@media (min-width: 521px) and (max-width: 1919px){
  .link-logo{
    height: 3rem;
    border-radius: 15%;
  }
}

@media (max-width: 520px) {
  .links-container {
    width: 106px !important;
    margin-left: 1rem !important;

  }
}
</style>
