<template>
  <div class="container">
    <div class="forms">
      <div class="pass">
        <router-link to="/auth" class="link">
          <i>
            <font-awesome-icon icon="fa-arrow-left"/>
          </i>
          {{ $t('PasswordResetComponent.backToLogin') }}
        </router-link>
      </div>
      <img class="platform-logo" src="@/assets/logo_transparent.png" alt="txt">
      <h1>{{ $t('PasswordResetComponent.forgotPassword') }}</h1>
      <p>{{ $t('PasswordResetComponent.resetInstructions') }}</p>
      <vee-form :validation-schema="schema" @submit="requestPasswordReset" v-slot="{ errors }">
        <div class="input-field">
          <vee-field type="text" name="email" id="txt_mail"
                     :placeholder="$t('PasswordResetComponent.placeholders.forgotPassword')"
                     :class="{inputError : !!errors.email}"/>
        </div>
        <error-message class="error-message" name="email"></error-message>
        <language-component class="lang-component"/>
        <button type="submit" name="submit" id="btn_lostPassword" :disabled="isSubmitting">
          {{ $t('PasswordResetComponent.resetPassword') }}
        </button>
      </vee-form>
    </div>
  </div>
</template>

<script>
import LanguageComponent from '@/components/global/LanguageComponent.vue';

export default {
  name: 'PasswordResetComponent',
  components: { LanguageComponent },
  data() {
    return {
      schema: {
        email: 'required|email',
      },
      isSubmitting: false,
    };
  },
  methods: {
    async requestPasswordReset(values) {
      try {
        this.isSubmitting = true;
        await this.$store.dispatch('requestPasswordReset', values);
        this.isSubmitting = false;
        this.$router.push('/auth/password-reset-success');
      } catch (error) {
        this.isSubmitting = false;
        // Todo handle errors
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: error.response.data.message,
          type: 'error',
        });
      }
    },
  },
  unmounted() {
    this.$store.commit('setPasswordResetRequested', false);
  },
};
</script>

<style scoped>
.lang-component {
  float: left;
  margin-top: 5px;
  position: absolute;
}

/*------------ GENERAL ----------------*/

h1 {
  color: var(--color-dark);
  font-family: "Poppins";
  font-size: 25px;
  font-weight: bold;
  letter-spacing: 0.15px;
  line-height: 27px;
  text-align: center;
  padding: 0 0 20px 0;
}

p {
  text-align: center;
  margin: 2rem 0;
  padding: 0 10px;
}

.link:hover {
  transition: .5s;
  color: var(--color-fulvous);
}

/*------------ FORM ----------------*/
.container .forms {
  padding: 30px;
}

.error-message {
  margin-top: 2px;
}

.platform-logo {
  display: block;
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  width: 10rem;
  height: auto;
}

.forms .input-field {
  position: relative;
  width: 100%;
  height: 3rem;
  margin-top: 1rem;
  border-radius: 5px;
  border: 1px solid #E7E7E7;

}

.input-field input {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  font-size: 16px;
}

.input-field .inputError {
  border: 2px solid var(--color-error);
}

.pass {
  float: left;
  cursor: pointer;
  color: var(--color-orange);
  font-weight: 600;
  padding: 15px 10px;
}

.pass:hover {
  color: var(--color-fulvous);

}

.pass i {
  padding: 0 .5rem;
}

/*------------------ BUTTONS ------------------*/
button[type=submit] {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  padding: 10px 35px;
  border: none;
  border-radius: 50px;
  font-size: 20px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

button[type=submit]:hover {
  transition: .5s;
  background-color: var(--color-fulvous);
}

button[type=submit]:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

/*==================== MEDIA QUERIES =====================*/
@media screen and (max-width: 500px) {
  .container {
    height: 100%;
    width: 100%;
    border-radius: 0;
  }
}

</style>
