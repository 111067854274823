<template>
  <div class="moodomatData-content">
    <div class="moodomatData-wrapper">
      <div class="moodomatData-column">
        <div class="moodomatData-value">
          <h1 class="moodomatData-number">{{ getMoodomatTeamCount.prevWeekCount }}</h1>
          <p class="moodomatData-text">{{ $t('TeamDashboardComponent.moodomatPrevWeek') }}</p>
        </div>
        <div class="moodomatData-value">
          <h1 class="moodomatData-number">{{ getMoodomatTeamCount.prevWeekPeople }}</h1>
          <p class="moodomatData-text">{{ $t('TeamDashboardComponent.peoplePrevCount') }}</p>
        </div>
      </div>
      <div class="moodomatData-column">
        <div class="moodomatData-value">
          <h1 class="moodomatData-number">{{ getMoodomatTeamCount.currWeekCount }}</h1>
          <p class="moodomatData-text">{{ $t('TeamDashboardComponent.moodomatThisWeek') }}</p>
        </div>
        <div class="moodomatData-value">
          <h1 class="moodomatData-number">{{ getMoodomatTeamCount.currWeekPeople }}</h1>
          <p class="moodomatData-text">{{ $t('TeamDashboardComponent.peopleCurrCount') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamDashboardMoodomatComponent',
  computed: {
    getMoodomatTeamCount() {
      if (this.$store.getters.getMoodomatTeamCount) {
        const teamCount = this.$store.getters.getMoodomatTeamCount;
        teamCount.prevWeekCount = teamCount.prevWeekCount || 0;
        teamCount.prevWeekPeople = teamCount.prevWeekPeople || 0;
        teamCount.currWeekCount = teamCount.currWeekCount || 0;
        teamCount.currWeekPeople = teamCount.currWeekPeople || 0;
        return teamCount;
      }
      return {
        currWeekCount: 0, prevWeekCount: 0, currWeekPeople: 0, prevWeekPeople: 0,
      };
    },
  },
};
</script>

<style scoped>

.moodomatData-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.moodomatData-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.moodomatData-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 15px !important;
}

.moodomatData-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  margin-top: 10px;
}

.moodomatData-number {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-orange);
  border-radius: 5px;
  font-size: 50px;
  line-height: 100%;
  min-width: 80px;
}

.moodomatData-text {
  padding-left: 1rem;
}

@media (max-width: 520px) {
  .moodomatData-wrapper {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}
</style>
