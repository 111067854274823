<template>
  <div class="mainEmployeeSatisfaction">
    <div class="upperEmployeeSatisfaction">
      <button
        @click="toggleVisibility"
        :style="{
          backgroundColor: showTeamsAndTags ? '#f49718' : 'white',
          color: showTeamsAndTags ? 'white' : 'black',
        }"
      >
        <img width="23" src="@/assets/eye-slash.svg" alt="eye slash" v-show="showTeamsAndTags" />
        <img width="23" src="@/assets/eye.svg" alt="eye" v-show="!showTeamsAndTags" />
        {{
          showTeamsAndTags ? $t("mngDashboardComponent.hide") : $t("mngDashboardComponent.show")
        }}
        {{
          $t("mngDashboardComponent.teams/tags")
        }}
      </button>
      <FiltersComponent
        :allowFilter="{
          team: true,
          tag: true
        }"
        :max="employeeSatisfactionMaxSelection"
        :multiple="false"
        v-if="showTeamsAndTags"
        @saveSelection="saveSelection"
      />
    </div>
    <div class="lowerEmployeeSatisfaction">
      <AlertBox
        @mouseover="isAlertBoxVisible = true"
        @mouseout="isAlertBoxVisible = false"
        @focusin="isAlertBoxVisible = true"
        @focusout="isAlertBoxVisible = false"
        tabindex="0"
        v-show="isAlertBoxVisible"
        :text="employeeSatisfactionText?.alert"
      />
      <div class="leftEmployeeContainer">
        <div class="topSatisfactionChartContainer">
          <h1>Employee Satisfaction Over Time</h1>
          <select v-model="employeeSelectFilter" @change="applyEmployeeFilter">
            <option
              v-for="item in employeeSatisfactionPeriod"
              :key="item?.identifier"
              :value="item?.identifier"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="satisfactionChartContainer">
          <EmployeeSatisfactionChart ref="employeeSatisfaction" />
        </div>
      </div>
      <div class="rightEmployeeContainer">
        <div class="avgChartContainer" v-show="showAverageChart">
          <div class="topAvgEmployeeContainer">
            <h1>Avg. Employee Satisfaction</h1>

            <select v-model="avgEmployeeSelectFilter" @change="applyAvgEmployeeFilter">
              <option
                v-for="item in employeeSatisfactionPeriod"
                :key="item?.identifier"
                :value="item?.identifier"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="avgEmployeeSatChart"
          @mouseover="isAlertBoxVisible = true"
          @mouseout="isAlertBoxVisible = false"
          @focusin="isAlertBoxVisible = true"
          @focusout="isAlertBoxVisible = false"
          tabindex="0">
            <AvgEmployeeSatisfactionChart ref="avgEmployeeSatisfaction" />
          </div>
        </div>
        <div class="employeeTextContainer">
          <div style="display: flex">
            <img src="@/assets/light.svg" alt="light" />
            <h1>
              {{employeeSatisfactionText?.headline}}
            </h1>
          </div>
          <p>
            {{employeeSatisfactionText?.text}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import '../managementDashboard.css';
import EmployeeSatisfactionChart from '@/components/Home/ManagementDashboard/SatisfactionReportComponent/EmployeeSatisfactionChartComponent.vue';
import AvgEmployeeSatisfactionChart from '@/components/Home/ManagementDashboard/SatisfactionReportComponent/AvgEmployeeSatisfactionChartComponent.vue';
import FiltersComponent from '@/components/Home/ManagementDashboard/common/FiltersComponent.vue';
import AlertBox from '@/components/Home/ManagementDashboard/common/AlertBoxComponent.vue';

export default {
  name: 'SatisfactionReportComponent',
  data() {
    return {
      isAlertBoxVisible: false,
      selections: [],
      employeeSelectFilter: 'Last7Days',
      avgEmployeeSelectFilter: 'Last7Days',
      showTeamsAndTags: false,
      showAverageChart: true,
    };
  },
  components: {
    EmployeeSatisfactionChart,
    AvgEmployeeSatisfactionChart,
    FiltersComponent,
    AlertBox,
  },
  computed: {
    employeeSatisfactionData() {
      return this.$store.getters.getMoodomat?.employeeSatisfactionData;
    },
    avgEmployeeSatisfactionData() {
      return this.$store.getters.getMoodomat?.avgEmployeeSatisfactionData;
    },
    employeeSatisfactionText() {
      return this.$store.getters.getMoodomat?.employeeSatisfactionText;
    },
    employeeSatisfactionPeriod() {
      return this.$store.getters.getMoodomat?.employeeSatisfactionPeriod;
    },
    employeeSatisfactionMaxSelection() {
      return this.$store.getters.getMoodomat?.maxSelection;
    },
  },
  methods: {
    // --------- Main Function to call the api on every filters change ---------------- //
    async employeeOverAllFunction(isEmployee) {
      try {
        await this.$store.dispatch('fetchInitialEmployeeSatisfactionData', {
          selections: this.selections,
          employeePeriodType: this.employeeSelectFilter,
          avgEmployeePeriodType: this.avgEmployeeSelectFilter,
        });
        if (isEmployee === 'isEmployee') {
          this.$refs.employeeSatisfaction.employeeSatisfactionLineChart(
            this.employeeSatisfactionData,
          );
        } else if (isEmployee === 'isAvgEmployee') {
          this.$refs.avgEmployeeSatisfaction.avgEmployeeSatisfactionBarChart(
            this.avgEmployeeSatisfactionData,
          );
        } else {
          this.$refs.employeeSatisfaction.employeeSatisfactionLineChart(
            this.employeeSatisfactionData,
          );
          this.$refs.avgEmployeeSatisfaction.avgEmployeeSatisfactionBarChart(
            this.avgEmployeeSatisfactionData,
          );
        }
      } catch (error) {
        this.$store.commit('setToastNotification', {
          id: Math.random(),
          message: 'Error! Check Console output!',
          type: 'error',
        });
      }
    },

    // -----------show / hide Teams and Tags function -------------- //
    toggleVisibility() {
      this.showTeamsAndTags = !this.showTeamsAndTags;
      if (this.selections.length > 0) {
        this.selections = [];
        this.employeeOverAllFunction('');
      }
    },

    // toggleAverageChart() {
    //   this.showAverageChart = !this.showAverageChart;
    // },

    // --------------- Filters---------------- //
    applyEmployeeFilter() {
      this.employeeOverAllFunction('isEmployee');
    },
    applyAvgEmployeeFilter() {
      this.employeeOverAllFunction('isAvgEmployee');
    },
    saveSelection(selection) {
      this.selections = selection;
      this.employeeOverAllFunction('');
    },
  },

  mounted() {
    this.employeeOverAllFunction('');
  },
};
</script>
