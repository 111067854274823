<template>
  <div class="task-card">
    <div class="headline">
      <h2>KSS Tracker: {{ archivedTask?.name }}</h2>
    </div>
    <div class="participants-status" v-if="usersCount > 0">
      <i class="icon">
        <font-awesome-icon icon="fa-users"/>
      </i>
      <p class="participants-label">{{ participantsCount }} participants | </p>
      <p class="participants-percent" :style="available">{{ participantsPercent }}</p>
    </div>
    <div class="status">
      <div class="time-rubber">
        <i class="icon">
          <font-awesome-icon icon="fa-calendar"/>
        </i>
        <p class="labelname">{{ formattedDate }}</p>
      </div>
      <div v-if="archivedTask?.outlook_id">
        <div class="time-rubber">
          <p class="labelname">Outlook</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KssTaskArchivedComponent',
  props: ['archivedTask'],
  data() {
    return {
      usersCount: this.archivedTask?.involvedUsers?.length || 0,
      minCount: this.$store.getters.getMinCount,
    };
  },
  computed: {
    formattedDate() {
      const date = new Date(this.archivedTask.date_created);
      return `${(`0${date.getDate()}`).slice(-2)}.${(`0${date.getMonth() + 1}`).slice(-2)}.${date.getFullYear()}`;
    },
    participantsCount() {
      return this.archivedTask.involvedUsers.length;
    },
    participantsPercent() {
      if (this.usersCount >= this.minCount) {
        const percent = `${Number(this.archivedTask.percent).toFixed(2)}% completed`;
        return percent;
      }
      return this.$t('KssTaskArchivedComponent.lessParticipants', { count: this.minCount });
    },
    available() {
      if (this.usersCount >= this.minCount) {
        return 'color: var(--color-orange)';
      }
      return 'color: var(--color-disabled)';
    },
  },
};
</script>

<style scoped>

.task-card {
  background: var(--color-light-grey);
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  margin: 1rem 0;
}

.task-card:hover {
  box-shadow: 0 4px 20px 0 #aaa;
  cursor: pointer;
}

.task-card .headline {
  display: flex;
  justify-content: space-between;
}

.task-card .headline h2 {
  font-size: 23px;
}

.task-card .headline .edit i {
  font-size: 1rem;
  color: var(--color-disabled);
}

.time-rubber {
  display: flex;
  flex-direction: row;
  margin: 0.2rem 0;
  padding: 0.7rem;
  align-items: center;
  height: 2.2rem;
  width: min-content;
  border-radius: 4px;
  background-color: var(--color-disabled);
}

.time-rubber .icon {
  display: flex;
  padding: 3px;
  width: 2rem;
  color: var(--color-white);
}

.labelname {
  color: var(--color-white);
  padding: 3px;
}

.task-card .headline .archived {
  color: var(--color-orange);
}

.task-card .headline .archived i {
  padding-left: 5px;
  font-size: 1rem;
  color: var(--color-orange);
}

.participants-status {
  height: 2.2rem;
  margin: 0.2rem 0.2rem 0.2rem 0rem;
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.participants-label {
  margin-left: 0.6rem;
  color: var(--color-dark);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
}

.participants-percent {
  margin-left: 0.6rem;
  color: var(--color-dark);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: normal;
}

.task-card .status  {
  display: flex;
  justify-content: flex-start;
}

@media (max-width: 520px) {
  .task-card .status  {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .participants-status {
    margin-top: 0.5rem;
    display: flex;
    justify-content: flex-start;
  }
}

</style>
