<template>
  <div class="dropdown-container">
    <div class="dropdown-component">
      <font-awesome-icon class="dropdown-icon" icon="fa-solid fa-plus"/>
      <p>{{ $t("UserAdministrationCreateAssessmentComponent.options.createAssessment") }}</p>
    </div>
    <div class="dropdown-options-container">
      <div class="dropdown-options">
        <div
          v-for="schema in getSchemas"
          :key="schema"
          class="dropdown-option"
          :class="{ disabled: getAreUsersSelected }"
        >
          <div class="dropdown-title" @click="openPopup(schema.type, undefined)">
            <p>{{ schema.representationProperties.assessmentName[getLocale] || '-' }}</p>
          </div>
        </div>
        <div
          class="dropdown-option"
          :class="{ disabled: getAreUsersSelected }"
        >
          <div class="dropdown-title custom-schema-option">
            <p>{{ $t("UserAdministrationCreateAssessmentComponent.customSchemaId") }}</p>
            <div class="input-container">
              <div class="input-field">
                <input type="text" name="customSchemaID" v-model="customSchemaID"/>
              </div>
              <p class="pill-element" @click="openPopup(undefined, customSchemaID)">+</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserAdministrationCreateAssessmentComponent',
  components: {},
  props: {
    getCheckedUsers: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      customSchemaID: '',
    };
  },
  computed: {
    getSchemas() {
      return this.$store.getters.getAvailableSchemas;
    },
    getAreUsersSelected() {
      return Object.keys(this.getCheckedUsers).length === 0;
    },
    getLocale() {
      return this.$i18n.locale;
    },
  },
  methods: {
    openPopup(schemaName, schemaID) {
      if (Object.keys(this.getCheckedUsers).length !== 0) {
        this.$store.commit('addFrontendOverlay', {
          id: Math.random(),
          type: 'createAssessmentCalendar',
          userIDs: this.getCheckedUsers?.map((e) => e._id),
          schemaName,
          schemaID,
        });
      }
    },
  },
};
</script>

<style scoped>
.dropdown-picks-element > p {
  padding: 0px;
}

.dropdown-name,
.dropdown-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid gray;
  align-items: center;
  padding: 0px var(--card-padding) 0px var(--card-padding);
}

.dropdown-option {
  color: black;
  cursor: pointer;
}

.dropdown-option:hover {
  color: white;
  background-color: var(--color-orange);
}

.dropdown-option:hover .pill-element {
  color: var(--color-orange);
  background-color: white;
}

.dropdown-picks {
  background-color: peachpuff;
  max-height: 180px;
  overflow-y: auto;
}

.dropdown-picks-element {
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dropdown-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button {
  display: block;
  margin: 0.5rem;

  padding: 10px 35px;
  border: none;
  border-radius: 12px;
  font-size: 18px;
  color: var(--color-white);
  background-color: var(--color-orange);
  cursor: pointer;
}

.delete-button {
  color: var(--color-orange);
  cursor: pointer;
}

.disabled {
  color: grey;
  cursor: not-allowed;
}

.disabled:hover {
  color: white;
  background-color: gray;
  cursor: not-allowed;
}

/*------------------- CHECKBOX -----------------------*/

.checkbox-icon-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 5px;
}

.checked-icon {
  color: var(--color-orange);
  font-size: 22px;
}

.unchecked-icon {
  font-size: 22px;
}

/*------------ FILTER DROPDOWN ---------------*/

.dropdown-component {
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 5px 10px;
  font-size: 14px;
  margin: 5px 0.8rem 5px;
  border-radius: 0.5rem;
  border-radius: var(--card-border-radius);
  background-color: var(--color-light);
}

.dropdown-icon {
  margin-right: 7px;
  height: 20px;
}

.dropdown-container {
  position: relative;
}

.dropdown-options-container {
  display: none;
  position: absolute;
  top: 92%;
  right: auto;
  left: 0;
  z-index: 1;
  padding-top: 5px;
}

.dropdown-options {
  width: 300px;
  max-height: 500px;
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: var(--card-border-radius);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: var(--color-milk);
}

.disabled {
  color: grey;
  cursor: not-allowed;
}

.disabled:hover {
  color: white;
  background-color: gray;
  cursor: not-allowed;
}

.dropdown-component:hover + .dropdown-options-container,
.dropdown-options-container:hover {
  display: flex;
  flex-direction: column;
}

.input-field {
  position: relative;
  width: 100%;
  height: 2rem;
  border-radius: 5px;
  border: 1px solid #e7e7e7;
}

.input-field input {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  font-size: 16px;
  background-color: rgb(0, 0, 0, 0);
}

.input-field i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
}

.pill-element {
  display: flex;
  align-items: center;
  background-color: var(--color-orange);
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  margin: 5px;
  color: var(--color-milk);
}

.custom-schema-option {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 5px;
}

.input-container {
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
}

@media screen and (max-width: 621px) {
  .dropdown-options-container {
    right: auto;
    left: 0;
  }
}

@media screen and (max-width: 421px) {
  .dropdown-options-container {
    left: auto;
    right: 0;
  }

  .dropdown-options {
    width: 350px;
  }
}

@media screen and (max-width: 392px) {
  .dropdown-options-container {
    right: auto;
    left: 0;
  }
}
</style>
